class themecolors {
  static colors = (theme, layout_id, surveyLayout) => {
    const colorObj = {
      borderColor: "",
      backgroundColor: "",
      lightBackgroundColor: "",
      textColor: "",
      boxShadow: "",
      labelcolor: "",
      onHoverBorderColor: "",
      onHoverBgColor: "",
      onHoverTextColor: "",
      onHoverInColor: "",
      hoverOutColor: "",
      lineargradient: "",
      border: "",
      onHoverBorder: "",
      onSelectionbgcolor: "",
    };
    if (layout_id === "5") {
      if (theme === "fifth-theme") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#F47621";
        colorObj.lightBackgroundColor = "#ffffff";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(255,186,182,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#F47621";
        colorObj.onHoverBgColor = "#F47621";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#F47621";
        colorObj.hoverOutColor = "#F47621";
        colorObj.lineargradient = "linear-gradient(to left, #ff766e , #F47621)";
        colorObj.border = "2px solid #F47621 !important";
        colorObj.onHoverBorder = "2px solid #F47621 !important";
        colorObj.onSelectionbgcolor = "#F47621";
        return colorObj;
      }
      if (theme === "red-blue") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#eb2227";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 25px rgba(238,204,204, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#eb2227";
        colorObj.onHoverBgColor = "#fff";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#eb2227";
        colorObj.hoverOutColor = "#eb2227";
        colorObj.lineargradient = "#eb2227";
        colorObj.border = "2px solid #eb2227!important";
        colorObj.onHoverBorder = "2px solid #eb2227 !important";
        colorObj.onSelectionbgcolor = "#eb2227";
        return colorObj;
      }
      if (theme === "sixth-theme") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#529C2F";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 10px rgba(109, 64, 169,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#529C2F";
        colorObj.onHoverBgColor = "#529C2F";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#529C2F";
        colorObj.hoverOutColor = "#529C2F";
        colorObj.lineargradient = "linear-gradient(to left, #b599ce , #5C0F8B)";
        colorObj.border = "2px solid #529C2F !important";
        colorObj.onHoverBorder = "2px solid #529C2F !important";
        colorObj.onSelectionbgcolor = "#529C2F";
        return colorObj;
      }
      if (theme === "seventh-theme") {
        if (surveyLayout === "azx8layout") {
          colorObj.borderColor = "#1b283f";
          colorObj.backgroundColor = "#FEE005";
          colorObj.lightBackgroundColor = "#ffffff";
          colorObj.textColor = "#1b283f";
          colorObj.boxShadow = "0 0 25px rgba(255,167,162, 0.5)";
          colorObj.labelcolor = "#000";
          colorObj.onHoverBorderColor = "#FEE005";
          colorObj.onHoverBgColor = "#FEE005";
          colorObj.onHoverTextColor = "#1b283f";
          colorObj.onHoverInColor = "#1b283f";
          colorObj.hoverOutColor = "#000";
          colorObj.lineargradient = "0 0 25px rgba(255,167,162, 0.5)";
          colorObj.border = "2px solid #1b283f !important";
          colorObj.onHoverBorder = "2px solid #FEE005 !important";
          colorObj.onSelectionbgcolor = "#FEE005";
          return colorObj;
        } 
        
        else {
          colorObj.borderColor = "#c5c5c5";
          colorObj.backgroundColor = "#FEE005";
          colorObj.lightBackgroundColor = "#1b283f";
          colorObj.textColor = "#1b283f";
          colorObj.boxShadow = "0 0 25px rgba(255,167,162, 0.5)";
          colorObj.labelcolor = "#000";
          colorObj.onHoverBorderColor = "#FEE005";
          colorObj.onHoverBgColor = "#FEE005";
          colorObj.onHoverTextColor = "#1b283f";
          colorObj.onHoverInColor = "#1b283f";
          colorObj.hoverOutColor = "#fff";
          colorObj.lineargradient = "0 0 25px rgba(255,167,162, 0.5)";
          colorObj.border = "2px solid #FEE005 !important";
          colorObj.onHoverBorder = "2px solid #1b283f !important";
          colorObj.onSelectionbgcolor = "#FEE005";
          return colorObj;
        }
      }
      if (theme === "eighth-theme") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#cb232e";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(255,167,162, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#cb232e";
        colorObj.onHoverBgColor = "#cb232e";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#cb232e";
        colorObj.hoverOutColor = "#cb232e";
        colorObj.lineargradient = "0 0 25px rgba(255,167,162, 0.5)";
        colorObj.border = "2px solid #cb232e !important";
        colorObj.onHoverBorder = "2px solid #cb232e !important";
        colorObj.onSelectionbgcolor = "#cb232e";
        return colorObj;
      }
      if (theme === "ninth-theme") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#00467f";
        colorObj.lightBackgroundColor = "#00467f";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(255,167,162, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#e97d00";
        colorObj.onHoverBgColor = "#e97d00";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#e97d00";
        colorObj.hoverOutColor = "#fff";
        colorObj.lineargradient = "0 0 25px rgba(255,167,162, 0.5)";
        colorObj.border = "2px solid #00467f !important";
        colorObj.onHoverBorder = "2px solid #e97d00 !important";
        colorObj.onSelectionbgcolor = "#e97d00";
        return colorObj;
      }
      if (theme === "red-white") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#ae0000";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 25px rgba(238,204,204, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#ae0000";
        colorObj.onHoverBgColor = "#fff";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#ae0000";
        colorObj.hoverOutColor = "#ae0000";
        colorObj.lineargradient = "#ae0000";
        colorObj.border = "2px solid #ae0000 !important";
        colorObj.onHoverBorder = "2px solid #ae0000 !important";
        colorObj.onSelectionbgcolor = "#ae0000";
        return colorObj;
      }
      if (theme === "green-black") {
        colorObj.borderColor = "#7a776d";
        colorObj.backgroundColor = "#017A01";
        colorObj.lightBackgroundColor = "#000";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(194,228,203, 0.5)";
        colorObj.labelcolor = surveyLayout === "sixthlayout" ? "#fff" : "#000";
        colorObj.onHoverBorderColor = "#35A753";
        colorObj.onHoverBgColor = "#017A01";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#017A01";
        colorObj.hoverOutColor = "#fff";
        colorObj.lineargradient = "#017A01";
        colorObj.border =
          surveyLayout === "sixthlayout"
            ? "2px solid #017A01 !important"
            : "2px solid #000 !important";
        colorObj.onHoverBorder = "2px solid #017A01 !important";
        colorObj.onSelectionbgcolor = "#017A01";
        return colorObj;
      }
      if (theme === "green-drawblue") {
        colorObj.borderColor = "#7a776d";
        colorObj.backgroundColor = "#35A753";
        colorObj.lightBackgroundColor = "#35A753";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(194,228,203, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#35A753";
        colorObj.onHoverBgColor = "#fff";
        colorObj.onHoverTextColor = "#4487F1";
        colorObj.onHoverInColor = "#4487F1";
        colorObj.hoverOutColor = "#fff";
        colorObj.lineargradient = "#7a776d";
        colorObj.border = "2px solid #35A753 !important";
        colorObj.onHoverBorder = "2px solid #4487F1 !important";
        colorObj.onSelectionbgcolor = "#fff";
        return colorObj;
      }
      if (theme === "blue-white") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#0000FE";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(204,204,254, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#0000FE";
        colorObj.onHoverBgColor = "#0000FE";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#0000FE";
        colorObj.hoverOutColor = "#0000FE";
        colorObj.lineargradient = "#0000FE";
        colorObj.border = "2px solid #0000FE !important";
        colorObj.onHoverBorder = "2px solid #0000FE !important";
        colorObj.onSelectionbgcolor = "#0000FE";
        return colorObj;
      }
      if (theme === "pink-white") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#F591C9";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(238,204,204, 0.5)";
        colorObj.labelcolor = "#585555";
        colorObj.onHoverBorderColor = "#F699CD";
        colorObj.onHoverBgColor = "#F591C9";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#F591C9";
        colorObj.hoverOutColor = "#F591C9";
        colorObj.lineargradient = "#F591C9";
        colorObj.border = "2px solid #F591C9 !important";
        colorObj.onHoverBorder = "2px solid #F699CD !important";
        colorObj.onSelectionbgcolor = "#F591C9";
        return colorObj;
      }
      if (theme === "pink-black") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#F591C9";
        colorObj.lightBackgroundColor = "#000";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(238,204,204, 0.5)";
        colorObj.labelcolor = surveyLayout === "sixthlayout" ? "#fff" : "#000";
        colorObj.onHoverBorderColor = "#F699CD";
        colorObj.onHoverBgColor = "#F591C9";
        colorObj.onHoverTextColor = "#000";
        colorObj.onHoverInColor = "#F591C9";
        colorObj.hoverOutColor = "#F591C9";
        colorObj.lineargradient = "#F591C9";
        colorObj.border = "2px solid #F591C9 !important";
        colorObj.onHoverBorder = "2px solid #F699CD !important";
        colorObj.onSelectionbgcolor = "#F591C9";
        return colorObj;
      }
      if (theme === "blue-aqua") {
        colorObj.borderColor = "#585555";
        colorObj.backgroundColor = "#02E3F7";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(206,220,245, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#0C51CF";
        colorObj.onHoverBgColor = "#fff";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#0C51CF";
        colorObj.hoverOutColor = "#0C51CF";
        colorObj.lineargradient = "#0C51CF";
        colorObj.border = "2px solid #0C51CF !important";
        colorObj.onHoverBorder = "2px solid #0C51CF !important";
        colorObj.onSelectionbgcolor = "#0C51CF";
        return colorObj;
      }
      if (theme === "red-white-black") {
        if (surveyLayout === "azx7layout") {
          colorObj.borderColor = "#c5c5c5";
          colorObj.backgroundColor = "#000";
          colorObj.lightBackgroundColor = "#414243";
          colorObj.textColor = "#000";
          colorObj.boxShadow = "0 0 25px rgba(255,167,162, 0.5)";
          colorObj.labelcolor = "#000";
          colorObj.onHoverBorderColor = "#000";
          colorObj.onHoverBgColor = "#000";
          colorObj.onHoverTextColor = "#fff";
          colorObj.onHoverInColor = "#F9A638";
          colorObj.hoverOutColor = "#fff";
          colorObj.lineargradient = "0 0 25px rgba(255,167,162, 0.5)";
          colorObj.border = "2px solid #414243 !important";
          colorObj.onHoverBorder = "2px solid #F9A638 !important";
          colorObj.onSelectionbgcolor = "#F9A638";
          return colorObj;
        }
        if (surveyLayout === "azx9layout") {
          colorObj.borderColor = "#c5c5c5";
          colorObj.backgroundColor = "#000";
          colorObj.lightBackgroundColor = "#fff";
          colorObj.textColor = "#000";
          colorObj.boxShadow = "0 0 25px rgba(255,167,162, 0.5)";
          colorObj.labelcolor = "#000";
          colorObj.onHoverBorderColor = "#000";
          colorObj.onHoverBgColor = "#000";
          colorObj.onHoverTextColor = "#fff";
          colorObj.onHoverInColor = "#242424";
          colorObj.hoverOutColor = "#000";
          colorObj.lineargradient = "0 0 25px rgba(255,167,162, 0.5)";
          colorObj.border = "2px solid #000 !important";
          colorObj.onHoverBorder = "2px solid #242424 !important";
          colorObj.onSelectionbgcolor = "#242424";
          return colorObj;
        }
         else {
          colorObj.borderColor = "#c5c5c5";
          colorObj.backgroundColor = "#000";
          colorObj.lightBackgroundColor = "#fff";
          colorObj.textColor = "#000";
          colorObj.boxShadow = "0 0 25px rgba(255,167,162, 0.5)";
          colorObj.labelcolor = "#000";
          colorObj.onHoverBorderColor = "#000";
          colorObj.onHoverBgColor = "#000";
          colorObj.onHoverTextColor = "#fff";
          colorObj.onHoverInColor = "#ED1C2E";
          colorObj.hoverOutColor = "#000";
          colorObj.lineargradient = "0 0 25px rgba(255,167,162, 0.5)";
          colorObj.border = "2px solid #000 !important";
          colorObj.onHoverBorder = "2px solid #ED1C2E !important";
          colorObj.onSelectionbgcolor = "#ED1C2E";
          return colorObj;
        }
      }
      if (theme === "aqua-green-black") {
        colorObj.borderColor = "#585555";
        colorObj.backgroundColor = "#02E3F7";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(206,220,245, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#57b7b3";
        colorObj.onHoverBgColor = "#fff";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#57b7b3";
        colorObj.hoverOutColor = "#57b7b3";
        colorObj.lineargradient = "#57b7b3";
        colorObj.border = "2px solid #57b7b3 !important";
        colorObj.onHoverBorder = "2px solid #57b7b3 !important";
        colorObj.onSelectionbgcolor = "#57b7b3";
        return colorObj;
      }
      if (theme === "midnight-blue-green") {
        colorObj.borderColor = "#585555";
        colorObj.backgroundColor = "#02E3F7";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(206,220,245, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#69bd49";
        colorObj.onHoverBgColor = "#fff";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#69bd49";
        colorObj.hoverOutColor = "#69bd49";
        colorObj.lineargradient = "#69bd49";
        colorObj.border = "2px solid #69bd49 !important";
        colorObj.onHoverBorder = "2px solid #69bd49 !important";
        colorObj.onSelectionbgcolor = "#69bd49";
        return colorObj;
      }
      if (theme === "magenta") {
        colorObj.borderColor = "#585555";
        colorObj.backgroundColor = "#02E3F7";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(206,220,245, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#562463";
        colorObj.onHoverBgColor = "#fff";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#562463";
        colorObj.hoverOutColor = "#562463";
        colorObj.lineargradient = "#562463";
        colorObj.border = "2px solid #562463 !important";
        colorObj.onHoverBorder = "2px solid #562463 !important";
        colorObj.onSelectionbgcolor = "#562463";
        return colorObj;
      }
      if (theme === "cerulean") {
        colorObj.borderColor = "#585555";
        colorObj.backgroundColor = "#02E3F7";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(206,220,245, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#004A8E";
        colorObj.onHoverBgColor = "#fff";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#004A8E";
        colorObj.hoverOutColor = "#004A8E";
        colorObj.lineargradient = "#004A8E";
        colorObj.border = "2px solid #004A8E !important";
        colorObj.onHoverBorder = "2px solid #004A8E !important";
        colorObj.onSelectionbgcolor = "#004A8E";
        return colorObj;
      }

      else {
        colorObj.borderColor = "#585555";
        colorObj.backgroundColor = "#02E3F7";
        colorObj.lightBackgroundColor = "#fff";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(206,220,245, 0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#69bd49";
        colorObj.onHoverBgColor = "#fff";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#69bd49";
        colorObj.hoverOutColor = "#69bd49";
        colorObj.lineargradient = "#69bd49";
        colorObj.border = "2px solid #69bd49 !important";
        colorObj.onHoverBorder = "2px solid #69bd49 !important";
        colorObj.onSelectionbgcolor = "#69bd49";
        return colorObj;
      }
    }
    else if (layout_id === "8") {
      if (theme === "first-theme") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#b1e5f1";
        colorObj.lightBackgroundColor = "#f5fdfe";
        colorObj.textColor = "#1b283f";
        colorObj.boxShadow = "0 0 25px rgba(255,167,162, 0.5)";
        colorObj.labelcolor = "#585555";
        colorObj.onHoverBorderColor = "#b1e5f1";
        colorObj.onHoverBgColor = "#1b283f";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#1b283f";
        colorObj.hoverOutColor = "#1b283f ";
        colorObj.lineargradient = "0 0 25px rgba(255,167,162, 0.5)";
        colorObj.border = "2px solid #b1e5f1 !important";
        colorObj.onHoverBorder = "2px solid #1b283f !important";
        colorObj.onSelectionbgcolor = "#1b283f";
        return colorObj;
      }
      if (theme === "eighth-theme") {
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#fff";
        colorObj.lightBackgroundColor = "#fef5f5";
        colorObj.textColor = "#000";
        colorObj.boxShadow = "0 0 25px rgba(255,167,162, 0.5)";
        colorObj.labelcolor = "#585555";
        colorObj.onHoverBorderColor = "#cb232e";
        colorObj.onHoverBgColor = "#cb232e";
        colorObj.onHoverTextColor = "#fff";
        colorObj.onHoverInColor = "#cb232e";
        colorObj.hoverOutColor = "#000";
        colorObj.lineargradient = "0 0 25px rgba(255,167,162, 0.5)";
        colorObj.border = "2px solid #f1b1b7 !important";
        colorObj.onHoverBorder = "2px solid #cb232e !important";
        colorObj.onSelectionbgcolor = "#cb232e";
        return colorObj;
      }
    }
    else if (layout_id === "4" || layout_id === "1" || layout_id === "7" ) {
      if (theme === "default-theme") {
        colorObj.onHoverInColor = "#19ad91";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#b6e4db";
        colorObj.lightBackgroundColor = "#b6e4db";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(182,228,219,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#19ad91";
        colorObj.onHoverBgColor = "#19ad91";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#000";
        colorObj.lineargradient = "linear-gradient(to left, #b6e4db , #19ad91)";
        colorObj.border = "2px solid #b6e4db !important";
        colorObj.onHoverBorder = "2px solid #19ad91 !important";
        colorObj.onSelectionbgcolor = "#19ad91";
        return colorObj;
      }
      if (theme === "first-theme") {
        // colorObj.onHoverInColor = "#abbcdc";
        colorObj.onHoverInColor = "#405d90";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#abbcdc";
        colorObj.lightBackgroundColor = "#abbcdc";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(171,188,220,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#405d90";
        colorObj.onHoverBgColor = "#405d90";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#1B283F";
        colorObj.lineargradient = "linear-gradient(to left, #abbcdc , #405d90)";
        colorObj.border = "2px solid #abbcdc !important";
        colorObj.onHoverBorder = "2px solid #405d90 !important";
        colorObj.onSelectionbgcolor = "#405d90";
        return colorObj;
      }
      if (theme === "second-theme") {
        colorObj.onHoverInColor = "#716ed2";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#aba8ff";
        colorObj.lightBackgroundColor = "#cccbf9";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(171,188,220,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#aba8ff";
        colorObj.onHoverBgColor = "#aba8ff";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#000 ";
        colorObj.lineargradient = "linear-gradient(to left, #aba8ff , #716ed2)";
        colorObj.border = "2px solid #cccbf9 !important";
        colorObj.onHoverBorder = "2px solid #aba8ff !important";
        colorObj.onSelectionbgcolor = "#716ed2";
        return colorObj;
      }
      if (theme === "third-theme") {
        colorObj.onHoverInColor = "#4094a9";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#4094a9";
        colorObj.lightBackgroundColor = "#c2e2e8";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(64,148,169,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#4094a9";
        colorObj.onHoverBgColor = "#4094a9";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#000";
        colorObj.lineargradient = "linear-gradient(to left, #c2e2e8 , #4094a9)";
        colorObj.border = "2px solid #c2e2e8 !important";
        colorObj.onHoverBorder = "2px solid #4094a9 !important";
        colorObj.onSelectionbgcolor = "#4094a9";
        return colorObj;
      }
      if (theme === "fourth-theme") {
        colorObj.onHoverInColor = "#FF4E44";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#FF4E44";
        colorObj.lightBackgroundColor = "#eedddd";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(238,221,221,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#FF4E44";
        colorObj.onHoverBgColor = "#FF4E44";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#000 ";
        colorObj.lineargradient = "linear-gradient(to left, #eedddd , #FF4E44)";
        colorObj.border = "2px solid #eedddd !important";
        colorObj.onHoverBorder = "2px solid #FF4E44 !important";
        colorObj.onSelectionbgcolor = "#FF4E44";
        return colorObj;
      }
      if (theme === "fifth-theme") {
        colorObj.onHoverInColor = "#F47621";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#F47621";
        colorObj.lightBackgroundColor = "#eedddd";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(238,221,221,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#F47621";
        colorObj.onHoverBgColor = "#F47621";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#F47621 ";
        colorObj.lineargradient = "linear-gradient(to left, #eedddd , #F47621)";
        colorObj.border = "2px solid #eedddd !important";
        colorObj.onHoverBorder = "2px solid #F47621 !important";
        colorObj.onSelectionbgcolor = "#F47621";
        return colorObj;
      }
      if (theme === "sixth-theme") {
        colorObj.onHoverInColor = "#5c0f8b";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#5c0f8b";
        colorObj.lightBackgroundColor = "#ead1ff";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(234,209,255,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#5c0f8b";
        colorObj.onHoverBgColor = "#5c0f8b";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#5c0f8b ";
        colorObj.lineargradient = "linear-gradient(to left, #ead1ff , #5c0f8b)";
        colorObj.border = "2px solid #ead1ff !important";
        colorObj.onHoverBorder = "2px solid #5c0f8b !important";
        colorObj.onSelectionbgcolor = "#5c0f8b";
        return colorObj;
      }
      if (theme === "seventh-theme") {
        colorObj.onHoverInColor = "#1b283f";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#1b283f";
        colorObj.lightBackgroundColor = "#fbc417";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(253,237,185,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#1b283f";
        colorObj.onHoverBgColor = "#1b283f";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#1b283f ";
        colorObj.lineargradient = "linear-gradient(to left, #fbc417 , #1b283f)";
        colorObj.border = "2px solid #fbc417 !important";
        colorObj.onHoverBorder = "2px solid #1b283f !important";
        colorObj.onSelectionbgcolor = "#1b283f";
        return colorObj;
      }
      if (theme === "eighth-theme") {
        colorObj.onHoverInColor = "#cb232e";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#cb232e";
        colorObj.lightBackgroundColor = "#ecacb0";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(236,172,176,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#cb232e";
        colorObj.onHoverBgColor = "#cb232e";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#000 ";
        colorObj.lineargradient = "linear-gradient(to left, #ecacb0 , #cb232e)";
        colorObj.border = "2px solid #ecacb0 !important";
        colorObj.onHoverBorder = "2px solid #cb232e !important";
        colorObj.onSelectionbgcolor = "#cb232e";
        return colorObj;
      }
      if (theme === "ninth-theme") {
        colorObj.onHoverInColor = "#00467f";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#00467f";
        colorObj.lightBackgroundColor = "#e97d00";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(248,216,178,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#00467f";
        colorObj.onHoverBgColor = "#00467f";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#fff";
        colorObj.lineargradient = "linear-gradient(to left, #e97d00 , #00467f)";
        colorObj.border = "2px solid #e97d00 !important";
        colorObj.onHoverBorder = "2px solid #00467f !important";
        colorObj.onSelectionbgcolor = "#00467f";
        return colorObj;
      }
    }
    else if (layout_id === "2" ) {
        colorObj.onHoverInColor = "#ec1c2a";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#ec1c2a";
        colorObj.lightBackgroundColor = "#eedddd";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(238,221,221,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#ec1c2a";
        colorObj.onHoverBgColor = "#ec1c2a";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#000 ";
        colorObj.lineargradient = "linear-gradient(to left, #eedddd , #ec1c2a)";
        colorObj.border = "2px solid #eedddd !important";
        colorObj.onHoverBorder = "2px solid #ec1c2a !important";
        colorObj.onSelectionbgcolor = "#ec1c2a";
        return colorObj;
    }
    else{
        colorObj.onHoverInColor = "#19ad91";
        colorObj.borderColor = "#c5c5c5";
        colorObj.backgroundColor = "#b6e4db";
        colorObj.lightBackgroundColor = "#b6e4db";
        colorObj.textColor = "#7a776d";
        colorObj.boxShadow = "0 0 10px rgba(182,228,219,0.5)";
        colorObj.labelcolor = "#000";
        colorObj.onHoverBorderColor = "#19ad91";
        colorObj.onHoverBgColor = "#19ad91";
        colorObj.onHoverTextColor = "#fff";
        colorObj.hoverOutColor = "#000";
        colorObj.lineargradient = "linear-gradient(to left, #b6e4db , #19ad91)";
        colorObj.border = "2px solid #b6e4db !important";
        colorObj.onHoverBorder = "2px solid #19ad91 !important";
        colorObj.onSelectionbgcolor = "#19ad91";
        return colorObj;
    
    }
  };
}
export default themecolors;
