/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Component } from "react";
//import LayoutThird from "../layout-third";
import { getSiteData } from "../../actions/manage-site-action-type";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import DoNotQualifyMain from "./do-not-qualify";
import LayoutFirstDemo from "../layout-first-demo";
//import LayoutSecond from "../layout-second";
import LayoutFourth from "../layout-fourth";
import LayoutSixth from "../layout-sixth";
import Utils from "../../utils";
import Api from "../../api";
import LoaderComponent from "../common/loader/loader";

class DoNotQualify extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: "3",
      page: 1,
      isPageRefreshed: false,
    };
  }

  componentDidMount() {
    const { getSiteData, getSiteDataObject } = this.props;
    getSiteDataObject && getSiteDataObject.id && Api.trackDnqPage(getSiteDataObject.id);
    if (getSiteDataObject.length === 0) {
      this.setState({ isPageRefreshed: true });
      getSiteData(this.state.page);
    } else {
      Utils.renderFont(getSiteDataObject);
    }
  }

  render() {
    const { isLoading, getSiteDataObject } = this.props;
    const { isPageRefreshed } = this.state;
    return getSiteDataObject &&
      getSiteDataObject.content_manage_sites &&
      getSiteDataObject.content_manage_sites.length > 0 ? (
      getSiteDataObject.theme === "1" ? (
        <>
          <LayoutFirstDemo
            siteData={getSiteDataObject}
            pageType={"donotqualify"}
            page={7}
            isPageRefreshed={isPageRefreshed}
          >
            <DoNotQualifyMain
              siteData={getSiteDataObject}
              layoutType={getSiteDataObject.theme}
            />
          </LayoutFirstDemo>
        </>
      ) : // : getSiteDataObject.theme === "2" ? (
        //   <LayoutSecond
        //     siteData={getSiteDataObject}
        //     pageType={"donotqualify"}
        //     page={7}
        //     isPageRefreshed={isPageRefreshed}
        //   >
        //     <DoNotQualifyMain
        //       siteData={getSiteDataObject}
        //       layoutType={getSiteDataObject.theme}
        //     />
        //   </LayoutSecond>
        // )
        //  : getSiteDataObject.theme === "3" ? (
        //   <LayoutThird
        //     siteData={getSiteDataObject}
        //     pageType={"donotqualify"}
        //     isPageRefreshed={isPageRefreshed}
        //     page={7}
        //   >
        //     <DoNotQualifyMain
        //       siteData={getSiteDataObject}
        //       layoutType={getSiteDataObject.theme}
        //     />
        //   </LayoutThird>
        // )
        getSiteDataObject.theme === "5" ? (
          <LayoutSixth
            siteData={getSiteDataObject}
            pageType={"donotqualify"}
            isPageRefreshed={isPageRefreshed}
            page={7}
          >
            <DoNotQualifyMain
              siteData={getSiteDataObject}
              layoutType={getSiteDataObject.theme}
            />
          </LayoutSixth>
        ) : (
          <LayoutFourth
            siteData={getSiteDataObject}
            pageType={"donotqualify"}
            isPageRefreshed={isPageRefreshed}
            page={7}
          >
            <DoNotQualifyMain
              siteData={getSiteDataObject}
              layoutType={getSiteDataObject.theme}
            />
          </LayoutFourth>
        )
    ) : isLoading ? (
      <LoaderComponent />
    ) : (
      <></>
    );
  }
}

const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({ isLoading, getSiteDataObject, getSiteStatus, getSiteError });
export default connect(mapStateToProps, { getSiteData })(DoNotQualify);
