import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { object, string, func, bool } from "prop-types";
import "./input-field.scss";
import Error from "../../components/error";
import fieldValidation from "../../utils/validate";
import { checkValidationRule } from "../../utils/validate";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";
import Utils from "../../utils";
import axios from "axios";
import { CircularLoaderSVG } from "../../assets/svg/circular_loader_svg";
const convert = require("xml-js");

const AutolifeInputField = ({
  name,
  fieldAttributes,
  classname,
  label,
  validate,
  validateExecutive,
  placeHolder,
  validationRule,
  validateOptions,
  validationCallback,
  description,
  type,
  showErrorText,
  theme_id,
  isPhoneValid,
  subtype,
  theme,
  iconName,
  surveyLayout,
  FirstName,
  themecolors,
  activestep,
  handleChange,
}) => {
  const [errors, setError] = useState({});
  const { value } = fieldAttributes;
  const [inputValue, setInputvalue] = useState("");
  const { [name]: error = "" } = errors;
  const [loading, setLoading] = useState(false);

  const { required } = validateOptions;
  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };

    if (checkValidationRule(isVisible, validationRule)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_TYPE" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }
    validationCallback(!!errorMsg);
  };
  const useStyles = makeStyles((theme) => ({
    formControl: {},
    root: {
      display: "flex",
      flexDirection: "row",
    },
    formLabelroot: {
      display: "flex",
      paddingLeft: "0px !important",
      alignItems: "center",
      color: "#000 !important",
      fontSize: "14px",
      fontWeight: "bold",
    },
    formLabelrootInline: {
      paddingLeft: "0px !important",
      color: (props) => `${props.themecolors.labelcolor} !important`,
      fontSize: "30px",
      padding: "10px",
      display: "flex",
      fontWeight: "500",
    },
    formInputSize: {
      fontSize: "large !important",
    },
    spanIn: {
      position: "absolute",
      marginLeft: "5px",
      height: "25px",
      display: "flex",
      alignItems: "center",
    },
    inputBox: {
      paddingLeft: "25px",
      height: "20px",
    },
    mainDiv: {
      position: "relative",
      paddingTop: "0",
      margin: "0",
      //marginTop: "-29px",
      display: "inline-block",
      width: "100%",
    },
    icon: {
      // position: "absolute",
      //left: "8px",
      width: "27px",
      fontSize: "27px",
      height: "30px",
    },
    SurveyType: {
      marginTop: "43px",
    },
    OtherType: {
      marginTop: "37px",
    },
    inputDiv: {
      color: (props) => `${props.themecolors.textColor} `,
      margin: "0",
      paddingLeft: "45px !important",
      fontSize: "16px",
    },
    iconDiv: {
      width: "35px",
      height: "30px",
      position: "absolute",
      left: "8px",
      top: "10px",
    },
    themeLayout: {
      "&:hover": {
        border: (props) => `${props.themecolors.onHoverBorder} `,
      },
      border: "2px solid #c5c5c5 !important",
    },
  }));
  const classes = useStyles({ themecolors });

  const validateFields = (isVisible, e) => {
    const { check, required, reg, regMsg } = validateOptions;

    if (!check) {
      return;
    }
    // console.log(value);

    if (required) {
      let errorMsg = "";
      if (!value || !/^\s*$/.test(JSON.stringify(value))) {
        errorMsg = `${name} is required`;
      }
      if (inputValue && !value) {
        errorMsg = `search your car with registration number`;
      }

      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);
        return;
      }
    }

    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    } else if (validateExecutive) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
  });

  const [colHovor, SetolHovor] = useState(themecolors.textColor);
  const changeColorHoverIn = () => {
    SetolHovor(themecolors.onHoverInColor);
  };
  const changeColorHoverOut = () => {
    SetolHovor(themecolors.textColor);
  };

  const [carDetailArray, setCarDetailArray] = useState([]);

  const getDetails = async (text) => {
    try {
      // console.log(text);
      setLoading(true);
      if (!text) {
        return [];
      }

      const baseurl = Utils.getHost().apiUrl;

      const data = JSON.stringify({
        regno: inputValue,
        // host: window.location.host,
      });

      const config = {
        method: "post",
        url: `${baseurl}carregcheck`,
        headers: {
          Authorization: "c2xkZ21sa21nbG1s",
          "Content-Type": "application/json",
        },
        data: data,
      };

      const response = await axios(config);
      const result = response?.data?.message || null;

      if (result) {
        const xmlOptions = {
          compact: true,
          spaces: 2,
        };
        const jsonResult = convert.xml2json(result, xmlOptions);

        const vehicleJson = JSON.parse(
          JSON.parse(jsonResult)["Vehicle"]["vehicleJson"]["_text"]
        );

        const textArry = [];
        Object.entries(vehicleJson).map(([key, value]) => {
          const currentValue =
            value.CurrentTextValue !== undefined
              ? value.CurrentTextValue
              : value || value;
          textArry.push({ label: key, text: currentValue });
        });
        textArry.push({ label: "RegNo", text: inputValue });

        // console.log(textArry);
        setCarDetailArray(textArry);
        handleChange(JSON.stringify(textArry));
      } else {
        setCarDetailArray([]);
      }
      setLoading(false);
    } catch (err) {
      console.error("Error:", err);
      setCarDetailArray([]);
      setLoading(false);

    }
  };
  // console.log(carDetailArray);
  const autoCompleteName = (name) => {
    switch (name.toLowerCase()) {
      case "fullname":
      case "full-name":
        return "name";
      case "firstname":
      case "first-name":
      case "fname":
        return "given-name";
      case "lastname":
      case "last-name":
      case "lname":
        return "family-name";
      case "email":
        return "email";
      default: return "off";
    }
  }
  useEffect(() => {
    const inputElement = document.getElementById(`step_${activestep}_${name}`);
    if (inputElement) {
      inputElement.setAttribute("autocomplete", autoCompleteName(name));
    }
  }, [activestep, name]);
  return (
    <>
      <Grid
        className={`input-box ${!!error && type !== "survey" && "input-box--error"
          }`}
      >
        {!!label && theme_id !== 3 && theme_id !== 2 && (
          <FormLabel
            className={
              type === "survey" && surveyLayout !== "thirdlayout"
                ? classes.formLabelrootInline
                : classes.formLabelroot
            }
          >
            <div
              className={
                theme === "ninth-theme" && theme_id !== "5" ? "labelColor" : ""
              }
            >
              {label}&nbsp;
            </div>
            {required && type !== "survey" && (
              <span className="mendatory">*</span>
            )}
            {!!description && (
              <Tooltip title={description} matTooltipClass="tooltip" />
            )}
          </FormLabel>
        )}

        <div
          onMouseOver={changeColorHoverIn}
          onFocus={changeColorHoverIn}
          onMouseLeave={changeColorHoverOut}
          className={theme_id === 2 ? "form-group" : `${classes.mainDiv}`}
        >
          <div className={classes.iconDiv}>
            <i
              className={`demo-icon ${iconName} ${classes.icon}
          ${type === "survey" ? classes.SurveyType : classes.OtherType}`}
              style={{
                color: `${colHovor}`,
              }}
            ></i>
          </div>
          <input
            id={`step_${activestep}_${name}`}
            className={
              theme_id === 2
                ? "form-control form-control-lg"
                : `${classes.inputDiv} 
          ${classes.themeLayout} input-box__field 
        ${type === "survey" && "input-box__field--survey"} ${classname} 

        ${classname} ${!!error && type !== "survey" && "input-box__field--errord"
                } `
            }
            onBlur={(e) => validateFields("VALIDATE_ON_BLUR", e)}
            onKeyUp={(e) => validateFields("VALIDATE_ON_TYPE", e)}
            {...fieldAttributes}
            placeholder={placeHolder}
            required
            onChange={(e) => setInputvalue(e.target.value)}
            value={inputValue}
          />
          {loading ? (
            <div className="circular-loader">
              <CircularLoaderSVG />
            </div>
          ) : (
            <button
              className="search-autolife"
              onClick={() => getDetails(inputValue)}
            >
              search
            </button>
          )}
          {theme_id === 2 && (
            <label>
              {label}
              <span>*</span>
            </label>
          )}
        </div>

        {!!error && showErrorText && theme_id !== 3 && (
          <Error errorMessage={error} />
        )}
      </Grid>
      {/* {console.log(carDetailArray)} */}
      {carDetailArray && carDetailArray.length > 0 && (
        <div>
          <ul className="vehicle-detail">
            {carDetailArray.map(
              (item, index) =>
                [
                  "Chassis",
                  "RegistrationYear",
                  "CarMake",
                  "CarModel",
                  "EngineSize",
                  "FuelType",
                  "ModelDescription",
                  "BodyStyle",
                ].includes(item.label) && (
                  <li key={index}>
                    <strong>{item.label}:</strong> {item.text}
                  </li>
                )
            )}
          </ul>
        </div>
      )}
    </>
  );
};

AutolifeInputField.propTypes = {
  fieldAttributes: object,
  classname: string,
  label: string.isRequired,
  name: string.isRequired,
  validationCallback: func.isRequired,
  showErrorText: bool,
};

AutolifeInputField.defaultProps = {
  fieldAttributes: {},
  classname: "",
  validateOptions: {
    check: false,
  },
  showErrorText: true,
  theme_id: 0,
  fontsize: "",
  color: "",
};

export default AutolifeInputField;
