import React from "react";
import DynamicForm from "../dynamic-form";
import { Helmet } from "react-helmet";
import { string, object } from "prop-types";
import Utils from "../../utils/index";

const AboutUsBody = (props) => {
  const { siteData, layoutType } = props;

  let siteDataValue = Utils.getPageContent(siteData, 2);
  let siteFormData = Utils.getDynamciFormContent(siteData, 2);

  if (siteDataValue === undefined && siteDataValue === null) {
    if (layoutType !== "4") window.location.href = "/";
  }

  const rendermetatags = (title, decsription, favicon) => {
    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={decsription} />
        <link rel="icon" href={favicon} sizes="16x16" />
      </Helmet>
    );
  };

  return (
    <>
      {layoutType === "1" && (
        <>
          <div
            className="pageTitle"
            style={{ backgroundColor: siteData.primary_color }}
          >
            {rendermetatags(
              siteDataValue.meta_title,
              siteDataValue.meta_description,
              siteData.favicon
            )}

            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1>{siteDataValue.page_title}</h1>
                  <p>{siteDataValue.page_sub_title}</p>
                </div>
              </div>
            </div>
          </div>
          <section className="innerPageContent">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="body-content1">
                    <div className="contentAboutLeft">
                      <h2>{siteDataValue.heading}</h2>
                      <h4>
                        <span className="text-red">
                          {siteDataValue.sub_heading}
                        </span>
                      </h4>

                      {siteDataValue.section_first_img ? (
                        <img
                          alt={siteDataValue.sfi_alt_text}
                          src={siteDataValue.section_first_img}
                          className="img-fluid float-left aboutImg"
                          title={siteDataValue.sfi_alt_text}
                        />
                      ) : (
                        <></>
                      )}
                    </div>

                    <div
                      className="contentAbout"
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.content,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {siteFormData && siteFormData.data && siteFormData.data.data ? (
            <section className="contact-block contactWrapperinner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <form id="contact-form" className="contactWrapper1 width80">
                      <div className="form-heading">
                        <h2>{siteDataValue.section_heading}</h2>
                        <div className="messages">
                          {siteDataValue.section_sub_heading}
                        </div>
                      </div>

                      <div className="controls">
                        <div className="row">
                          {siteFormData.form_heading && (
                            <h1 className="form-heading-css">
                              {siteFormData.form_heading}
                            </h1>
                          )}

                          <DynamicForm
                            data={siteFormData.data.data}
                            siteData={siteData}
                            page_id={2}
                            theme_id={1}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
        </>
      )}
      {layoutType === "8" && (
        <>
          <div
            className="pageTitle"
            style={{ backgroundColor: siteData.primary_color }}
          >
            {rendermetatags(
              siteDataValue.meta_title,
              siteDataValue.meta_description,
              siteData.favicon
            )}

            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1>{siteDataValue.page_title}</h1>
                  <p>{siteDataValue.page_sub_title}</p>
                </div>
              </div>
            </div>
          </div>
          <section className="innerPageContent">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="body-content1">
                    <div className="contentAboutLeft">
                      <h2>{siteDataValue.heading}</h2>
                      <h4>
                        <span className="text-red">
                          {siteDataValue.sub_heading}
                        </span>
                      </h4>

                      {siteDataValue.section_first_img ? (
                        <img
                          alt={siteDataValue.sfi_alt_text}
                          src={siteDataValue.section_first_img}
                          className="img-fluid float-left aboutImg"
                          title={siteDataValue.sfi_alt_text}
                        />
                      ) : (
                        <></>
                      )}
                    </div>

                    <div
                      className="contentAbout"
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.content,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {siteFormData && siteFormData.data && siteFormData.data.data ? (
            <section className="contact-block contactWrapperinner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <form id="contact-form" className="contactWrapper1 width80">
                      <div className="form-heading">
                        <h2>{siteDataValue.section_heading}</h2>
                        <div className="messages">
                          {siteDataValue.section_sub_heading}
                        </div>
                      </div>

                      <div className="controls">
                        <div className="row">
                          {siteFormData.form_heading && (
                            <h1 className="form-heading-css">
                              {siteFormData.form_heading}
                            </h1>
                          )}

                          <DynamicForm
                            data={siteFormData.data.data}
                            siteData={siteData}
                            page_id={2}
                            theme_id={1}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
        </>
      )}
      
      {layoutType === "4" && (
        <>
          <section className="bgbluew"></section>
          {rendermetatags(
            siteDataValue.meta_title,
            siteDataValue.meta_description,
            siteData.favicon
          )}

          <section className="innerpage">
            <div className="container">
              <div className="col-sm-12">
                <div className="content-innerpages">
                  <h2>{siteDataValue && siteDataValue.page_title}</h2>
                  <div className="bototmmcontent">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.section_second_paragraph,
                      }}
                    ></div>
                    <img
                      className="img-responsive"
                      src={siteDataValue.section_first_img}
                      alt={siteDataValue.sfi_alt_text}
                    ></img>
                  </div>

                  {siteFormData && siteFormData.data && siteFormData.data.data && (
                    <div className="controls">
                      <DynamicForm
                        data={siteFormData.data.data}
                        siteData={siteData}
                        page_id={2}
                        theme_id={4}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}
      {layoutType === "7" && (
        <>
          {rendermetatags(
            siteDataValue.meta_title,
            siteDataValue.meta_description,
            siteData.favicon
          )}

          <section className="newThemeinnerPage">
            <div className="container">
              <div className="pageTitleInner">
                <h1>{siteDataValue.page_title}</h1>
                <h2>{siteDataValue.page_sub_title}</h2>
              </div>

              <div className="aboutPageContentNew">
                <div className="aboutPageLeftContent">
                  <div className="aboutPageLeftContentInside">
                    <h2>{siteDataValue.heading}</h2>
                    <p>{siteDataValue.sub_heading}</p>
                  </div>
                </div>
                <div
                  className="aboutPageImg"
                  style={{
                    backgroundImage: `url(${siteDataValue.section_first_img})`,
                    maxWidth: "100%",
                  }}
                ></div>
              </div>
              <div
                className="contentAreaInner"
                dangerouslySetInnerHTML={{ __html: siteDataValue.content }}
              ></div>
            </div>
          </section>
        </>
      )}
      {layoutType === "5" && (
        <>
          <div
            className="pageTitle"
            style={{ backgroundColor: siteData.primary_color }}
          >
            {rendermetatags(
              siteDataValue.meta_title,
              siteDataValue.meta_description,
              siteData.favicon
            )}

            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1>{siteDataValue.page_title}</h1>
                  <p>{siteDataValue.page_sub_title}</p>
                </div>
              </div>
            </div>
          </div>
          <section className="innerPageContent">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="body-content1">
                    <div className="contentAboutLeft">
                      <h2>{siteDataValue.heading}</h2>
                      <h4>
                        <span className="text-red">
                          {siteDataValue.sub_heading}
                        </span>
                      </h4>
                    </div>
                    <div className="imgAbout">
                      {siteDataValue.section_first_img ? (
                        <img
                          alt={siteDataValue.sfi_alt_text}
                          src={siteDataValue.section_first_img}
                          className="img-fluid float-left aboutImg"
                          title={siteDataValue.sfi_alt_text}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                    <div
                      className="contentAbout"
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.content,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {siteFormData && siteFormData.data && siteFormData.data.data ? (
            <section className="contact-block contactWrapperinner">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <form id="contact-form" className="contactWrapper1 width80">
                      <div className="form-heading">
                        <h2>{siteDataValue.section_heading}</h2>
                        <div className="messages">
                          {siteDataValue.section_sub_heading}
                        </div>
                      </div>

                      <div className="controls">
                        <div className="row">
                          {siteFormData.form_heading && (
                            <h1 className="form-heading-css">
                              {siteFormData.form_heading}
                            </h1>
                          )}

                          <DynamicForm
                            data={siteFormData.data.data}
                            siteData={siteData}
                            page_id={2}
                            theme_id={5}
                          />
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </section>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  );
};

AboutUsBody.propTypes = {
  siteData: object,
  layoutType: string,
};

AboutUsBody.defaultProp = {
  layoutType: "1",
};

export default AboutUsBody;
