import React from "react";
import { object } from "prop-types";
import DynamicForm from "../../../dynamic-form";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { makeStyles } from "@material-ui/core/styles";
import Utils from "../../../../utils";

const useStyles = makeStyles(() => ({
  achorHvr: {
    transition: "ease 0.7s",
    color: "#fff",
    "&:hover": { backgroundColor: "#0766cc !important" },
  },
}));
const BannerPage = (props) => {
  const classses = useStyles();
  const { siteData } = props;
  const matches = useMediaQuery("(max-width:600px)");

  const landing = Utils.getPageContent(siteData, 1);
  const siteFormData = Utils.getDynamciFormContent(siteData, 1);
  const banners = Utils.getBannerContent(siteData);
  const survey_page = Utils.getPageContent(siteData, 6);
  const privacy_policy = Utils.getPageContent(siteData, 4);
  const terms_conditions = Utils.getPageContent(siteData, 5);

  const handleClick = ({ page_url, is_external, target }) => {
    Utils.handleClickUrl(page_url, is_external, target);
  };

  return (
    <>
      <section
        className="banner-home hero"
        style={{
          backgroundImage: `url(${
            matches ? banners && banners.mob_img : banners && banners.img
          })`,
        }}
      >
        <div className="waveImg"></div>
        <div className="container">
          <div className="row">
            <div className="text-contentbanner">
              <div className="col-md-7 left-side">
                <div className="heading-banner">
                  <h1 style={{ color: `${banners && banners.color1}` }}>
                    {banners && banners.heading}
                  </h1>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: banners && banners.sub_heading,
                    }}
                  ></span>
                  <br></br>
                  <div className="getlink">
                    <span
                      onClick={() => handleClick(survey_page)}
                      //className="getlink yellow"
                      className={classses.achorHvr}
                      style={{ cursor: "pointer" }}
                    >
                      {landing.heading}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-5 right-side">
                <div
                  className="form-wrapper nav-stacked"
                  data-spy="affix"
                  data-offset-top="205"
                >
                  <div className="form-container">
                    <h5>{siteFormData && siteFormData.form_heading}</h5>
                    <form id="myDIV">
                      <div className="step1 form-custome-new">
                        <div className="form-group">
                          {siteFormData && (
                            <DynamicForm
                              data={siteFormData && siteFormData.data.data}
                              siteData={siteData}
                              page_id={1}
                              theme_id={4}
                            />
                          )}
                        </div>

                        <p className="form-step-text">
                          {landing.section_second_header}
                        </p>

                        <div className="container-footer">
                          <div className="lock-icon d-inline-flex">
                            <img
                              alt="lock-icon"
                              className="pr-2"
                              src={require("../../../../assets/images/lock.svg")}
                            />
                            <span className="anchorLink">
                              <span onClick={() => handleClick(privacy_policy)}>
                                Privacy Policy
                              </span>{" "}
                              &
                              <span onClick={() => handleClick(terms_conditions)}>
                                {" "}
                                General Terms{" "}
                              </span>
                            </span>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

BannerPage.propTypes = {
  siteData: object,
};

export default BannerPage;
