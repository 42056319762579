import React, { useEffect } from "react";
import { object, string, bool } from "prop-types";
import Error from "../../components/error/index";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";
import { checkValidationRule } from "../../utils/validate";
import "./select-box.scss";

const useStyles = makeStyles((theme) => ({
  formLabelrootInline: {
    paddingLeft: "0px !important",
    color: (props) => `${props.themecolors.labelcolor} !important `,
    fontSize: (props) => (props.layoutTheme === "5" ? "25px" : "30px"),
    padding: " 10px",
    display: "flex",
    fontWeight: (props) => (props.layoutTheme === "5" ? "600" : "500"),
  },
  WhiteLabel: {
    paddingLeft: "0px !important",
    color: "white",
    fontSize: "30px",
    padding: " 10px",
    display: "flex",
    fontWeight: "500",
  },
}));

const CustomDropdown = (props) => {
  const {
    options,
    name,
    label,
    description,
    validateOptions,
    type,
    validate,
    validationCallback,
    value,
    theme_id,
    onCustomDropDownClick,
    theme,
    layoutTheme,
    displayicon,
    data,
    themecolors,
    activestep,
  } = props;

  const [errors, setError] = React.useState({});
  const { [name]: error = "" } = errors;

  const [selectedValue, setSelectedValue] = React.useState(getSelectedValue());

  const classes = useStyles({ themecolors, layoutTheme });

  if (
    window.location.host === "tutorexpert.com.au" ||
    window.location.host === "www.tutorexpert.com.au"
  ) {
    if (data.subject === "Chemistry") {
      options.filter((questuion) => {
        return questuion.value === 12 || questuion.value === 11;
      });
    }
  }
  const handleClick = (value) => {
    onCustomDropDownClick(value);
    setSelectedValue(value);
    setError({});
  };

  function getSelectedValue() {
    var returnValue = "";
    Array.isArray(options)
      ? !!options &&
        options.map((option) => {
          if (option.selected) {
            returnValue = option.value;
          }
          return returnValue;
        })
      : !!options &&
        Object.keys(options).map((option) => {
          if (option.selected) {
            returnValue = options[option];
          }
          return returnValue;
        });
    returnValue = value === undefined || value === "" ? returnValue : value;

    return returnValue;
  }

  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };
    if (checkValidationRule(isVisible)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_CLICK" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const validateFields = (isVisible, e) => {
    const { required } = validateOptions;

    if (required) {
      let errorMsg;

      if (value === "") {
        errorMsg = `${name} is required`;
      } else {
        errorMsg = "";
      }
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }
    handleErrors("", isVisible, e);
  };
  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    // setSelectedValue(value);
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
  });

  return (
    <>
      {!!label && (
        <FormLabel
          className={
            theme === "nine_theme"
              ? classes.WhiteLabel
              : classes.formLabelrootInline
          }
        >
          {label}&nbsp;
          {validateOptions.required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {!!description && <Tooltip title={description} />}
        </FormLabel>
      )}
      <div
        id={`step_${activestep}_${name}`}
        className={`dropdownMain customDropdown-${theme}  ${
          activestep >= 0 ? ` step_${activestep} ` : ""
        }`}
      >
        {Array.isArray(options)
          ? // if options is array with id and name selected={option.selected}
            !!options &&
            options.map((option, index) => (
              <span
                className={`dropdownMainList customDropdown-${theme}_span effect2 ${
                  selectedValue === option.value
                    ? `dropdownMainListSelected customDropdown-${theme}_span_selected`
                    : ""
                }`}
                id={`step_${activestep}_${name}_span`}
                style={{
                  boxShadow:
                    layoutTheme === "4" && "0 3px 6px rgba(51, 51, 51, 0.4)",
                  // border: layoutTheme === "4" && "2px solid #fff",
                  border:
                    layoutTheme === "4" &&
                    `${
                      selectedValue === option.value
                        ? "none"
                        : "1px solid rgb(207 227 234)"
                    }`,
                }}
                value={selectedValue}
                key={index}
                onClick={() => handleClick(option.value)}
              >
                <span
                  className={`dropdownLIstLabel customDropdown-${theme}_span_label`}
                  id={`step_${activestep}_${name}_span_span`}
                >
                  <center
                    style={{
                      marginTop: displayicon === "hide" ? "38px" : "",
                      fontSize: displayicon === "hide" ? "18px" : "",
                    }}
                    id={`step_${activestep}_${name}_span_center`}
                  >
                    {option.label}
                  </center>
                </span>
                <span
                  className={`icon-span-main ${
                    selectedValue === option.value ? "active" : ""
                  }`}
                  style={{
                    display: displayicon === "show" ? "inline" : "none",
                  }}
                >
                  <i
                    className={`demo-icon ${option.icon}`}
                    style={{
                      //color: "white",
                      fontSize: "49px",
                    }}
                  ></i>
                </span>
              </span>
            ))
          : // if options is object
            !!options &&
            Object.keys(options).map((option, index) => (
              <span
                className={`customDropdown-${theme}_span effect2 ${
                  selectedValue === options[option]
                    ? `customDropdown-${theme}_span_selected`
                    : ""
                }`}
                value={selectedValue}
                key={index}
                onClick={() => handleClick(options[option])}
                id={`step_${activestep}_${name}_span`}
              >
                {options[option]}
              </span>
            ))}
      </div>
      {!!error && theme_id !== 3 && <Error errorMessage={error} />}
    </>
  );
};

CustomDropdown.propTypes = {
  fieldAttributes: object,
  name: string.isRequired,
  allowMultiple: bool,
};

CustomDropdown.defaultProps = {
  fieldAttributes: {},
  allowMultiple: false,
  theme_id: 0,
};

export default CustomDropdown;
