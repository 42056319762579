import React from "react";
import Header from "./header";
import Footer from "./footer";
import { string, object, number } from "prop-types";
import "./layout-second.scss";
import "../../assets/css/acquerly-icons.css";

const LayoutSecond = (props) => {
  const { children, pageType, siteData, page, isPageRefreshed } = props;
  return (
    <div className="layout_second">
      <Header siteData={siteData} pageType={pageType} page={page} />
      <main role="main">{children}</main>
      <Footer
        siteData={siteData}
        pageType={pageType}
        page={page}
        isPageRefreshed={isPageRefreshed}
      />
    </div>
  );
};

export default LayoutSecond;

LayoutSecond.propTypes = {
  pageType: string,
  siteData: object,
  page: number,
};

LayoutSecond.defaultProps = {
  pageType: "home",
  page: 1,
};
