import React, { useState, useEffect } from "react";
import Select from "react-select";
import { Grid } from "@material-ui/core";
import { object, string, bool, array, func } from "prop-types";
import Error from "../../components/error/index";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";
import { makeStyles } from "@material-ui/core/styles";
import { checkValidationRule } from "../../utils/validate";

const useStyles = makeStyles((theme) => ({
  surveyMain: {
    height: "57px",
    borderRadius: "4px 0px 0px 4px",
    padding: "2px",
    backgroundColor: "white",
    flex: "0 0 50px",
    maxWidth: "50px",
    lineHeight: "50px",
    position: "relative",
    zIndex: "1",
  },
  OtherMain: {
    height: "45px",
    marginBottom: "-50px",
    borderRadius: "4px 0px 0px 4px",
    padding: "2px",
    backgroundColor: "white",
  },
  icon: {
    width: "35px",
    height: "35px",
    display: "block",
    fontSize: "27px",
  },
  mainSelect: {
    marginLeft: "41px",
    marginTop: "5px",
    textAlign: "start",
  },
}));
const DropDownAutocomplete = ({
  theme_id,
  options,
  name,
  label,
  description,
  validateOptions,
  type,
  validate,
  validationCallback,
  value,
  handleChange,
  placeholder,
  themecolors,
  activestep,
}) => {
  const backgroundCol = themecolors.onHoverInColor;
  const [selectedVal, setSelectedVal] = useState(value);
  const [optionsArr, setOptionsArr] = useState(options);
  const [errors, setError] = useState({});
  const { [name]: error = "" } = errors;
  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };
    if (checkValidationRule(isVisible)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_CLICK" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const validateFields = (isVisible, e) => {
    const { required } = validateOptions;
    if (required) {
      let errorMsg;

      if (selectedVal === "") {
        errorMsg = `${name} is required`;
      } else {
        errorMsg = "";
      }
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }
    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
    setOptionsArr(options);
    setSelectedVal(value);
  });

  const dot = () => ({
    alignItems: "center",
    display: "flex",
    height: type === "survey" ? "45px" : "35px",
    ":before": {
      borderRadius: 10,
      content: '""',
      display: "block",
      marginRight: 8,
    },

    "input:focus": {
      borderStyle: "none",
    },
  });
  const classes = useStyles();
  const colourStyles = {
    control: (styles) => ({
      ...styles,
      borderLeft: "none",
      borderTopLeftRadius: "none",
      boxShadow: "none",
      textAligh: "right",
      border: `${themecolors.border}`,

      ":hover": {
        border: `${themecolors.onHoverBorder}`,
      },
    }),
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isDisabled
          ? null
          : isSelected
          ? backgroundCol
          : isFocused
          ? backgroundCol
          : null,
        color: isDisabled
          ? "white"
          : isSelected
          ? "white"
          : isFocused
          ? "white"
          : null,

        cursor: isDisabled ? "not-allowed" : "default",
        ":hover": { color: "white", backgroundColor: backgroundCol },
        ":focus": { borderLeftStyle: "none" },
        ":active": {
          ...styles[":active"],
        },
      };
    },
    input: (styles) => ({ ...styles, ...dot() }),
  };

  const handleChangeAuto = (event) => {
    setSelectedVal(event);
    handleChange(event);
    setError({});
  };

  // const [colHovor, SetolHovor] = useState(themecolors.textColor);

  const checkFocus = () => {
    // SetolHovor(backgroundCol);
  };

  const MenuClose = () => {
    // SetolHovor(themecolors.textColor);
  };

  return (
    <Grid
      onMouseOver={checkFocus}
      onMouseLeave={MenuClose}
      className={`select-box  ${!!error && "select-field--error"}`}
    >
      {!!label && theme_id !== 3 && (
        <FormLabel style={{ fontWeight: "500" }}>
          {label}&nbsp;
          {validateOptions.required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {!!description && <Tooltip title={description} />}
        </FormLabel>
      )}
      <div
        className="auto_select"
        style={{
          display: "flex",
          flexWrap: "wrap",
        }}
        id={`step_${activestep}_${name}_div`}
      >
        <div
          className={classes.mainSelect}
          style={{
            flex: "0 0 100%",
            marginLeft: "0px",
            marginTop: "0px",
            position: "relative",
          }}
          id={`step_${activestep}_${name}_divi`}
        >
          <Select
            id={`step_${activestep}_${name}`}
            options={optionsArr}
            styles={colourStyles}
            onChange={(event) => {
              handleChangeAuto(event);
            }}
            defaultValue={selectedVal || value}
            theme={(theme) => ({
              ...theme,

              colors: {
                ...theme.colors,
                primary25: "hotpink",
                primary: "#ff0000",
              },
            })}
            style={{ borderLeft: "none" }}
            onFocus={checkFocus}
            onMenuClose={MenuClose}
            placeholder={placeholder}
          />
        </div>
      </div>
      {!!error && theme_id !== 3 && <Error errorMessage={error} />}
    </Grid>
  );
};

export default DropDownAutocomplete;

DropDownAutocomplete.propTypes = {
  arrList: array.isRequired,
  label: string,
  handleChange: func.isRequired,
  value: object,
  placeholder: string,
  requireValidOption: bool,
};

DropDownAutocomplete.defaultProps = {
  arrList: [{ value: "No record Found" }],
  displayName: "value",
  value: {},
  placeholder: "",
  requireValidOption: true,
  theme_id: 0,
};
