import { createAction } from "redux-actions";

export const GET_SITE_DATA = "GET_SITE_DATA";
export const getSiteData = createAction(GET_SITE_DATA);

export const GET_SITE_DATA_REQUESTED = "GET_SITE_DATA_REQUESTED";
export const getSiteDataRequested = createAction(GET_SITE_DATA_REQUESTED);

export const GET_SITE_DATA_SUCCESS = "GET_SITE_DATA_SUCCESS";
export const getSiteDataSuccess = createAction(GET_SITE_DATA_SUCCESS);

export const GET_SITE_DATA_FAILURE = "GET_SITE_DATA_FAILURE";
export const getSiteDataFailure = createAction(GET_SITE_DATA_FAILURE);

export const LEAD_DATA = "LEAD_DATA";
export const leadData = createAction(LEAD_DATA);

export const LEAD_DATA_CALLBACK = "LEAD_DATA_CALLBACK";
export const leadDatACALLBACK = createAction(LEAD_DATA_CALLBACK);

export const LEAD_DATA_REQUESTED = "LEAD_DATA_REQUESTED";
export const leadDataRequested = createAction(LEAD_DATA_REQUESTED);

export const LEAD_DATA_SUCCESS = "LEAD_DATA_SUCCESS";
export const leadDataSuccess = createAction(LEAD_DATA_SUCCESS);

export const LEAD_DATA_FAILURE = "LEAD_DATA_FAILURE";
export const leadDataFailure = createAction(LEAD_DATA_FAILURE);

export const WEb_HOOK_LEAD_DATA = "WEb_HOOK_LEAD_DATA";
export const webHookLeadData = createAction(WEb_HOOK_LEAD_DATA);

export const  WEb_HOOK_LEAD_DATA_REQUESTED = "WEb_HOOK_LEAD_DATA_REQUESTED";
export const webHookleadDataRequested = createAction(WEb_HOOK_LEAD_DATA_REQUESTED);

export const WEb_HOOK_LEAD_DATA_SUCCESS = "WEb_HOOK_LEAD_DATA_SUCCESS";
export const webHookleadDataSuccess = createAction(WEb_HOOK_LEAD_DATA_SUCCESS);

export const WEb_HOOK_LEAD_DATA_FAILURE = "WEb_HOOK_LEAD_DATA_FAILURE";
export const webHookleadDataFailure = createAction(WEb_HOOK_LEAD_DATA_FAILURE);
