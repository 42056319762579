import React from "react";
import { string } from "prop-types";
import ErrorIcon from "../../assets/images/error.png";
import "./error.scss";

const Error = (props) => (
  <div className="error-container">
    <span className={`error-container__text ${props.type}`}>
      <img
        className="error-container__text_image"
        src={ErrorIcon}
        alt="error"
      />
      {props.errorMessage}

      {/* <h2><span></span>404Error Page not found!</h2> */}
    </span>
  </div>
);

Error.propTypes = { errorMessage: string.isRequired };

export default Error;
