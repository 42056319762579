import React from "react";
import { object } from "prop-types";
import Utils from "../../../utils";
import $ from "jquery";
import FooterDisclaimer from "../../shared/footer-disclaimer";


class FooterPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, hasError: false, errorInfo: null };
  }

  componentDidMount = () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  };

  handleClick = ({ page_url, is_external, target }) => {
    const { page } = this.props;
    if (page && page != undefined && (page === 7 || page === "7")) {
      window.location.href = `${page_url}`;
    } else {
      Utils.handleClickUrl(page_url, is_external, target);
    }
  };

  renderScript = (getSiteDataObject) => {
    const { isPageRefreshed } = this.props;

    if (isPageRefreshed) {
      $("head").append(
        getSiteDataObject &&
          getSiteDataObject.fb_script &&
          `${getSiteDataObject.fb_script}`
      );
      $("body").append(
        getSiteDataObject &&
          getSiteDataObject.google_script &&
          `${getSiteDataObject.google_script}`
      );
    }
  };

  render() {
    const { siteData } = this.props;
    const privacy_policy = Utils.getPageContent(siteData, 4);
    const terms_conditions = Utils.getPageContent(siteData, 5);
    return (
      <div className="section-footer">
        {this.renderScript(siteData)}
        <footer className="shopaFooter" 
        dangerouslySetInnerHTML={{
        __html: siteData.footer_content,
      }}
      >
      
    </footer>
      </div>
    );
  }
}

FooterPage.propTypes = {
  siteData: object,
};

export default FooterPage;
