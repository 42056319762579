import axios from "axios";
import queryString from "query-string";
import Survey from "../components/survey-page";
import LandingPage from "../components/landing";
import AboutUs from "../components/about-us";
import ContactUs from "../components/contact-us";
import PrivacyPolicy from "../components/privacy-policy";
import TermaCondition from "../components/terms-condition";
import ThankyouPage from "../components/thankyou";
import DoNotQualifyPage from "../components/do-not-qualify";
import BlogPage from "../components/blog";
import ErrorPage from "../components/page-not-found";
import Utils from "../utils/index";
import PageNotFound from "../components/page-not-found";
import AdvertiseWithUs from "../components/advertise-with-us/index";

import PagesComponent from "../components/pages";
import Blog2 from "../components/blog-2/blog-2";
const dynamicRoute = [
  {
    component: LandingPage,
    exact: true,
    path: "/",
    title: "Home Page",
  },
  {
    component: DoNotQualifyPage,
    exact: true,
    path: "/do-not-qualify/",
    title: "do-not-qualify",
  },
  {
    component: ThankyouPage,
    exact: true,
    path: "/thank-you/",
    title: "Thankyou Page",
  },
  {
    component: AdvertiseWithUs,
    exact: true,
    path: "/advertise-with-us",
    title: "Advertise With Us"
  },
  {
    component: ["www.au2.quickandeasyhearing.com", "au2.quickandeasyhearing.com"].includes(Utils.getHost().websiteUrl) ? Blog2 : PageNotFound,
    exact: true,
    path: "/bloghearingsolutions",
    title: "Blog | Australian Hearing Ads"
  }

];

const getUrls = async () => {
  const host = Utils.getHost();
  // if (["www.mylifeinsurancecompare.co.nz", "mylifeinsurancecompare.co.nz"].includes(host.websiteUrl)) {
  //   window.location.replace("https://www.2023.mylifeinsurancecompare.co.nz?d="+host.websiteUrl);
  // }
  // const domain = queryString.parse(window.location.search)?.d;
  // if (domain) {
  //   sessionStorage.setItem("domain", domain);
  // }

  const hostUrl = `${host.apiUrl}getpageurl/list/${host.websiteUrl}`;
  const headers = {
    Authorization: "c2xkZ21sa21nbG1s",
  };

  const res = await axios.get(`${hostUrl}`, { headers });
  const siteData = res?.data?.response?.content_manage_sites;
  const pages = res?.data?.response?.pages;
  siteData.push({
    "page_id": 9,
    "is_external": "false",
    "page_url": "/advertise-with-us",
    "target": "false"
  });

  siteData.map((item) => {
    if (
      (!item.is_external || item.is_external === "false") &&
      item.page_id !== 1
    ) {
      dynamicRoute.push({
        pageId: item.page_id,
        component:
          item.page_id === 2
            ? AboutUs
            : item.page_id === 3
              ? ContactUs
              : item.page_id === 4
                ? PrivacyPolicy
                : item.page_id === 5
                  ? TermaCondition
                  : item.page_id === 6
                    ? Survey
                    : item.page_id === 7
                      ? ThankyouPage
                      : item.page_id === 8
                        ? BlogPage
                        : PageNotFound,
        exact: true,
        path: item.page_url || "/page_not_found",
        title: "",
      });
    }
  });

  pages.map((page) => dynamicRoute.push({
    pageId: page.page_id,
    component: PagesComponent,
    exact: true,
    path: page.url,
    title: "",
  }))

  dynamicRoute.push({
    component: ErrorPage,
    path: "*",
    title: "Page Not Found",
  });
  return dynamicRoute;
};

export { getUrls };
