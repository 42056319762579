import React from "react";
import { object } from "prop-types";
import Utils from "../../../../utils/index";

const SectionFourthPage = (props) => {
  const { siteData, mainData } = props;

  const handleClick = ({ page_url, is_external, target }) => {
    Utils.handleClickUrl(page_url, is_external, target);
  };
  const survey_page = Utils.getPageContent(mainData, 6);

  return (
    <div className="section-fourth-main">
      {mainData &&
      mainData.primary_color !== "fourth-theme" &&
      mainData.primary_color !== "fifth-theme" ? (
        <section className="sectionone first bg-light">
          <div className="container">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="heading-section">
                <h2>{siteData.section_fourth_header}</h2>
                <p>{siteData.section_fourth_paragraph}</p>
              </div>
              <div className="sectionContentfourth" style={{ display: "none" }}>
                <div className="col-sm-5 order-sm-2">
                  <div className="descrioption-news">
                    <p
                      id="left-side-description"
                      dangerouslySetInnerHTML={{
                        __html: siteData.section_fourth_paragraph_left,
                      }}
                    ></p>
                  </div>
                </div>
                <div className="col-sm-5 order-sm-1 offset-sm-1">
                  <div className="descrioption-news">
                    <p
                      id="right-side-description"
                      dangerouslySetInnerHTML={{
                        __html: siteData.section_fourth_paragraph_right,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
              {/* Accordion */}
              <div className="col-sm-12">
                {siteData.page_sub_title === "show" ? (
                  <div className="tabs">
                    <span
                      className="tab"
                      dangerouslySetInnerHTML={{
                        __html: siteData.section_heading,
                      }}
                    ></span>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className="col-sm-12 heading-section">
                <span
                  className="homebtn section-4"
                  onClick={() => handleClick(survey_page)}
                  style={{ cursor: "pointer" }}
                >
                  {siteData.heading}
                </span>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="sectionone first bg-light hideFaq">
          <div className="container">
            <div className="row" style={{ justifyContent: "center" }}>
              <div className="heading-section">
                {siteData.page_sub_title === "show" && (
                  <h2>{siteData.section_fourth_header}</h2>
                )}
              </div>
              <div className="sectionContentfourth" style={{ display: "none" }}>
                <div className="col-sm-5 order-sm-2">
                  <div className="descrioption-news">
                    <p
                      id="left-side-description"
                      dangerouslySetInnerHTML={{
                        __html: siteData.section_fourth_paragraph_left,
                      }}
                    ></p>
                  </div>
                </div>
                <div className="col-sm-5 order-sm-1 offset-sm-1">
                  <div className="descrioption-news">
                    <p
                      id="right-side-description"
                      dangerouslySetInnerHTML={{
                        __html: siteData.section_fourth_paragraph_right,
                      }}
                    ></p>
                  </div>
                </div>
              </div>
              {/* Accordion */}
              <div className="col-sm-12">
                {siteData.page_sub_title === "show" ? (
                  <div className="tabs">
                    <span
                      className="tab"
                      dangerouslySetInnerHTML={{
                        __html: siteData.section_heading,
                      }}
                    ></span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="bottomStip">
            <div className="container">
              <div className="row">
                <div className="col-md-8 offset-md-2 heading-section">
                  <span
                    className="tab"
                    dangerouslySetInnerHTML={{
                      __html: siteData.section_fourth_paragraph,
                    }}
                  ></span>
                  <span
                    className="homebtn section-4"
                    onClick={() => handleClick(survey_page)}
                    style={{ cursor: "pointer" }}
                  >
                    {siteData.heading}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

SectionFourthPage.propTypes = {
  siteData: object,
};

export default SectionFourthPage;
