const CONSTANTS = {
  MOBILE_NUMBER:
    /^\({0,1}((0|\+61)(2|4|3|7|8)){0,1}\){0,1}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{2}(\ |-){0,1}[0-9]{1}(\ |-){0,1}[0-9]{3}$/, ///^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[789]\d{9}$/,
  MOBILE_NUMBER_GENERIC: /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
  GST_NUMBER:
    /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/,
  ALPHA_NUMERIC_REGEX: /^([a-zA-Z0-9]|[ ])+$/,
  SPL_CHAR_REGEX: /^[a-zA-Z0-9 ,.\-/"\\()<>!]*$/,
  ALPHA_REGEX: /^([A-Za-z]|[ ])+$/,
  EMAIL_REGEX: /^[\w-]+(?:\.[\w-]+)*@(?:[\w-]+\.)+[a-zA-Z]{2,7}$/, // /^[a-zA-Z0-9._-]+@[a-zA-Z0-9]+\.+[a-zA-Z]+$/,
  // Minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character:
  PASSWORD_REGEX:
    /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
  advertelyApiKey:
    "b4d663da84ea4ec7b4b6e7a0bc422e36ed5f9aaf42714f4c9920f3a72ddce7e4",
  advertelyBaseUrl: "https://sonvik.com/api/",
  ENGLAND_MOBILE_NUMBER: /^(?:0|\+44)7\d{9}$/,
  UAE_MOBILE_NUMBER: /^(?:\+971|00971|971|0)?(?:50|55|56|52|58)\d{7}$/
};
// const FONT = "cursive";

export default CONSTANTS;
