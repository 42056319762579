import React from "react";
import { object } from "prop-types";
import Emoji3 from "../../../../assets/images/emoji3.svg";

const SectionFourthPage = (props) => {
  const { siteData } = props;
  const scrollToTop=()=> {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <div className="beta-fourthSection">
      <div className="sectionDivider">
        <div className="divider__left"></div>
        <div className="divider__right"></div>
      </div>
      <section className="beta-fourthContainer">
        <div className="container">
          <div className="row" style={{ justifyContent: "center" }}>
            <div className="beta-fourthHeader">
              <img src={Emoji3} alt="" />
              <h2>{siteData.section_fourth_header}</h2>
              <p>{siteData.section_fourth_paragraph}</p>
            </div>
            <div className="sectionContentfourth" style={{ display: "none" }}>
              <div className="col-sm-5 order-sm-2">
                <div className="descrioption-news">
                  <p
                    id="left-side-description"
                    dangerouslySetInnerHTML={{
                      __html: siteData.section_fourth_paragraph_left,
                    }}
                  ></p>
                </div>
              </div>
              <div className="col-sm-5 order-sm-1 offset-sm-1">
                <div className="descrioption-news">
                  <p
                    id="right-side-description"
                    dangerouslySetInnerHTML={{
                      __html: siteData.section_fourth_paragraph_right,
                    }}
                  ></p>
                </div>
              </div>
            </div>
            {/* Accordion */}
            <div className="col-sm-12">
              {siteData.page_sub_title === "show" ? (
                <div className="tabs">
                  <span
                    className="tab"
                    dangerouslySetInnerHTML={{
                      __html: siteData.section_heading,
                    }}
                  ></span>
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-sm-12 btnCheck">
              <button
                className="homebtn section-4"
                onClick={() => scrollToTop()}
                style={{ cursor: "pointer" }}
              >
                {siteData.heading}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

SectionFourthPage.propTypes = {
  siteData: object,
};

export default SectionFourthPage;
