import React from "react";
import PropTypes from "prop-types";
//import { withStyles, makeStyles } from "@material-ui/core/styles";
//import { Button as UIButton } from "@material-ui/core";
import { string, func, bool } from "prop-types";
//mport CircularProgress from "@material-ui/core/CircularProgress";
import $ from "jquery";
/**
 * Button with a loading indicator
 * @param props
 * disabled: bool - Indicating the element is disabled,
 * onClick: func - callback function invokes on clicking on element,
 * type: string - Button Type (i.e. submit or button),
 * appearance: object - Object of type button_appearances containing multiple versions of the button component,
 * text: string - The text to show in the button
 * loading: bool - Shows a circular progress indicating that a network request is being made
 * @returns {*}
 * @constructor
 */

// const getStyledRoot = (item) => {
//   const appearance = {};
//   delete Object.assign(appearance, item, { "&:hover": item[":hover"] })[
//     ":hover"
//   ];
//   return appearance;
// };

// const ButtonCustom = withStyles((theme) => ({
//   root: {
//     boxShadow: "none",
//     textTransform: "none",
//     borderRadius: "2px",
//     margin: theme.spacing(0.5),
//     "&:hover": {
//       boxShadow: "none",
//       textTransform: "none",
//       textTransform: "uppercase",
//     },
//     textTransform: "uppercase",
//   },
// }))(UIButton);

// const useStyles = makeStyles({
//   styledRoot: (props) => getStyledRoot(props.appearance),
//   progress: { color: (props) => props.appearance.color },
// });

export default function Button(props) {
  //const { styledRoot, progress } = useStyles(props);
  const { text, type, disabled, clickHandler, loading } = props;
  // $("*").css("font-family", localStorage.getItem("fontFamily"));
  return (
    // <ButtonCustom
    //   variant="contained"
    //   className={`form_button ${styledRoot}`}
    //   type="button"
    //   disabled={disabled !== "" && disabled}
    //   onClick={clickHandler}
    // >
    //   {!loading ? text : <CircularProgress size={24} className={progress} />}
    // </ButtonCustom>
    <input
      type="button"
      className={`form_button`}
      onClick={clickHandler}
      value={text}
    />
  );
}

export const button_appearances = {
  primary_large: {
    height: "40px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#ffffff",
    backgroundColor: "#007C9D",
    ":hover": {
      backgroundColor: "#005C71",
      color: "#ffffff",
      border: "none",
    },
  },
  primary: {
    height: "40px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#ffffff",
    backgroundColor: "#010569",
    ":hover": {
      backgroundColor: "#030D40",
      color: "#ffffff",
      border: "none",
    },
  },
  primarydemo: {
    color: "#13ac8f",
    border: "2px solid #13ac8f",
    background: "transparent",
    float: "left",
    height: "55px",
    marginTop: "40px",
    marginLeft: "0px",
    marginRight: "0px",
    width: "100%",
    ":hover": {
      background: "#13ac8f",
      color: "#fff",
    },
  },
  default: {
    height: "40px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#6a7070",
    border: "solid 1px #6a7070",
    backgroundColor: "#ffffff",
    ":hover": {
      backgroundColor: "#ffffff",
      color: "#000",
      border: "solid 1px #000",
    },
  },
  danger: {
    height: "40px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#ffffff",
    backgroundColor: "#AE0C16",
    ":hover": {
      backgroundColor: "#B01212",
      color: "#ffffff",
      border: "none",
    },
  },
  info: {
    height: "40px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#ffffff",
    backgroundColor: "#3383ee",
    ":hover": {
      backgroundColor: "#1192F6",
      color: "#ffffff",
      border: "none",
    },
  },
  success: {
    height: "40px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#ffffff",
    backgroundColor: "#0D7F60",
    ":hover": {
      backgroundColor: "#0E8C60",
      color: "#ffffff",
      border: "none",
    },
  },
  warning: {
    height: "40px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#ffffff",
    backgroundColor: "#EFA804",
    ":hover": {
      backgroundColor: "#FBAA00",
      color: "#ffffff",
      border: "none",
    },
  },

  primary_medium: {
    height: "36px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#ffffff",
    backgroundColor: "#007C9D",
    ":hover": {
      backgroundColor: "#005C71",
      color: "#ffffff",
      border: "none",
    },
  },
  primary_small: {
    height: "32px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1,
    color: "#ffffff",
    backgroundColor: "#007C9D",
    ":hover": {
      backgroundColor: "#005C71",
      color: "#ffffff",
      border: "none",
    },
  },
  attention_large: {
    height: "40px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#000",
    backgroundColor: "#ffb81c",
    ":hover": {
      backgroundColor: "#FF9600",
      color: "#000",
      border: "none",
    },
  },
  attention_medium: {
    height: "36px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#000",
    backgroundColor: "#ffb81c",
    ":hover": {
      backgroundColor: "#FF9600",
      color: "#000",
      border: "none",
    },
  },
  attention_small: {
    height: "32px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1,
    color: "#000",
    backgroundColor: "#ffb81c",
    ":hover": {
      backgroundColor: "#FF9600",
      color: "#000",
      border: "none",
    },
  },
  default_large: {
    height: "40px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#6a7070",
    border: "solid 1px #6a7070",
    backgroundColor: "#ffffff",
    ":hover": {
      backgroundColor: "#ffffff",
      color: "#000",
      border: "solid 1px #000",
    },
  },
  default_medium: {
    height: "36px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#6a7070",
    border: "solid 1px #6a7070",
    backgroundColor: "#ffffff",
    ":hover": {
      backgroundColor: "#ffffff",
      color: "#000",
      border: "solid 1px #000",
    },
  },
  default_small: {
    height: "32px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1,
    color: "#6a7070",
    border: "solid 1px #6a7070",
    backgroundColor: "#ffffff",
    ":hover": {
      backgroundColor: "#ffffff",
      color: "#000",
      border: "solid 1px #000",
    },
  },
  disabled_large: {
    height: "40px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#6a7070",
    backgroundColor: "#e9e9e9",
    ":hover": {
      backgroundColor: "#e9e9e9",
      color: "#6a7070",
      border: "none",
    },
  },
  disabled_medium: {
    height: "36px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#6a7070",
    backgroundColor: "#e9e9e9",
    ":hover": {
      backgroundColor: "#e9e9e9",
      color: "#6a7070",
      border: "none",
    },
  },
  disabled_small: {
    height: "32px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1,
    color: "#6a7070",
    backgroundColor: "#e9e9e9",
    ":hover": {
      backgroundColor: "#e9e9e9",
      color: "#6a7070",
      border: "none",
    },
  },
  default_gradient_small: {
    background: "linear-gradient(to right, #03A9F4, #00BCD4)",
    height: "24px",
    fontSize: 12,
    lineHeight: 1,
    color: "#fff",
  },
  default_gradient_medium: {
    background: "linear-gradient(to right, #03A9F4, #00BCD4)",

    color: "#fff !important",
    // padding: "7px 10px",
    transition: "opacity 0.3s ease",
    display: "inline-block",
    fontWeight: 400,
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    padding: ".375rem .75rem",
    fontSize: "1rem",
    lineHeight: 1.5,
    borderRadius: ".25rem",
  },
  default_gradient_large: {
    background: "linear-gradient(to right, #03A9F4, #00BCD4)",

    color: "#fff !important",
    // padding: "7px 10px",
    transition: "opacity 0.3s ease",
    display: "inline-block",
    fontWeight: 400,
    textAlign: "center",
    verticalAlign: "middle",
    cursor: "pointer",
    backgroundColor: "transparent",
    border: "1px solid transparent",
    padding: ".375rem .75rem",
    fontSize: "1rem",
    lineHeight: 1.5,
    borderRadius: ".25rem",
  },
  info_fifth: {
    height: "50px",
    width: "100%",
    fontSize: 16,
    lineHeight: 1.5,
    color: "#ffffff",
    backgroundColor: "#3383ee",
    border: "none",
    borderRadius: "none",
    ":hover": {
      backgroundColor: "#1192F6",
      color: "#ffffff",
      border: "none",
    },
  },
};

Button.propTypes = {
  disabled: bool,
  clickHandler: func,
  text: string.isRequired,
  type: string,
  loading: bool,
  appearance: PropTypes.oneOf(Object.values(button_appearances)),
};

Button.defaultProps = {
  disabled: false,
  loading: false,
  type: "submit",
  appearance: button_appearances.default_medium,
};
