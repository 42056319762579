import React from "react";
import Header from "./header";
import Footer from "./footer";
import { string, object } from "prop-types";
import "./beta-layout.scss";

const LayoutFirst = (props) => {
  const { children, pageType, siteData, page, isPageRefreshed } = props;

  if (siteData.status === false || siteData.status === "false") {
    window.location.href = "/page-not-found2";
  }

  return (
    <div
      className={`beta_layout-theme beta_layout-${
        siteData.primary_color &&
        siteData.primary_color !== undefined &&
        siteData.primary_color !== "" &&
        siteData.primary_color !== null
          ? siteData.primary_color
          : "default-theme"
      }`}
    >
      <Header siteData={siteData} pageType={pageType} page={page} />
      <main role="main">{children}</main>
      <Footer
        siteData={siteData}
        pageType={pageType}
        page={page}
        isPageRefreshed={isPageRefreshed}
      />
    </div>
  );
};

export default LayoutFirst;

LayoutFirst.propTypes = {
  pageType: string,
  siteData: object,
};

LayoutFirst.defaultProps = {
  pageType: "home",
  page: 1,
  isPageRefreshed: false,
};
