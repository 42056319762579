import { createAction } from "redux-actions";

export const POST_ADVERTISE_WITH_US = "POST_ADVERTISE_WITH_US";
export const postAdvertiseWithUs = createAction(POST_ADVERTISE_WITH_US);

export const POST_ADVERTISE_WITH_US_REQUESTED = "POST_ADVERTISE_WITH_US_REQUESTED";
export const postAdvertiseWithUsRequested = createAction(POST_ADVERTISE_WITH_US_REQUESTED);

export const POST_ADVERTISE_WITH_US_SUCCESS = "POST_ADVERTISE_WITH_US_SUCCESS";
export const postAdvertiseWithUsSuccess = createAction(POST_ADVERTISE_WITH_US_SUCCESS);

export const POST_ADVERTISE_WITH_US_FAILED = "POST_ADVERTISE_WITH_US_FAILED";
export const postAdvertiseWithUsFailed = createAction(POST_ADVERTISE_WITH_US_FAILED);
