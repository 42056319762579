import { call, put, takeLatest } from "redux-saga/effects";
import { toast } from "react-toastify";

import {
    POST_ADVERTISE_WITH_US,
    postAdvertiseWithUsRequested,
    postAdvertiseWithUsSuccess,
    postAdvertiseWithUsFailed,
} from "../actions/advertise-with-us-actions";
import Utils from "../utils";

const {
    httpHelper: { getRequest, postRequest },
} = new Utils().getAll();

const host = Utils.getHost();

export function* postAdvertiseWithUsData({ payload }) {
    const payloadData = {
        data: payload,
        headers: {
            Authorization: "c2xkZ21sa21nbG1s",
        },
        url: `${host.apiUrl}advertise-with-us`,
    };

    yield put(postAdvertiseWithUsRequested());

    try {
        const { data, error } = yield call(postRequest, payloadData);
        if (data && !error) {
            yield put(postAdvertiseWithUsSuccess(data.response));
            toast.success(
                "Your request has been submitted. We'll connect with you ASAP.",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
        } else {
            yield put(postAdvertiseWithUsFailed(error));
            toast.error(
                "Something went wrong, please try after sometime.",
                {
                    position: toast.POSITION.BOTTOM_RIGHT,
                }
            );
        }
    } catch (error) {
        yield put(postAdvertiseWithUsFailed(error));
        toast.error(
            "Something went wrong, please try after sometime.",
            {
                position: toast.POSITION.BOTTOM_RIGHT,
            }
        );

    }
}

function* AdvertiseWithUsSaga() {
    yield takeLatest(POST_ADVERTISE_WITH_US, postAdvertiseWithUsData);
}
export default AdvertiseWithUsSaga;
