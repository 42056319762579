import React from "react";
import { Helmet } from "react-helmet";
import { makeStyles } from "@material-ui/core/styles";
import Utils from "../../../utils";

const useStyles = makeStyles(() => ({
  achorHvr: {
    transition: "ease 0.7s",

    color: "#fff",
    "&:hover": { backgroundColor: "#0766cc !important" },
  },
  accordianMain: {
    marginTop: "40px",
    width: "90%",
  },
}));

const handleClick = ({ page_url, is_external, target }) => {
  Utils.handleClickUrl(page_url, is_external, target);
};

const LayoutBody = (props) => {
  const classses = useStyles();
  const { siteData } = props;

  let siteDataValue = Utils.getPageContent(siteData, 1);

  if (siteDataValue.length === 0) {
    window.location.href = "/page-not-found3";
  }
  const survey_page = Utils.getPageContent(siteData, 6);

  return (
    <>
      <Helmet>
        <title>{siteDataValue.meta_title}</title>
        <meta name="description" content={siteDataValue.meta_description} />
        <link rel="icon" href={siteData.favicon} sizes="16x16" />
      </Helmet>
      <section className="brands-section">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <h2 className="text-center">
                {siteDataValue.section_first_paragraph}
              </h2>
              <div className="row">
                <div
                  className="col-sm-6"
                  onClick={() => handleClick(survey_page)}
                  style={{ cursor: "pointer" }}
                >
                  <img
                    src={siteDataValue.section_third_img}
                    className="img-responsive"
                    alt={siteDataValue.sti_alt_text}
                  />
                </div>
                <div className="col-sm-6">
                  <div
                    className="sideContent"
                    dangerouslySetInnerHTML={{
                      __html: siteDataValue.section_fourth_paragraph,
                    }}
                  ></div>
                </div>
              </div>
              <div className="brands-images">
                <div className="featureeText">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: siteDataValue.section_fourth_paragraph_left,
                    }}
                  ></span>
                </div>
                <div className="seperation-new">
                  <div className="row">
                    <div className="col-sm-6">
                      <div
                        className="textContent"
                        onClick={() => handleClick(survey_page)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={siteDataValue.list_first_img}
                          className="img-responsive"
                          alt={siteDataValue.lfi_alt_text}
                        />
                        <p
                          className="paragraph"
                          dangerouslySetInnerHTML={{
                            __html: siteDataValue.section_third_paragraph,
                          }}
                        ></p>
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div
                        className="textContent"
                        onClick={() => handleClick(survey_page)}
                        style={{ cursor: "pointer" }}
                      >
                        <img
                          src={siteDataValue.list_second_img}
                          className="img-responsive"
                          alt={siteDataValue.lsi_alt_text}
                        />

                        <p
                          className="paragraph"
                          dangerouslySetInnerHTML={{
                            __html: siteDataValue.list_third_paragraph,
                          }}
                        ></p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="paragraph-alignment-new">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="getlink">
                        <span
                          onClick={() => handleClick(survey_page)}
                          style={{ cursor: "pointer" }}
                          className={classses.achorHvr}
                        >
                          {siteDataValue.heading}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Accordion section */}
      <div className={`container ${classses.accordianMain}`}>
        <div className="row">
          <div className="col">
            {/* <h2 className="text-center">Accordian</h2> */}
            <div className="tabs">
              {siteDataValue.page_sub_title === "show" ? (
                <div className="tabs">
                  <span
                    className="tab"
                    dangerouslySetInnerHTML={{
                      __html: siteDataValue.section_heading,
                    }}
                  ></span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>

      <section className="quickeasy">
        <div className="container">
          <div className="row">
            <div
              className="col-sm-8 offset-sm-2 text-center testimonialsArea"
              dangerouslySetInnerHTML={{
                __html: siteDataValue.section_second_paragraph,
              }}
            ></div>
          </div>
        </div>
      </section>
      <section className="content-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <p
                className="testimonialsList"
                dangerouslySetInnerHTML={{
                  __html: siteDataValue.list_fourth_paragraph,
                }}
              ></p>
            </div>
            <div className="col-sm-6">
              <p
                className="testimonialsList"
                dangerouslySetInnerHTML={{
                  __html: siteDataValue.section_content,
                }}
              ></p>
            </div>
          </div>
          <div className="row">
            {siteDataValue.col_one === "show" ? (
              siteDataValue.col_two
            ) : (
              <div></div>
            )}
          </div>
        </div>
      </section>
    </>
  );
};

export default LayoutBody;
