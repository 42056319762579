import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { object, string, func } from "prop-types";
import "./input-field.scss";
import Error from "../../components/error";
import fieldValidation from "../../utils/validate";
import { checkValidationRule } from "../../utils/validate";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";
import { makeStyles } from "@material-ui/core/styles";

const TextAreaField = ({
  name,
  fieldAttributes,
  classname,
  label,
  validate,
  validateExecutive,
  placeHolder,
  validationRule,
  validateOptions,
  validationCallback,
  description,
  type,
  theme_id,
  theme,
  iconName,
  themecolors,
  activestep
}) => {
  const [errors, setError] = useState({});
  const { value } = fieldAttributes;
  const { rows } = fieldAttributes;
  const col = themecolors.onHoverInColor;

  const { [name]: error = "" } = errors;
  const useStyles = makeStyles((theme) => ({
    formControl: {},
    root: {
      display: "flex",
      flexDirection: "row",
    },
    formLabelroot: {
      display: "flex",
      paddingLeft: "0px !important",
      alignItems: "center",
      color: "#000 !important",
      fontSize: "14px",
      fontWeight: "bold",
    },
    formLabelrootInline: {
      paddingLeft: "0px !important",
      color: "#585555 !important",
      fontSize: "30px",
      padding: " 10px",
      display: "flex",
      fontWeight: "500",
    },
    divMain: {
      position: "relative",
      padding: "0",
      margin: "0",
      display: "inline-block",
      width: "100%",
    },
    iconMain: {
      position: "absolute",
      top: "10px",
      left: "12px",
      width: "35px",
      height: "35px",
      fontSize: "27px",
    },
    textAreaMain: {
      color: "#7a776d",
      //height: "50px",
      margin: "0",
      paddingLeft: "60px !important",
    },
    themeLayout: {
      "&:hover": {
        border: `2px solid ${themecolors.onHoverInColor} !important`,
      },
      border: "2px solid #c5c5c5 !important",
      borderRadius: "5px !important",
    },
  }));
  const classes = useStyles({ theme, themecolors });

  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };

    if (checkValidationRule(isVisible, validationRule)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_TYPE" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const validateFields = (isVisible, e) => {
    const { required } = validateOptions;

    if (required) {
      const errorMsg = fieldValidation.required(value, label);

      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }
    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    } else if (validateExecutive) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
  });

  const [colHovor, SetolHovor] = useState("#c5c5c5");
  const changeColorHoverIn = () => {
    SetolHovor(col);
  };
  const changeColorHoverOut = () => {
    SetolHovor("#c5c5c5");
  };
  return (
    <Grid
      style={{ paddingTop: "10px" }}
      className={`textarea-box ${!!error && "textarea-box"}`}
    >
      {!!label && theme_id !== 3 && (
        <FormLabel
          className={
            type === "survey"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
          // style={{ display: theme_id === "5" ? "none" : "block" }}
        >
          <div
            // style={{
            //   color: theme === "ninth-theme" && theme_id !== "5" ? "white" : "",
            // }}
            className={
              theme === "ninth-theme" && theme_id !== "5" ? "labelColor" : ""
            }
          >
            {label}&nbsp;
          </div>
          {validateOptions.required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {!!description && <Tooltip title={description} />}
        </FormLabel>
      )}
      <div
        onMouseOver={changeColorHoverIn}
        onMouseOut={changeColorHoverOut}
        onFocus={changeColorHoverIn}
        onBlur={changeColorHoverOut}
        className={classes.divMain}
      >
        <i
          className={`demo-icon ${iconName} ${classes.iconMain}`}
          style={{
            color: `${
              type === "survey" || theme === "eighth-theme"
                ? colHovor
                : "#7c7d7d"
            }`,
          }}
        ></i>
        <textarea
          autoComplete="off"
          id={`step_${activestep}_${name}`}
          // id="w3mission "
          rows={rows}
          className={`${classes.textAreaMain} textarea-box__field ${
            type === "survey" && "textarea-box__field--survey"
          } ${classname} ${
            !!error && type !== "survey" && "textarea-box__field--error"
          } ${classes.themeLayout}`}
          onBlur={(e) => validateFields("VALIDATE_ON_BLUR", e)}
          onKeyUp={(e) => validateFields("VALIDATE_ON_TYPE", e)}
          {...fieldAttributes}
          placeholder={placeHolder}
        />
      </div>

      {!!error && theme_id !== 3 && <Error errorMessage={error} />}
    </Grid>
  );
};

TextAreaField.propTypes = {
  fieldAttributes: object,
  classname: string,
  label: string.isRequired,
  name: string.isRequired,
  validationCallback: func.isRequired,
};

TextAreaField.defaultProps = {
  fieldAttributes: {},
  classname: "",
  validateOptions: {
    check: false,
  },
  theme_id: 0,
};

export default TextAreaField;
