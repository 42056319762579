import React from "react";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/acquerly-icons.css";
import LayoutFirstDemo from "../layout-first-demo";
import BetaLayout from "../beta-layout";
import { getSiteData } from "../../actions/manage-site-action-type";
import BodyFirstDemo from "../layout-first-demo/layout-body";
import BodyFourth from "../layout-fourth/layout-body";
import BetaLayoutBody from "../beta-layout/layout-body";
import { connect } from "react-redux";
import LayoutFourth from "../layout-fourth";
import CircularProgress from "@material-ui/core/CircularProgress";
import { Helmet } from "react-helmet";
import Utils from "../../utils";
import LayoutSixth from "../layout-sixth";
import LayoutSeventh from "../layout-seventh";
import LayoutBodySixth from "../layout-sixth/layout-body";
import LayoutBodySeventh from "../layout-seventh/layout-body";
import LayoutSecond from "../layout-shopadocket";
import LayoutBodySecond from "../layout-shopadocket/layout-body";
import LoaderComponent from "../common/loader/loader";

class LandingPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      isPageRefreshed: false,
    };
  }

  componentDidMount() {
    const { getSiteData, getSiteDataObject } = this.props;
    if (getSiteDataObject.length === 0) {
      this.setState({ isPageRefreshed: true });
      getSiteData(this.state.page);
    } else {
      Utils.renderFont(getSiteDataObject);
    }
    setTimeout(() => {
      if (
        window.location.protocol == "http:" &&
        window.location.host !== "localhost:3000"
      ) {
        console.log("host protocol", window.location);
        window.location.href = window.location.href.replace("http", "https");
      }
    }, 1000);
  }

  renderHelmet = (getSiteDataObject) => {
    return (
      <Helmet>
        <link rel="icon" href={getSiteDataObject.favicon} sizes="16x16" />
      </Helmet>
    );
  };

  render() {
    const { isLoading, getSiteDataObject } = this.props;
    const { isPageRefreshed, page } = this.state;

    if (getSiteDataObject && Object.keys(getSiteDataObject).length > 0) {
      const redirectToBlog = Utils.redirectToBlogOnPageLoad(getSiteDataObject);
      redirectToBlog && Utils.historyPush(redirectToBlog);

      const redirectToSurvey =
        Utils.redirectToSurveyOnPageLoad(getSiteDataObject);
      redirectToSurvey && Utils.historyPush(redirectToSurvey);
    }

    return (
      <>
        {getSiteDataObject &&
        getSiteDataObject.content_manage_sites &&
        getSiteDataObject.content_manage_sites.length > 0 ? (
          getSiteDataObject.theme === "1" ? (
            <>
              {this.renderHelmet(getSiteDataObject)}
              <LayoutFirstDemo
                siteData={getSiteDataObject}
                pageType={"home"}
                page={page}
                isPageRefreshed={isPageRefreshed}
              >
                <BodyFirstDemo siteData={getSiteDataObject} />
              </LayoutFirstDemo>
            </>
          ) : getSiteDataObject.theme === "4" ? (
            <>
              {this.renderHelmet(getSiteDataObject)}
              <LayoutFourth
                siteData={getSiteDataObject}
                pageType={"home"}
                isPageRefreshed={isPageRefreshed}
                page={page}
              >
                <BodyFourth siteData={getSiteDataObject} />
              </LayoutFourth>
            </>
          ) : getSiteDataObject.theme === "5" ? (
            <>
              {this.renderHelmet(getSiteDataObject)}
              <LayoutSixth
                siteData={getSiteDataObject}
                pageType={"home"}
                page={page}
                isPageRefreshed={isPageRefreshed}
                isLoading={isLoading}
              >
                <LayoutBodySixth siteData={getSiteDataObject} />
              </LayoutSixth>
            </>
          ) : getSiteDataObject.theme === "7" ? (
            <>
              {this.renderHelmet(getSiteDataObject)}
              <LayoutSeventh
                siteData={getSiteDataObject}
                pageType={"home"}
                page={page}
                isPageRefreshed={isPageRefreshed}
                isLoading={isLoading}
              >
                <LayoutBodySeventh siteData={getSiteDataObject} />
              </LayoutSeventh>
            </>
          ) : getSiteDataObject.theme === "8" ? (
            <>
              {this.renderHelmet(getSiteDataObject)}
              <BetaLayout
                siteData={getSiteDataObject}
                pageType={"home"}
                page={page}
                isPageRefreshed={isPageRefreshed}
              >
                <BetaLayoutBody siteData={getSiteDataObject} />
              </BetaLayout>
            </>
          ) : getSiteDataObject.theme === "2" ? (
            <>
              {this.renderHelmet(getSiteDataObject)}
              <LayoutSecond
                siteData={getSiteDataObject}
                pageType={"home"}
                page={page}
                isPageRefreshed={isPageRefreshed}
              >
                <LayoutBodySecond siteData={getSiteDataObject} />
              </LayoutSecond>
            </>
          ) : (
            <LoaderComponent />

          )
        ) : isLoading ? (
          <LoaderComponent />
        ) : (
          <></>
        )}
      </>
    );
  }
}

const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({ isLoading, getSiteDataObject, getSiteStatus, getSiteError });

export default connect(mapStateToProps, { getSiteData })(LandingPage);
