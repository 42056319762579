import React, { Fragment, useEffect, useState } from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { string, number, oneOfType, func, bool } from "prop-types";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Error from "../../components/error";
import { checkValidationRule } from "../../utils/validate";
import FormControl from "@material-ui/core/FormControl";
import FormGroup from "@material-ui/core/FormGroup";
import InputField from "../input-field/input-field";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "../../views/tool-tip";
import Switch from "@material-ui/core/Switch";
import FormLabel from "@material-ui/core/FormLabel";
import { withStyles } from "@material-ui/core/styles";

const StyledSwitch = withStyles((theme) => ({
  root: {
    display: "none",
    width: 42,
    height: 26,
    padding: 0,
    margin: theme.spacing(1),
  },
  switchBaseDefault: {
    padding: 1,
    color: (props) => `${props.themecolors.onHoverInColor}`,
    "&$checked": {
      color: (props) => `${props.themecolors.onHoverInColor}`,
      transform: "translateX(16px)",
      "& + $track": {
        backgroundColor: (props) => `${props.themecolors.onHoverBgColor}`,
        opacity: 1,
        // border: "none",
        border: (props) => `${props.themecolors.onHoverBorder}`,
      },
    },
  },

  thumb: {
    width: 24,
    height: 24,
  },
  trackDefault: {
    border: (props) => `${props.themecolors.onHoverBorder}`,
    backgroundColor: (props) =>
      `${props.themecolors.onHoverBgColor} !important`,
    borderRadius: 26 / 2,
    opacity: 1,
  },

  checked: {},
  focusVisible: {},
}))(({ classes, ...props }) => {
  // const { theme } = props;

  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,

        switchBase: classes.switchBaseDefault,
        thumb: classes.thumb,
        //track: classes.track,
        track: classes.trackDefault,
        checked: classes.checked,
      }}
      {...props}
      style={{ display: "none" }}
    />
  );
});

const useStyles = makeStyles((theme) => ({
  formControl: {},
  root: {
    marginLeft: "10px",
    backgroundColor: "#fff",
    padding: "6px",
    marginBottom: "4px !important",
    position: "relative",
    borderRadius: "2px",
  },
  secondLayout: {
    paddingLeft: "10px",
  },
  rootInput: {
    maxWidth: "250px",
    marginLeft: "16px",
  },
  formLabelrootInline: {
    paddingLeft: "0px ",
    color: (props) => `${props.themecolors.labelcolor} !important`,
    fontSize: "30px",
    padding: " 10px",
    fontWeight: "500",
    display: "flex",
  },
  formLabelroot: {
    display: "flex",
    paddingLeft: "0px",
    alignItems: "center",
    color: "#000 !important",
    fontSize: "14px",
    fontWeight: "bold",
  },
  checkBoxDesignSelectedSurveySixth: {
    backgroundColor: (props) =>
      `${props.themecolors.onSelectionbgcolor} !important`,
    color: (props) => `${props.themecolors.onHoverTextColor} !important`,
    transition: "0.3s",
    border: (props) => `${props.themecolors.onHoverBorder}`,
  },
  checkBoxDesignUnselectedSurveySixth: {
    backgroundColor: (props) =>
      `${props.themecolors.lightBackgroundColor} !important`,
    color: (props) => `${props.themecolors.hoverOutColor} !important`,
    border: (props) => `${props.themecolors.border}`,
    transition: "0.3s",
    "&:hover": {
      backgroundColor: (props) =>
        `${props.themecolors.onSelectionbgcolor} !important`,
      color: (props) => `${props.themecolors.onHoverTextColor} !important`,
      transition: "0.3s",
      border: (props) => `${props.themecolors.onHoverBorder}`,
      transform: "scale(1.03)",
    },
  },
}));

const CheckBox = (props) => {
  const {
    type,
    options,
    label,
    name,
    // className,
    other,
    inline,
    toggle,
    onChange,
    onChangeOther,
    validate,
    validationRule,
    validateOptions,
    validationCallback,
    // value,
    description,
    data,
    theme,
    templatetheme,
    // iconName,
    displayicon,
    themecolors,
    activestep,
  } = props;

  const [errors, setError] = useState({});
  const [valueCheckboxOther, setValueCheckboxOther] = React.useState(
    data && data.isOtherSelected ? "Other" : ""
  );

  const [otherTxt, setField] = React.useState(
    data && data.isOtherText ? data.isOtherText : ""
  );

  const classes = useStyles({ themecolors });
  const [valueCheckbox, setValueCheckbox] = React.useState(options);
  const setSelectedOptions = (e) => {
    valueCheckbox.filter((item) => {
      if (item.value === e.target.value) {
        item.selected = e.target.checked;
      }
      return item;
    });

    onChange(e, valueCheckbox);

    setValueCheckbox(valueCheckbox);
  };
  const onChangeCheckBox = (e) => {
    setSelectedOptions(e);
    setError({});
  };

  const onChangeCheckBoxOther = (e) => {
    if (e.target.checked) {
      setValueCheckboxOther(e.target.value);
      onChangeOther("", true, "");
    } else {
      setValueCheckboxOther("");
      onChangeOther("", false, "");
    }
    setField("");
  };

  const handleOtherChange = (e) => {
    setField(e.target.value);
    onChangeOther(e.target.value, true, "");
  };

  const validationCallbackChek = (res) => {
    setError(res);
  };

  const { [name]: error = "" } = errors;

  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };
    if (checkValidationRule(isVisible, validationRule)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_CLICK" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const validateFields = (isVisible, e) => {
    const { required } = validateOptions;

    if (required) {
      let errorMsg;

      var ifExist = valueCheckbox.filter((item) => {
        return item.selected === true;
      });

      if (ifExist.length === 0) {
        //&& valueCheckboxOther === ""
        errorMsg = `${name} is required`;
      } else if (otherTxt === "") {
        errorMsg = `${name} is required`;
      } else {
        errorMsg = "";
      }
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }
    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));

    setValueCheckbox(options);
  });

  return (
    <FormControl
      className={classes.root}
      style={{
        width: "100%",
        marginLeft: "0px",
      }}
    >
      <FormLabel
        className={
          type === "survey"
            ? classes.formLabelrootInline
            : classes.formLabelroot
        }
      >
        {label}&nbsp;
        {validateOptions.required && type !== "survey" && (
          <span className="mendatory">*</span>
        )}
        {description && <Tooltip title={description} />}
      </FormLabel>

      <FormGroup
        id={`step_${activestep}_${name}`}
        style={{ display: inline ? "inline" : "inharite" }}
      >
        {valueCheckbox.map((item, index) => {
          return (
            <Fragment key={index}>
              {toggle ? (
                <FormControlLabel
                  name={name}
                  id={`step_${activestep}_${name}_formcontrol`}
                  value={item.value || ""}
                  className={`${classes.root} ${
                    item.selected
                      ? classes.checkBoxDesignSelectedSurveySixth
                      : classes.checkBoxDesignUnselectedSurveySixth
                  }`}
                  control={
                    <span
                      className={classes.iconMain}
                      onChange={onChangeCheckBox}
                      style={{
                        display: displayicon === "show" ? "inline" : "none",
                      }}
                      id={`step_${activestep}_${name}_span`}
                    >
                      <i
                        className={`demo-icon ${item.icon} ${classes.iconOne}`}
                      >
                        {" "}
                      </i>
                      <StyledSwitch
                        key={index}
                        value={item.value || ""}
                        // onChange={onChangeCheckBox}
                        checked={item.selected}
                        themecolors={themecolors}
                        id={`step_${activestep}_${name}_span_switch`}
                      />
                    </span>
                  }
                  label={item.label}
                  style={{ padding: displayicon === "hide" ? "16px" : "" }}
                />
              ) : (
                <FormControlLabel
                  className={`${classes.root} ${
                    item.selected ? "checkbox-selected" : "checkbox-unselected"
                  } ${
                    item.selected
                      ? classes.checkBoxDesignSelectedSurveySixth
                      : classes.checkBoxDesignUnselectedSurveySixth
                  }`}
                  id={`step_${activestep}_${name}_span_formcontrollabel`}
                  control={
                    <span
                      id={`step_${activestep}_${name}_span`}
                      className={"icon-span"}
                      onChange={onChangeCheckBox}
                      style={{
                        fontSize: `${
                          templatetheme === "1" || templatetheme === "2"
                            ? "30px"
                            : "25px"
                        }`,
                        height: `${
                          templatetheme === "1" || templatetheme === "2"
                            ? "45px"
                            : "38px"
                        }`,
                        width: `${
                          templatetheme === "1" || templatetheme === "2"
                            ? "45px"
                            : "38px"
                        }`,
                        borderRight: "1px solid rgb(0,0,0,.2)",
                        display: `${
                          displayicon === "show" ? "inline" : "none"
                        }`,
                      }}
                    >
                      <i
                        className={`demo-icon ${item.icon} ${classes.iconOne}`}
                      >
                        {" "}
                      </i>
                      <StyledSwitch
                        key={index}
                        value={item.value || ""}
                        onChange={onChangeCheckBox}
                        checked={item.selected}
                        themecolors={themecolors}
                      />
                    </span>
                  }
                  label={
                    <div
                      className={
                        templatetheme === "2" && displayicon === "show"
                          ? `${classes.secondLayout}`
                          : ""
                      }
                      id={`step_${activestep}_${name}_span_label`}
                    >
                      {item.label}
                    </div>
                  }
                  style={{ padding: displayicon === "hide" ? "16px" : "" }}
                />
              )}
            </Fragment>
          );
        })}
        {other ? (
          <>
            {toggle ? (
              <FormControlLabel
                value="other"
                name="other"
                className={classes.root}
                style={{ marginLeft: "10px" }}
                control={
                  <Switch
                    value="Other"
                    onChange={onChangeCheckBoxOther}
                    checked={data && data.isOtherSelected}
                    color="default"
                  />
                }
                label="Other"
                id={`step_${activestep}_${name}_span_other`}
              />
            ) : (
              <FormControlLabel
                value="other"
                style={{ marginLeft: "16px" }}
                control={
                  <Checkbox
                    value="Other"
                    name="other"
                    onChange={onChangeCheckBoxOther}
                    color="default"
                    checked={data && data.isOtherSelected}
                    id={`step_${activestep}_${name}_chkbox`}
                  />
                }
                id={`step_${activestep}_${name}_chk_formcontrol`}
                label="Other"
              />
            )}
            {valueCheckboxOther === "Other" ? (
              <div
                className={classes.rootInput}
                id={`step_${activestep}_${name}_div`}
              >
                <InputField
                  className={classes.rootInput}
                  label={""}
                  placeHolder="Enter text here"
                  name={"otherTxt"}
                  validateOptions={{
                    check: true,
                    required: validateOptions.required,
                  }}
                  validate={validate}
                  validationCallback={(res) => validationCallbackChek(res)}
                  fieldAttributes={{
                    autoComplete: "off",
                    type: "text",
                    onChange: handleOtherChange.bind(this),
                    value: otherTxt || "",
                  }}
                  showErrorText={false}
                  theme_id={theme}
                  id={`step_${activestep}_${name}_input`}
                />
              </div>
            ) : (
              <> </>
            )}
          </>
        ) : (
          <></>
        )}
      </FormGroup>
      {!!error && <Error errorMessage={error} />}
    </FormControl>
  );
};
CheckBox.propTypes = {
  disabled: bool,
  id: oneOfType([string.isRequired, number.isRequired]),
  isChecked: bool.isRequired,
  isError: bool,
  optionText: string,
  validationCallback: func,
  isDisabled: bool,
  label: string,
  onChange: func,
};
CheckBox.defaultProps = {
  label: "",
  optionText: "",
  isError: false,
  isChecked: false,
  isDisabled: false,
  type: "standard",
};

export default CheckBox;
