import React from "react";
import { object } from "prop-types";
import Utils from "../../../../utils/index";

const SecondSectionPage = (props) => {
  const { siteData, mainData } = props;

  const handleClick = ({ page_url, is_external, target }) => {
    Utils.handleClickUrl(page_url, is_external, target);
  };

  const survey_page = Utils.getPageContent(mainData, 6);

  return (
    <div className="section-second-main">
      {mainData &&
      mainData.primary_color !== "fourth-theme" &&
      mainData.primary_color !== "fifth-theme" ? (
        <section
          className="sectionone first bgblue"
          style={{
            backgroundColor: siteData && siteData.primary_color,
            backgroundImage: `url(${
              siteData ? siteData.section_first_img : ""
            })`,
          }}
        >
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="heading-section">
                  <h2>{siteData.section_second_header}</h2>
                  <p>{siteData.section_second_paragraph}</p>
                  <span
                    className="homebtn"
                    onClick={() => handleClick(survey_page)}
                    style={{ cursor: "pointer" }}
                  >
                    {siteData.heading}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="sectionone first bgblue">
          <div className="container">
            <div className="row secondSecRow">
              <div className="col-sm-6 order-md-2">
                <div className="heading-section">
                  <h2>{siteData.section_second_header}</h2>
                  <p>{siteData.section_second_paragraph}</p>
                  <span
                    className="homebtn"
                    onClick={() => handleClick(survey_page)}
                    style={{ cursor: "pointer" }}
                  >
                    {siteData.heading}
                  </span>
                </div>
              </div>
              <div className="col-sm-6 order-md-1">
                <img
                  src={siteData.section_first_img}
                  alt={siteData.sti_alt_text}
                  title={siteData.sti_alt_text}
                  className="secondSecImg"
                />
              </div>
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

SecondSectionPage.propTypes = {
  siteData: object,
};

export default SecondSectionPage;
