import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";

const useStylesBootstrap = makeStyles(theme => ({
  arrow: {
    color: '#363F48'
  },
  tooltip: {
    backgroundColor: '#363F48',
    fontSize: '12px'
  }
}));

function BootstrapTooltip(props) {
  const classes = useStylesBootstrap();

  return <Tooltip arrow classes={classes} {...props} />;
}

export default function ToolTip(props) {
  const {title} = props;
  return (
    <div>
      <BootstrapTooltip title={title} placement="top-end">
        <HelpOutlineOutlinedIcon style={{ fontSize: '15px' }} />
      </BootstrapTooltip>
    </div>
  );
}