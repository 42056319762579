import React, { useEffect, useState } from "react";
import Utils from "../../utils";
import Api from "../../api";
import $ from "jquery";

const AdvertlyPlugin = (props) => {
  const { siteData ,callback} = props;
  const [isLoading, setIsLoading] = useState(false);
  let siteDataValue = Utils.getPageContent(siteData, 7);

  const showAdvertlyAds =
    siteDataValue && siteDataValue?.section_third_header === "yes";
  const advertlydatakey =
    siteDataValue && siteDataValue?.section_third_paragraph;

  useEffect(() => {
    console.log("agsjdhfjasfdhhafsjdfjafjdfjafjfdf")
    let userdata =
      localStorage.getItem("userdata") !== null &&
      localStorage.getItem("userdata") !== undefined
        ? JSON.parse(localStorage.getItem("userdata"))
        : "";
    if (
      userdata !== "" &&
      showAdvertlyAds &&
      advertlydatakey !== null &&
      advertlydatakey !== undefined
    ) {
      setIsLoading(true);
      var scripts = `<script>
      var user = {
          "fname": '${userdata.fname || ""}',
          "lname": '${userdata.lname || ""}',
          "email": '${userdata.emailId || ""}',
          "phone": '${userdata.phone || ""}',
          "age": ${userdata.age || 0},
          "postcode":'${userdata.postcode || ""}',
          "city":'${userdata.city || ""}',
          "state":'${userdata.state || ""}',
          "address":'${userdata.address || ""}',
          "gender":'${userdata.gender || ""}',
          "dob":'${userdata.dob||null}'
      }
      </script>
      <script src="https://code.jquery.com/jquery-3.5.0.min.js"></script>
      <link rel="stylesheet" href="https://advertly.com.au/advertlyDesign.css" >
      <script src="https://advertly.com.au/AdvertlyPlugin.js" data-key="${advertlydatakey}"></script>      
      `;
      $("head").append(`${scripts}`);
      callback&&setTimeout( function(){callback(false)},4000);
      Api.trackAdvertlyPluginError(
        `Testing for Advertly plugin - ${JSON.stringify(userdata)}`
      );
    } else {
      callback&&setTimeout( function(){callback(false)},1000);
      Api.trackAdvertlyPluginError(
        `Issue - Testing for Advertly plugin - ${
          userdata || "null userdata"
        } - ${showAdvertlyAds || "no ads"} - ${advertlydatakey || "no key"}`
      );
    }
  }, []);

  return (
    <>
      {showAdvertlyAds &&
      advertlydatakey !== null &&
      advertlydatakey !== undefined ? (
        <>
          <div id="advertly-launcher"></div>
        </>
      ) : (
        <></>
      )}
    </>
  );
};
export default AdvertlyPlugin;
