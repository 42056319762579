import {
  GET_SITE_DATA_REQUESTED,
  GET_SITE_DATA_SUCCESS,
  GET_SITE_DATA_FAILURE,
  LEAD_DATA_REQUESTED,
  LEAD_DATA_SUCCESS,
  LEAD_DATA_FAILURE,
} from "../actions/manage-site-action-type";

const initialState = {
  leadDataObj:'',
  getSiteDataObject: [],
  getSiteDataObjectAboutUs: [],
  getSiteDataObjectContactUs: [],
  getSiteDataObjectPrivacyPolicy: [],
  getSiteDataObjectTermCondition: [],
  getSiteStatus: null,
  getSiteError: null,
  isLoading: false,
  getSiteDataAboutUsObject: [],
};

const ManageSite = (state = initialState, { payload, type }) => {
  switch (type) {
    case GET_SITE_DATA_REQUESTED:
      return {
        ...state,
        getSiteError: null,
        getSiteStatus: null,
        isLoading: true,
      };
    case GET_SITE_DATA_SUCCESS:
      return {
        ...state,
        getSiteDataObject: payload,
        getSiteStatus: "success",
        isLoading: false,
      };
    case GET_SITE_DATA_FAILURE:
      return {
        ...state,
        getSiteDataObject: { ...payload },
        getSiteStatus: "failure",
        isLoading: false,
      };
    case LEAD_DATA_REQUESTED:
      return {
        ...state,
        getSiteError: null,
        getSiteStatus: null,
        isLoading: true,
      };
    case LEAD_DATA_SUCCESS:
      return {
        ...state,
        leadDataObj: payload,
        getSiteStatus: "success",
        isLoading: false,
      };
    case LEAD_DATA_FAILURE:
      return {
        ...state,
        leadDataObj: { ...payload },
        getSiteStatus: "failure",
        isLoading: false,
      };

    default:
      return { ...state };
  }
};
export default ManageSite;
