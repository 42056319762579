import React from "react";
import { object } from "prop-types";
import $ from "jquery";
import { Helmet } from "react-helmet";
import { Link } from 'react-router-dom';
import Utils from "../../../utils/index";
import FooterDisclaimer from "../../shared/footer-disclaimer";

const FooterPage = ({ siteData, page, isPageRefreshed }) => {
  const handleClick = ({ page_url, is_external, target }) => {
    if (page && page !== undefined && (page === 7 || page === "7")) {
      window.location.href = `${page_url}`;
    } else {
      Utils.handleClickUrl(page_url, is_external, target);
    }
  };

  const contact_us = Utils.getPageContent(siteData, 3);
  const about_us = Utils.getPageContent(siteData, 2);
  const privacy_policy = Utils.getPageContent(siteData, 4);
  const terms_conditions = Utils.getPageContent(siteData, 5);
  const thankyou = Utils.getPageContent(siteData, 7);

  const renderScript = (siteData) => {
    if (isPageRefreshed) {
      $("head").append(siteData && siteData.fb_script && siteData.fb_script);
      $("body").append(
        siteData && siteData.google_script && siteData.google_script
      );
    }
  };

  const renderThankyouMetatags = (siteData) => {
    return (
      <Helmet>
        <title>{thankyou.meta_title}</title>
        <meta name="description" content={thankyou.meta_description} />
        <link rel="icon" href={siteData.favicon} sizes="16x16" />
      </Helmet>
    );
  };

  window.scrollTo({ top: 0, left: 0, behavior: "smooth" });

  return (
    <>
      <div className="section-footer">
        {renderScript(siteData)}
        {(page === 7 || page === "7") && renderThankyouMetatags(siteData)}
      </div>
      <section className="copyright-footer">
        <div className="container">
          <div className="row">
            <div className="col-sm-6">
              <p>
                © Copyright {new Date().getFullYear()} - {siteData.name}
              </p>
            </div>
            <div className="col-sm-6">
              <ul>
                <li>
                  <Link
                    style={{ cursor: "pointer" }}
                    alt="privacy policy"
                    // onClick={() => handleClick({
                    //   page_url: '/advertise-with-us',
                    //   is_external: false,
                    // })}
                    to="/advertise-with-us"
                  >
                    Advertise With Us
                  </Link>
                </li>
                {about_us ? (
                  <li>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(about_us)}
                      alt="home logo"
                    >
                      About Us
                    </span>
                  </li>
                ) : (
                  <></>
                )}

                {contact_us ? (
                  <li>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(contact_us?.page_url)}
                      alt="Contact Us"
                    >
                      Contact Us
                    </span>
                  </li>
                ) : (
                  <></>
                )}
                {privacy_policy ? (
                  <li>
                    <span
                      style={{ cursor: "pointer" }}
                      alt="privacy policy"
                      onClick={() => handleClick(privacy_policy)}
                    >
                      Privacy Policy
                    </span>
                  </li>
                ) : (
                  <></>
                )}
                {terms_conditions ? (
                  <li>
                    <span
                      style={{ cursor: "pointer" }}
                      onClick={() => handleClick(terms_conditions)}
                      alt="Terms and Conditions"
                    >
                      Terms and Conditions
                    </span>
                  </li>
                ) : (
                  <></>
                )}
              </ul>
            </div>
          </div>
          <FooterDisclaimer siteData={siteData} />
        </div>
      </section>
    </>
  );
};

FooterPage.propTypes = {
  siteData: object,
};

export default FooterPage;
