import React from "react";
import queryString from "query-string";
import history from "../../routes/history";
import InputField from "../../views/input-field/input-field";
import NumberField from "../../views/number-field";
import constants from "../../constants";
import Button from "../../views/button";
import { button_appearances } from "../../views/button";
import SelectBox from "../../views/dropdown/index";
import ComboBox from "../../views/combo-box";
import RadioBox from "../../views/radio-box/index";
import CheckBox from "../../views/check-box/index";
import DateField from "../../views/date-field";
import FileField from "../../views/file-field/file-field";
import TextArea from "../../views/text-area/index";
import { leadData } from "../../actions/manage-site-action-type";
import { connect } from "react-redux";
import axios from "axios";
import profanityWords from "../../assets/profanityWords.json";
import DynmicDropDownAutocomplete from "../../views/drop-down-auto-complete/DropdownAndAutoComplete";
import DropDownAutocomplete from "../../views/drop-down-auto-complete/DropdownAndAutoComplete";
import AutoCompletePostSuburb from "../../views/drop-down-auto-complete/autocomplete-post-suburb";
import "./dynamic-form.scss";
import Utils from "../../utils";
import Card from "../../views/card/card";
import themecolors from "../../utils/themecolors";
import Otpcard from "../../views/card/otpcard";
import { comparesolarmarket } from "../../utils/microsites-constants";

class FifthLayoutDynamicForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fields: {},
      fieldsData: {},
      error: "",
      isValidate: false,
      isPhoneValid: true,
      isLoading: false,
      showOtpPopup: false,
      phoneverified: false,
      showOtpPop: false,
    };
    this.state.fields = props.prevData;
    //  this.state.fieldsData = props.formData;
  }

  componentDidMount = () => {
    const { data } = this.props;

    data.map((item) => {
      if (item.type !== "button" && item.name !== undefined) {
        let returnVal = this.returnValue(item);

        this.setState((prevState, props) => ({
          fields: {
            ...prevState.fields,
            [item.name]: returnVal,
          },
          [`has${item.name}Error`]: returnVal !== "" ? false : true,
        }));
      }
    });

    Utils.getData().then((res) => {
      this.setState({ ipv4: res });
    });
  };

  returnValue = (item) => {
    var returnValue = "";

    if (item.value) {
      returnValue = item.value;
    } else if (item.values) {
      var valuesArray = [];
      // var temparr = [];
      if (item.type === "select") {
        valuesArray = item.values.filter((item) => {
          return item.selected === true;
        });
      } else {
        valuesArray = item.values.filter((item) => {
          return item.selected === true;
        });
      }

      if (valuesArray.length > 0) {
        valuesArray.map((item) => {
          returnValue = returnValue + "," + item.value;
        });
      } else {
        returnValue = "";
      }
    } else {
      returnValue = "";
    }

    returnValue = returnValue.replace(/^,|,$/g, "");
    return returnValue;
  };

  handleChange = (field, data, e) => {
    const { target } = e;
    const { fields } = this.state;
    fields[field] = target.value;
    this.setState({
      fields,
      [`has${[e.target.name]}Error`]: false,
      isPhoneValid: true,
    });
  };

  handleFileZoneChange = (field, data, e) => {
    const { target } = e;
    const { fields } = this.state;
    fields[field] = target;
    this.setState({ fields });
  };

  comboChange = (value, data, field) => {
    const { fields } = this.state;
    fields[field] = value;
    this.setState({ fields });
  };

  comboClick = (value, field, displayName) => {
    const { fields } = this.state;
    fields[field] = value !== null ? value[displayName] : "";
    this.setState({ fields });
  };

  handleSubmit = (e) => {
    e.preventDefault();

    let isTrue = true;
    const { siteData, dataset, page_id, theme_id, QBData, leadData } =
      this.props;
    const { fieldsData, fields } = this.state;
    let siteFormData = siteData.dynamic_forms.filter((item) => {
      return item.page_id === page_id;
    })[0];
    let siteSurveyContent = Utils.getPageContent(siteData, 6);

    this.setState({ isValidate: true });

    let formData = [];

    Object.keys(fields).map((key) => {
      var fielddata = dataset.filter((inner_item) => {
        return inner_item.name === key;
      })[0];

      var formValue = fields[key];
      if (
        fielddata &&
        fielddata.type === "checkbox-group" &&
        fielddata.isOtherSelected &&
        fielddata.isOtherText
      ) {
        formValue = formValue + "," + fielddata.isOtherText;
        formValue = formValue.replace(/^,|,$/g, "");
      }
      if (
        fielddata &&
        fielddata.type === "radio-group" &&
        fielddata.isOtherSelected &&
        fielddata.isOtherText
      ) {
        formValue = fielddata.isOtherText;
      }

      formData.push({
        label: fielddata.label,
        value: formValue,
        name: fielddata.name,
        subtype:
          fielddata.subtype && fielddata.subtype !== undefined
            ? fielddata.subtype
            : fielddata.type,
      });
    });

    fieldsData.data = JSON.stringify(formData);
    fieldsData.domain = window.location.host;
    fieldsData.site_id = siteData.id;
    fieldsData.page_id = page_id;
    fieldsData.form_type = "1";
    fieldsData.site_name = siteData.name;
    fieldsData.interested_in = siteFormData && siteFormData.tag_id;
    const thankyou = Utils.getPageContent(siteData, 7);
    fieldsData.thankyou_page_url = thankyou?.page_url || null;

    let fullName = "";
    let i, len;
    let fName = "";
    let lName = "";

    for (i = 0, len = formData.length; i < len; i++) {
      let tname = formData[i].name ? formData[i].name.toLowerCase() : "";
      let tsubtype = formData[i].subtype ? formData[i].subtype.toLowerCase() : "";

      if (tname === "name" || tname === "fullname" || tname === "full-name" || tname === "full_name") {
        fullName = formData[i].value;
      }

      if (
        tname === "firstname" ||
        tname === "fname" ||
        tname === "first-name"
      ) {
        fName = formData[i].value;
      }

      if (tname === "lastname" || tname === "lname" || tname === "last-name") {
        lName = formData[i].value;
      }

      if (
        tname === "dob" ||
        tname === "dateofbirth" ||
        tname === "date-of-birth"
      ) {
        let date = formData[i].value;
        if (date instanceof Date && !isNaN(date)) {
          fieldsData["dob"] = date;

          var today = new Date();
          var birthDate = new Date(date);
          var age_now = today.getFullYear() - birthDate.getFullYear();
          var m = today.getMonth() - birthDate.getMonth();
          if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
            age_now--;
          }

          fieldsData["age"] = age_now;
        }
      }

      if (tname === "gender" || tname === "sex") {
        fieldsData["gender"] = formData[i].value;
      }

      if (formData[i].subtype === "email") {
        fieldsData["email"] = formData[i].value;
      }

      if (formData[i].subtype === "tel") {
        fieldsData["phone_number"] = formData[i].value;
      }

      if (
        tname === "town_city" ||
        tname === "towncity" ||
        tname === "town" ||
        tname === "city"
      ) {
        fieldsData["town_city"] = formData[i].value;
      }

      if (
        tname === "post_code" ||
        tname === "postcode" ||
        tname === "zip_code" ||
        tname === "zip"
      ) {
        fieldsData["postcode"] = formData[i].value;
        fieldsData["full_address"] = formData[i].value;
      }

      if (tname === "state") {
        fieldsData["state"] = formData[i].value;
      }

      // for credit councellor data
      if (
        fieldsData.domain === "www.creditcounsellors.supportwithdebt.com.au" ||
        fieldsData.domain === "creditcounsellors.supportwithdebt.com.au"
      ) {
        if (tname === "unsecured_debt" || tname === "unsecureddebt") {
          fieldsData["unsecured_debt"] = formData[i].value;
        }
        if (tname === "home_ownership" || tname === "homeownership") {
          fieldsData["home_ownership"] = formData[i].value;
        }
        if (tname === "income") {
          fieldsData["income"] = formData[i].value;
        }
      }

      //Health Deal api section
      if (
        fieldsData.domain === "www.healthdeal.myhealthcompare.com.au" ||
        fieldsData.domain === "healthdeal.myhealthcompare.com.au"
      ) {
        if (tname === "covered") {
          fieldsData["covered"] = formData[i].value;
        }
        if (tname === "provider") {
          fieldsData["provider"] = formData[i].value;
        }
      }
      //End Section

      //first_national api section
      if (
        fieldsData.domain === "firstnational.ozhouseprice.com.au" ||
        fieldsData.domain === "www.firstnational.ozhouseprice.com.au" ||
        fieldsData.domain === "www.propertyvalue.ozhouseprice.com.au" ||
        fieldsData.domain === "propertyvalue.ozhouseprice.com.au" ||
        fieldsData.domain === "www.ozhouseprice.com.au" ||
        fieldsData.domain === "ozhouseprice.com.au" ||
        fieldsData.domain === "www.edm.ozhouseprice.com.au" ||
        fieldsData.domain === "edm.ozhouseprice.com.au"
      ) {
        if (tname === "lookingTo") {
          fieldsData["lookingTo"] = formData[i].value;
        }
        if (tname === "property_type") {
          fieldsData["property_type"] = formData[i].value;
        }
        if (tname === "postcode") {
          fieldsData["postcode"] = formData[i].value;
          var postcodeArray = formData[i].value.split(", ");
          fieldsData["state"] =
            postcodeArray && postcodeArray.length > 1 && postcodeArray[1];
        }
        if (tname === "address") {
          fieldsData["address"] = formData[i].value;
        }
        let queries = queryString.parse(history.location.search);
        fieldsData["source"] = queries && queries.source;
      }
      //End Section

      //canadian quick and easy hear api section
      if (
        fieldsData.domain === "canadian.quickandeasyhearing.com" ||
        fieldsData.domain === "www.canadian.quickandeasyhearing.com" ||
        fieldsData.domain === "canadian2.quickandeasyhearing.com" ||
        fieldsData.domain === "www.canadian2.quickandeasyhearing.com" ||
        fieldsData.domain === "ca.quickandeasyhearing.com" ||
        fieldsData.domain === "www.ca.quickandeasyhearing.com" ||
        fieldsData.domain === "ca2.quickandeasyhearing.com" ||
        fieldsData.domain === "www.ca2.quickandeasyhearing.com" ||
        fieldsData.domain === "nz1.quickandeasyhearing.com" ||
        fieldsData.domain === "www.nz1.quickandeasyhearing.com" ||
        fieldsData.domain === "aus.quickandeasyhearing.com" ||
        fieldsData.domain === "www.aus.quickandeasyhearing.com" ||
        fieldsData.domain === "newaus.quickandeasyhearing.com" ||
        fieldsData.domain === "www.newaus.quickandeasyhearing.com" ||
        fieldsData.domain === "hca.quickandeasyhearing.com" ||
        fieldsData.domain === "www.hca.quickandeasyhearing.com" ||
        fieldsData.domain === "hca2.quickandeasyhearing.com" ||
        fieldsData.domain === "www.hca2.quickandeasyhearing.com"
      ) {
        if (tname === "haveHearingAids" || tname === "havehearingaids") {
          fieldsData["haveHearingAids"] = formData[i].value;
        }
        if (tname === "gender" || tname === "Gender") {
          fieldsData["gender"] = formData[i].value;
        }
        if (tname === "struggleToHear" || tname === "struggletohear") {
          fieldsData["struggleToHear"] = formData[i].value;
        }
        if (tname === "age" || tname === "Age") {
          fieldsData["age"] = formData[i].value;
        }
      }
      //End Section
      //Stars
      if (
        fieldsData.domain === "nz2.quickandeasyhearing.com" ||
        fieldsData.domain === "www.nz2.quickandeasyhearing.com" ||
        fieldsData.domain === "au1.quickandeasyhearing.com" ||
        fieldsData.domain === "www.au1.quickandeasyhearing.com" ||
        fieldsData.domain === "au.quickandeasyhearing.com" ||
        fieldsData.domain === "www.au.quickandeasyhearing.com" ||
        fieldsData.domain === "ca1.quickandeasyhearing.com" ||
        fieldsData.domain === "www.ca1.quickandeasyhearing.com" ||
        fieldsData.domain === "ca3.quickandeasyhearing.com" ||
        fieldsData.domain === "www.ca3.quickandeasyhearing.com" ||
        fieldsData.domain === "trycanada.quickandeasyhearing.com" ||
        fieldsData.domain === "www.trycanada.quickandeasyhearing.com" ||
        fieldsData.domain === "nzv2.quickandeasyhearing.com" ||
        fieldsData.domain === "www.nzv2.quickandeasyhearing.com"
      ) {
        if (tname === "age" || tname === "Age") {
          fieldsData["age"] = formData[i].value;
        }
        if (tname === "haveHearingAids" || tname === "havehearingaids") {
          fieldsData["haveHearingAids"] = formData[i].value;
        }
        if (tname === "gender" || tname === "Gender") {
          fieldsData["gender"] = formData[i].value;
        }
        if (tname === "struggleToHear" || tname === "struggletohear") {
          fieldsData["struggleToHear"] = formData[i].value;
        }
      }
      //End here
      //Stars
      if (
        fieldsData.domain === "www.businessfundstoday.net" ||
        fieldsData.domain === "businessfundstoday.net" ||
        fieldsData.domain === "biz.businessfundstoday.net" ||
        fieldsData.domain === "www.biz.businessfundstoday.net" ||
        fieldsData.domain === "v2.businessfundstoday.net" ||
        fieldsData.domain === "www.v2.businessfundstoday.net" ||
        fieldsData.domain === "businessloan.financesolver.co" ||
        fieldsData.domain === "www.businessloan.financesolver.co" ||
        fieldsData.domain === "native.financesolver.co" ||
        fieldsData.domain === "www.native.financesolver.co" ||
        fieldsData.domain === "bizau.businessfundstoday.net" ||
        fieldsData.domain === "www.bizau.businessfundstoday.net"
      ) {
        if (tname === "business_name") {
          fieldsData["business_name"] = formData[i].value;
        }
        if (tname === "est_time_trading") {
          fieldsData["est_time_trading"] = formData[i].value;
        }
        if (tname === "monthly_revenue") {
          fieldsData["monthly_revenue"] = formData[i].value;
        }
        if (tname === "loan_purpose") {
          fieldsData["loan_purpose"] = formData[i].value;
        }

        if (tname === "est_loan_amount") {
          fieldsData["est_loan_amount"] = formData[i].value;
        }
        if (
          fieldsData.domain === "biz.businessfundstoday.net" ||
          fieldsData.domain === "www.biz.businessfundstoday.net" ||
          fieldsData.domain === "v2.businessfundstoday.net" ||
          fieldsData.domain === "www.v2.businessfundstoday.net" ||
          fieldsData.domain === "businessloan.financesolver.co" ||
          fieldsData.domain === "www.businessloan.financesolver.co" ||
          fieldsData.domain === "native.financesolver.co" ||
          fieldsData.domain === "www.native.financesolver.co" ||
          fieldsData.domain === "bizau.businessfundstoday.net" ||
          fieldsData.domain === "www.bizau.businessfundstoday.net"
        ) {
          if (tname === "abn") {
            fieldsData["abn"] = formData[i].value;
          }
        }
      }
      //End here

      //Health Deal api section for myhealthcompare com.au
      if (
        fieldsData.domain === "www.compare.myhealthcompare.com.au" ||
        fieldsData.domain === "compare.myhealthcompare.com.au" ||
        fieldsData.domain === "www.new.myhealthcompare.com.au" ||
        fieldsData.domain === "new.myhealthcompare.com.au"
      ) {
        if (
          tname === "covered" ||
          tname === "policytype" ||
          tname === "policy-type"
        ) {
          fieldsData["covered"] = formData[i].value;
        }

        if (tname === "provider") {
          fieldsData["provider"] = formData[i].value;
        }
        if (tname === "cover") {
          fieldsData["cover"] = formData[i].value;
        }
      }

      if (
        fieldsData.domain === "kiwihouseprice.com" ||
        fieldsData.domain === "www.kiwihouseprice.com"
      ) {
        if (tname === "homeowner") {
          fieldsData["homeowner"] = formData[i].value;
        }
        if (tname === "sellnextyear") {
          fieldsData["sellnextyear"] = formData[i].value;
        }
        if (tname === "sellproperty") {
          fieldsData["sellproperty"] = formData[i].value;
        }
      }

      //My Broadband Compare Dodo api section for http://mybroadbandcompare.com.au/
      if (
        fieldsData.domain === "www.mybroadbandcompare.com.au" ||
        fieldsData.domain === "mybroadbandcompare.com.au" ||
        fieldsData.domain === "www.v2.mybroadbandcompare.com.au" ||
        fieldsData.domain === "v2.mybroadbandcompare.com.au"
      ) {
        if (tname === "primary_reason") {
          fieldsData["primary_reason"] = formData[i].value;
        }
        if (tname === "broadband_feature") {
          fieldsData["broadband_feature"] = formData[i].value;
        }
        if (tname === "household") {
          fieldsData["household"] = formData[i].value;
        }
        if (tname === "usage") {
          fieldsData["usage"] = formData[i].value;
        }
        if (tname === "bundle") {
          fieldsData["bundle"] = formData[i].value;
        }
        if (tname === "current_internet_provider") {
          fieldsData["current_internet_provider"] = formData[i].value;
        }
        if (tname === "own_rent") {
          fieldsData["own_rent"] = formData[i].value;
        }
        if (tname === "moving") {
          fieldsData["moving"] = formData[i].value;
        }
      }
      //End Section

      //Starts and added on 26/01/2022  for www.2022.myenergycompare.com.au
      if (
        fieldsData.domain === "gs.myenergycompare.com.au" ||
        fieldsData.domain === "www.gs.myenergycompare.com.au" ||
        fieldsData.domain === "myenergycompare.com.au" ||
        fieldsData.domain === "www.myenergycompare.com.au" ||
        fieldsData.domain === "justcompare.myenergycompare.com.au" ||
        fieldsData.domain === "www.justcompare.myenergycompare.com.au" ||
        fieldsData.domain === "2022.myenergycompare.com.au" ||
        fieldsData.domain === "www.2022.myenergycompare.com.au"
      ) {
        if (tname === "service") {
          fieldsData["service"] = formData[i].value;
        }
        if (tname === "usage") {
          fieldsData["usage"] = formData[i].value;
        }
      }
      //End Section

      //Starts and added on 01 feb 2022  for aus.mysolarcompare.com.au
      if (
        fieldsData.domain === "aus.mysolarcompare.com.au" ||
        fieldsData.domain === "www.aus.mysolarcompare.com.au" ||
        fieldsData.domain === "localsolarquotes.mysolarcompare.com.au" ||
        fieldsData.domain === "www.localsolarquotes.mysolarcompare.com.au" ||
        fieldsData.domain === "v4.mysolarcompare.com.au" ||
        fieldsData.domain === "www.v4.mysolarcompare.com.au"
      ) {
        if (tname === "interested_in_solar") {
          fieldsData["interested_in_solar"] = formData[i].value;
        }
        if (tname === "home_owner") {
          fieldsData["home_owner"] = formData[i].value;
        }
        if (tname === "property") {
          fieldsData["property"] = formData[i].value;
        }
        if (tname === "roof_type") {
          fieldsData["roof_type"] = formData[i].value;
        }
        if (tname === "txt_address") {
          fieldsData["txt_address"] = formData[i].value;
        }
        if (tname === "avg_bill") {
          fieldsData["avg_bill"] = formData[i].value;
        }
        if (tname === "time_frame") {
          fieldsData["time_frame"] = formData[i].value;
        }
        if (tname === "quotes") {
          fieldsData["quotes"] = formData[i].value;
        }
      }
      //End Section

      //My Broadband Compare NZ api section for mybroadbandcompare.co.nz
      if (
        fieldsData.domain === "www.mybroadbandcompare.co.nz" ||
        fieldsData.domain === "mybroadbandcompare.co.nz"
      ) {
        if (tname === "broadband_provider") {
          fieldsData["broadband_provider"] = formData[i].value;
        }
        if (tname === "monthly_cost") {
          fieldsData["monthly_cost"] = formData[i].value;
        }
        if (tname === "service_type") {
          fieldsData["service_type"] = formData[i].value;
        }
      }
      //End Section

      // v3.ozhouseprice.com.au api section
      if (
        fieldsData.domain === "v3.ozhouseprice.com.au" ||
        fieldsData.domain === "www.v3.ozhouseprice.com.au"
      ) {
        if (tname === "homeowner") {
          fieldsData["homeowner"] = formData[i].value;
        }
      }
      //end section
      // mypetinsurancecompare.co api section
      if (
        fieldsData.domain === "mypetinsurancecompare.co" ||
        fieldsData.domain === "www.mypetinsurancecompare.co"
      ) {
        if (tname === "petnsurtype") {
          fieldsData["petnsurtype"] = formData[i].value;
        }
        if (tname === "petnsurage") {
          fieldsData["petnsurage"] = formData[i].value;
        }
        if (tname === "landline") {
          fieldsData["landline"] = formData[i].value;
        }
        if (tname === "petname") {
          fieldsData["petname"] = formData[i].value;
        }
      }
      //end section

      if (
        fieldsData.domain === "canadian.quickandeasyhearing.com" ||
        fieldsData.domain === "www.canadian.quickandeasyhearing.com" ||
        fieldsData.domain === "canadian2.quickandeasyhearing.com" ||
        fieldsData.domain === "www.canadian2.quickandeasyhearing.com" ||
        fieldsData.domain === "ca.quickandeasyhearing.com" ||
        fieldsData.domain === "www.ca.quickandeasyhearing.com" ||
        fieldsData.domain === "ca2.quickandeasyhearing.com" ||
        fieldsData.domain === "www.ca2.quickandeasyhearing.com" ||
        fieldsData.domain === "ca1.quickandeasyhearing.com" ||
        fieldsData.domain === "www.ca1.quickandeasyhearing.com" ||
        fieldsData.domain === "ca3.quickandeasyhearing.com" ||
        fieldsData.domain === "www.ca3.quickandeasyhearing.com" ||
        fieldsData.domain === "trycanada.quickandeasyhearing.com" ||
        fieldsData.domain === "www.trycanada.quickandeasyhearing.com"
      ) {
        if (tname === "textzipcode" || tname === "textzipcode") {
          fieldsData["textzipcode"] = formData[i].value;
        }
        //  if (tname === "textstate" || tname === "textstate") {
        //   fieldsData["textstate"] = formData[i].value;
        // }
        // if (tname === "textcity" || tname === "textcity") {
        //   fieldsData["textcity"] = formData[i].value;
        // }
      }

      //LP-648 au2.quickandeasyhearing.com
      if (
        fieldsData.domain === "au2.quickandeasyhearing.com" ||
        fieldsData.domain === "www.au2.quickandeasyhearing.com"
      ) {
        if (tname === "ageAbove" || tname === "ageabove") {
          fieldsData["ageAbove"] = formData[i].value;
        }
        if (tname === "virtuallyInvisible" || tname === "virtuallyinvisible") {
          fieldsData["virtuallyInvisible"] = formData[i].value;
        }
        if (tname === "categorize" || tname === "categorize") {
          fieldsData["categorize"] = formData[i].value;
        }
        if (tname === "haveHearingAids" || tname === "havehearingaids") {
          fieldsData["haveHearingAids"] = formData[i].value;
        }
        if (tname.toLowerCase() === "phone-number") {
          fieldsData["phone_number"] = formData[i].value;
        }
      }
      // end section

      //LP-648 au3.quickandeasyhearing.com
      if (
        fieldsData.domain === "au3.quickandeasyhearing.com" ||
        fieldsData.domain === "www.au3.quickandeasyhearing.com"
      ) {
        if (tname === "age" || tname === "Age") {
          fieldsData["age"] = formData[i].value;
        }

        if (tname.toLowerCase() === "phone-number") {
          fieldsData["phone_number"] = formData[i].value;
        }
      }
      // end section

      // nakedloans.mycarloancompare.com.au
      if (
        fieldsData.domain === "nakedloans.mycarloancompare.com.au" ||
        fieldsData.domain === "www.nakedloans.mycarloancompare.com.au"
      ) {
        if (tname === "purchaseprice") {
          fieldsData["purchaseprice"] = formData[i].value;
        }
        if (tname === "employmentstatus") {
          fieldsData["employmentstatus"] = formData[i].value;
        }
        if (tname === "creditrating") {
          fieldsData["creditrating"] = formData[i].value;
        }
        if (tname === "grossincome") {
          fieldsData["grossincome"] = formData[i].value;
        }
        if (tname === "ispropertyowner") {
          fieldsData["ispropertyowner"] = formData[i].value;
        }
      }
      // end section

      if (
        fieldsData.domain === "energyefficiencyv2.financesolver.co" ||
        fieldsData.domain === "www.energyefficiencyv2.financesolver.co"
        //  ||
        // fieldsData.domain === "energyefficiency.financesolver.co" ||
        // fieldsData.domain === "www.energyefficiency.financesolver.co"
      ) {
        if (tname === "product" || tname === "Product") {
          fieldsData["product"] = formData[i].value;
        }
        if (tname === "address" || tname === "Address") {
          fieldsData["address"] = formData[i].value;
        }
      }

      //comparesolarmarket.mysolarcompare.com.au 30-01-2023
      if (
        comparesolarmarket.includes(fieldsData.domain)
      ) {
        fieldsData[tname] = formData[i].value;
      } else {
        // console.log(tname, 'tname',tsubtype, fieldsData.domain)
        if (["commercial.mysolarcompare.com.au", 
          "solarcleaning.financesolver.co",
          "aus.mysolarcompare.com.au",
          "www.aus.mysolarcompare.com.au",
          "localsolarquotes.mysolarcompare.com.au",
          "www.localsolarquotes.mysolarcompare.com.au",
          "lsq.mysolarcompare.com.au",
          "www.lsq.mysolarcompare.com.au",
          "propertyinvestr.co",
          "www.propertyinvestr.co",
          "batteries.mysolarcompare.com.au",
          "queensland.mysolarcompare.com.au"
        ].includes(fieldsData.domain) && tname === "postcode" && tsubtype !== 'text') {
          fieldsData["address"] = formData[i].value;
          var postcodeArray = formData[i].value.split(", ");
          const postcode = postcodeArray && postcodeArray.length > 1 && postcodeArray[1].split(' ')
          fieldsData["postcode"] = postcode[postcode.length - 1];
        }
        else if (tname === "postcode" && tsubtype !== 'text') {
          var postcodeArray = formData[i].value.split(",");
          fieldsData["postcode"] =
            postcodeArray && postcodeArray.length > 1 && postcodeArray[2].trim();
          fieldsData["state"] =
            postcodeArray && postcodeArray.length > 1 && postcodeArray[1].trim();
          fieldsData["city"] =
            postcodeArray && postcodeArray.length > 1 && postcodeArray[0].trim();
          fieldsData["town_city"] =
            postcodeArray && postcodeArray.length > 1 && postcodeArray[0].trim();
        } else {
          fieldsData[tname] = formData[i].value;
        }
      }

      if (this.state[`has${formData[i].name}Error`] === true) {
        isTrue = false;
        break;
      }
    }

    if (fullName) {
      fieldsData.full_name = fullName.trim(" ");
    } else {
      fieldsData.full_name = fName.trim(" ") + " " + lName.trim(" ");
    }

    localStorage.setItem("full_name", fieldsData.full_name);

    fieldsData.theme_id = theme_id;
    fieldsData.qb_data = JSON.stringify(QBData);
    fieldsData.fName = fName.trim(" ");
    fieldsData.lName = lName.trim(" ");
    fieldsData.ipv4 = this.state.ipv4;
    fieldsData.pn = "fifth-dynamic-form";

    var isIncludes = false;
    try {
      formData &&
        formData.forEach((item) => {
          profanityWords.list.forEach((word) => {
            if (
              item.value !== undefined &&
              word !== undefined && typeof (item.value) === "string" &&
              this.isIncludeProfanityWord(
                word.toLowerCase(),
                item.value.toLowerCase()
              )
            ) {
              isIncludes = true;
            }
          });
        });
    } catch (e) {
      console.log(e);
    }
    if (isTrue) {
      this.redirectToDeclinePageIfNotSatisfiesCondition(
        formData,
        thankyou?.page_url || "/thank-you"
      );
      if (!isIncludes) {
        //this.handlecard();

        localStorage.setItem("email", fieldsData["email"]);
        //  console.log(fieldsData);
        if (
          fieldsData["phone_number"] !== "" &&
          fieldsData["phone_number"] !== undefined &&
          fieldsData["phone_number"] !== null
        ) {
          this.fname = fName;
          this.lname = lName;
          this.validatePhone(fieldsData, this);
        } else {
          siteSurveyContent.section_second_header !== "yes" &&
            this.handleSuccessCallback(false);
        }
      } else {
        localStorage.setItem("email", "");
        window.location.href = thankyou?.page_url || "/thank-you";
      }
    }

    return isTrue;
  };

  handlecard = () => {
    const { siteData } = this.props;
    let siteSurveyContent = Utils.getPageContent(siteData, 6);
    siteSurveyContent.section_second_header === "yes"
      ? setTimeout(() => {
        this.setState({ showOtpPop: true });
      }, [500])
      : this.setState({ isLoading: true });
  };

  redirectToDeclinePageIfNotSatisfiesCondition = (formData, thankyou_url) => {
    localStorage.setItem("quickandeasyFinnace", "NA");
    if (Utils.isquickeasyfinance()) {
      formData.map((item) => {
        if (
          (item.name.toLowerCase() === "age" ||
            item.name.toLowerCase() === "citizen") &&
          item.value.toLowerCase() === "no"
        ) {
          localStorage.setItem(
            "quickandeasyFinnace",
            "Thank you for your enquiry with Quick and Easy Finance. Though unfortunately based on your current circumstances, we are not able to assist you at this point in time" +
            "Why not find out about a quick and easy loans with Ferratum? A cash loan with a decision in only a few minutes! Click here to find out more"
          );

          window.location.href = thankyou_url || "/thank-you";
          return;
        }
      });
    }
  };

  isIncludeProfanityWord = (str, word) => {
    //return new RegExp("\\b" + word + "\\b", "i").test(str);
    return str === word;
  };

  validatePhone = (fieldsData, thiss) => {
    const { siteData } = this.props;
    let siteSurveyContent = Utils.getPageContent(siteData, 6);
    try {
      if (fieldsData.phone_number !== "") {
        fieldsData.phone_number = fieldsData.phone_number
          .replace("+61", "0")
          .replace("+64", "0")
          .replace("+1", "").
          replace("+44", "0");
        var isSuccess = false;
        var baseurl = "";
        if (process.env.WDS_SOCKET_PATH === "release") {
          baseurl = "https://helathinsurancefind.com.au/api/";
        } else {
          baseurl = "http://localhost:8034/api/";
        }
        var config = {
          method: "get",
          url: `${baseurl}/neutrinoapiV3/${fieldsData.phone_number}/${siteData.country}/${siteData.id}`,
        };

        axios(config)
          .then(function (response) {
            isSuccess =
              response && response?.data && response?.data?.message === "true";

            var stateData = thiss.state;

            var statedata =
              stateData &&
              stateData.fieldsData &&
              stateData.fieldsData.data &&
              JSON.parse(stateData.fieldsData.data);

            var phonedata =
              statedata &&
              statedata.filter((item) => {
                return item.subtype === "tel";
              });
            var phonevalidate = phonedata.length > 0 && phonedata[0].name;
            if (isSuccess) {
              thiss.setState({
                isValidate: false,
                isPhoneValid: true,
                [`has${[phonevalidate]}Error`]: false,
              });
              thiss.handlecard();

              siteSurveyContent.section_second_header !== "yes" &&
                thiss.handleSuccessCallback(false);
            }

            if (!isSuccess) {
              if (
                phonevalidate !== undefined &&
                phonevalidate !== null &&
                phonevalidate !== ""
              ) {
                thiss.setState({
                  isPhoneValid: false,
                  isValidate: true,
                  [`has${[phonevalidate]}Error`]: true,
                  isLoading: false,
                });
              }
            }
          })
          .catch(function (error) {
            thiss.handlecard();
            siteSurveyContent.section_second_header !== "yes" &&
              thiss.handleSuccessCallback(false);
          });
      }
    } catch (ex) {
      thiss.handlecard();
      siteSurveyContent.section_second_header !== "yes" &&
        thiss.handleSuccessCallback(false);
    }
  };

  headerTag = (item, index) => (
    <div key={index} style={{ display: "flex" }}>
      <item.subtype>{item.label}</item.subtype>{" "}
    </div>
  );

  buttonTag = (item, index) => {
    const { theme_id, surveyLayout } = this.props;

    return (
      <div
        className={`space-between surveyFormBtnFull`}
        key={index}
        style={{ display: "flex", justifyContent: "center" }}
      >
        <div
          style={{
            display: "flex",
            width: surveyLayout !== "thirdlayout" && "100%",
          }}
          className={
            surveyLayout === "thirdlayout" ? "standard_button_fullwidth" : ""
          }
        >
          <Button
            type={item.subtype}
            name={item.name}
            appearance={
              item.style
                ? theme_id === "4"
                  ? button_appearances.info_fifth
                  : button_appearances[item.style]
                : button_appearances.primary_large
            }
            clickHandler={this.handleSubmit}
            text={item.label}
          />
        </div>
      </div>
    );
  };

  dropDownAutocompleteOnChange = (data, name) => {
    const { fields } = this.state;
    fields[name] = data.value;
    this.setState({ fields, [`has${[name]}Error`]: false });
  };

  DynmicDropDownAutocomplete = (item, index) => {
    const { isValidate, fields } = this.state;

    const { siteData, formtype } = this.props;
    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        className="space-between"
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DynmicDropDownAutocomplete
          validate={isValidate}
          theme_id={theme}
          validateOptions={{
            check: true,
            required: true,
          }}
          type={formtype}
          options={item.values}
          name={item.name}
          label={item.label}
          handleChange={(value) =>
            this.dropDownAutocompleteOnChange(value, item.name)
          }
          description={item.description}
          allowMultiple={item.multiple}
          validationCallback={(res) => {
            this.setState({ [`has${item.name}Error`]: res, isValidate: false });
          }}
          value={fields[item.name] || ""}
          requireValidOption={item.requireValidOption}
          iconName={item.icon}
          placeholder={item.placeholder}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={this.props.activestep}
        />
      </div>
    );
  };

  selectTag = (item, index) => {
    const { theme_id, page_id } = this.props;

    return (
      <div
        className={`space-between ${theme_id === "4" && page_id === "1" ? item.className : ""
          }`}
        key={index}
        style={{ display: "flex" }}
      >
        <SelectBox
          theme_id={theme_id}
          options={item.values}
          name={item.name}
          label={item.label}
          iconName={item.icon}
          fieldAttributes={{
            onChange: this.handleChange.bind(this, item.name, item),
          }}
          description={item.description}
          validateOptions={{
            check: true,
            required: item.required,
          }}
          allowMultiple={item.multiple}
        />
      </div>
    );
  };

  handleChangeRadio = (e, data) => {
    const { fields } = this.state;
    fields[e.target.name] = e.target.value;
    data.isOtherSelected = false;
    data.isOtherText = "";

    var status = e.target.value === "other" ? true : false;
    this.setState({ fields, [`has${[e.target.name]}Error`]: status });
  };

  handleChangeRadioOther = (otherTxt, isSelected, data) => {
    data.isOtherSelected = isSelected;
    data.isOtherText = otherTxt;

    const { fields } = this.state;
    fields[data.name] = "other";

    var status = otherTxt === "" ? true : false;
    this.setState({ fields, [`has${[data.name]}Error`]: status });
  };

  radioboxTag = (item, index) => {
    const { isValidate, fields } = this.state;
    const { theme_id, page_id } = this.props;
    const { siteData } = this.props;

    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        className={`space-between ${theme_id === "4" && page_id === "1" ? item.className : ""
          }`}
        key={index}
        style={{ display: "flex" }}
      >
        <RadioBox
          theme_id={theme_id}
          type={"standard"}
          data={item}
          key={index}
          value={fields[item.name] || ""}
          name={item.name}
          onChangeHandle={(res) => this.handleChangeRadio(res, item)}
          validateOptions={{
            required: item.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          onChangeOther={(res, res1) =>
            this.handleChangeRadioOther(res, res1, item)
          }
          iconName={"icon-date"}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={this.props.activestep}
        />
      </div>
    );
  };

  AutocompleteTag = (item, index) => {
    const { fields, isValidate } = this.state;
    const { theme_id, page_id } = this.props;

    return (
      <div
        className={`space-between ${theme_id === "4" && page_id === "1" ? item.className : ""
          }`}
        key={index}
      >
        <ComboBox
          theme_id={theme_id}
          clearOnEscape={true}
          classname={item.className}
          name={item.name}
          arrList={item.values}
          label={item.label}
          description={item.description}
          handleChange={(value) => this.comboChange(value, item.name, item)}
          handelClickEvent={(value) =>
            this.comboClick(value, item.name, "value")
          }
          validateOptions={{
            check: true,
            required: item.required,
          }}
          validate={isValidate}
          validationCallback={(res) => {
            this.setState({ [`has${item.name}Error`]: res, isValidate: false });
          }}
          value={fields[item.name] || ""}
          placeholder={item.placeholder}
          requireValidOption={item.requireValidOption}
        />{" "}
      </div>
    );
  };

  paragraphTag = (item, index) => (
    <div key={index} className="space-between">
      <item.subtype key={index}>{item.label}</item.subtype>
    </div>
  );

  getCommaSepratedValuesCheckbox = (data) => {
    var selectedValues = "";
    data &&
      data.values &&
      data.values.map((item) => {
        if (item.selected === true || item.selected === "true") {
          selectedValues += "," + item.label;
        }
      });
    return (selectedValues = selectedValues.replace(/^,|,$/g, ""));
  };

  checkboxErrorStatus = (data, selectedValues) => {
    return data.isOtherSelected && data.isOtherText === ""
      ? true
      : data.isOtherSelected && data.isOtherText !== ""
        ? false
        : selectedValues === ""
          ? true
          : false;
  };

  handleChangeCheckbox = (e, data) => {
    const { fields } = this.state;
    var selectedValues = this.getCommaSepratedValuesCheckbox(data);
    fields[e.target.name] = selectedValues;
    var status = this.checkboxErrorStatus(data, selectedValues);

    this.setState({ fields, [`has${[e.target.name]}Error`]: status });
  };

  handleChangeCheckboxOther = (otherTxt, isSelected, data) => {
    data.isOtherSelected = isSelected;
    data.isOtherText = otherTxt;
    var selectedValues = this.getCommaSepratedValuesCheckbox(data);
    var status = this.checkboxErrorStatus(data, selectedValues);

    this.setState({ [`has${[data.name]}Error`]: status });
  };

  checkboxTag = (item, index) => {
    const { fields, isValidate } = this.state;
    const { theme_id, page_id } = this.props;
    const { siteData } = this.props;

    var theme = siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        key={index}
        className={`space-between ${theme_id === "4" && page_id === "1" ? item.className : ""
          }`}
      >
        <CheckBox
          theme_id={theme_id}
          label={item.label}
          className={item.className}
          options={item.values || []}
          name={item.name}
          validateOptions={{
            required: item.required,
          }}
          validate={isValidate}
          toggle={item.toggle}
          inline={item.inline}
          other={item.other}
          validationCallback={(res) =>
            this.setState({ [`has${item.name}Error`]: res, isValidate: false })
          }
          id={"isHelpdesk_id"}
          value={fields[item.name] || ""}
          optionText={""}
          isDisabled={false}
          onChange={(res) => this.handleChangeCheckbox(res, item)}
          onChangeOther={(res, res1) =>
            this.handleChangeCheckboxOther(res, res1, item)
          }
          displayName={"title"}
          description={item.description}
          data={item}
          theme={theme}
          iconName={"icon-money2"}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={this.props.activestep}
        />
      </div>
    );
  };

  handleDateChange = (value, field) => {
    const { fields } = this.state;
    fields[field] = value;
    this.setState({ fields, [`has${[field]}Error`]: false });
  };

  dateTag = (data, index) => {
    const { fields, isValidate } = this.state;
    const { theme_id, page_id, siteData } = this.props;
    var theme = this.props.siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    var maxdate = new Date(
      new Date().getFullYear() + 100,
      new Date().getMonth(),
      new Date().getDate()
    );
    let isdob =
      data.className.includes("dob") ||
      data.className.includes("DOB") ||
      data.className.includes("Dob");
    if (isdob) {
      maxdate = new Date(
        new Date().getFullYear() - 18,
        new Date().getMonth(),
        new Date().getDate()
      );
    }
    return (
      <div
        key={index}
        className={`space-between ${theme_id === "4" && page_id === "1" ? data.className : ""
          }`}
      >
        <DateField
          theme_id={theme_id}
          key={index}
          label={data.label}
          description={data.description}
          name={data.name}
          validateOptions={{
            required: data.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${data.name}Error`]: res, isValidate: false })
          }
          fieldAttributes={{
            placeholderText: data.placeholder,
            //onChange: (date) => this.handleDateChange(date, data.name),
            //selected: fields[data.name] || "",
          }}
          onDateChange={(date) => this.handleDateChange(date, data.name)}
          value={fields[data.name] || ""}
          maxdate={maxdate}
          theme={theme}
          iconName={data.icon}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={this.props.activestep}
        />
      </div>
    );
  };

  fileTag = (data, index) => {
    const { fields, isValidate } = this.state;
    switch (data.subtype) {
      case "file":
        return (
          <div key={index} className="space-between">
            <FileField
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              validateOptions={{
                required: data.required,
              }}
              multiple={data.multiple}
              validate={isValidate}
              validationCallback={(res) =>
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                })
              }
              fieldAttributes={{
                autoComplete: "off",
                placeholder: data.placeholder,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
              }}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  numberFieldTag = (data, index) => {
    const { fields, isValidate } = this.state;
    const { theme_id, page_id, siteData } = this.props;
    var theme = this.props.siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <div
        key={index}
        className={`space-between ${theme_id === "4" && page_id === "1" ? data.className : ""
          }`}
      >
        <NumberField
          theme_id={theme_id}
          key={index}
          label={data.label}
          placeHolder={data.placeholder}
          name={data.name}
          description={data.description}
          // className={data.className}
          min={data.min}
          max={data.max}
          step={data.step}
          theme={theme}
          iconName={data.icon}
          validateOptions={{
            check: true,
            required: data.required,
          }}
          validate={isValidate}
          validationCallback={(res) =>
            this.setState({ [`has${data.name}Error`]: res, isValidate: false })
          }
          fieldAttributes={{
            autoComplete: "off",
            type: "number",
            placeholder: data.placeholder,
            onChange: this.handleChange.bind(this, data.name, data),
            value: fields[data.name] || "",
          }}
          themecolors={themecolors.colors(
            theme,
            siteData.theme,
            siteFormData.page_title
          )}
          activestep={this.props.activestep}
        />
      </div>
    );
  };

  textTag = (data, index) => {
    const {
      EMAIL_REGEX,
      MOBILE_NUMBER,
      PASSWORD_REGEX,
      MOBILE_NUMBER_GENERIC,
      ENGLAND_MOBILE_NUMBER,
      UAE_MOBILE_NUMBER
    } = constants;
    const { fields, isValidate, isPhoneValid } = this.state;
    const { theme_id, page_id, surveyLayout, formtype, siteData, activestep } =
      this.props;
    var theme = siteData.primary_color;
    const surveyPage = Utils.getPageContent(siteData, 6);
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    switch (data.subtype) {
      case "text":
      case "email":
      case "tel":
      case "password":
        return (
          <div
            key={index}
            className={`space-between ${theme_id === "4" && page_id === "1" ? data.className : ""
              }`}
          >
            <InputField
              theme_id={theme_id}
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              description={data.description}
              isPhoneValid={isPhoneValid}
              subtype={data.subtype}
              theme={theme}
              iconName={data.icon}
              validateOptions={{
                check: true,
                reg:
                  data.subtype === "email"
                    ? EMAIL_REGEX
                    : data.subtype === "tel"
                      ? siteData.country !== "AU"
                        ? siteData.country === 'GB'? ENGLAND_MOBILE_NUMBER : 
                        siteData.country === 'UAE' ? UAE_MOBILE_NUMBER :
                        MOBILE_NUMBER_GENERIC
                        : MOBILE_NUMBER
                      : data.subtype === "password"
                        ? PASSWORD_REGEX
                        : "",
                regMsg:
                  data.subtype === "email"
                    ? `Please enter a valid email address`
                    : data.subtype === "tel"
                      ? `Please enter a valid ${siteData.country === "NZ"? "New Zealand": 
                                                siteData.country === "CA"? "Canadian": 
                                                siteData.country === 'GB'? "United Kingdom" :
                                                siteData.country === 'UAE'? "UAE" :
                                                "Australian"
                      } phone number`
                      : data.subtype === "password"
                        ? "Password should have minimum eight characters, at least one uppercase letter, one lowercase letter, one number and one special character"
                        : "",
                required: data.required,
              }}
              validate={isValidate}
              validationCallback={(res) => {
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                });
              }}
              fieldAttributes={{
                autoComplete: "off",
                type: data.subtype === "tel" ? "phone" : data.subtype,
                placeholder: data.placeholder,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
                maxLength: data.maxlength,
              }}
              surveyLayout={surveyLayout || surveyPage?.page_title}
              type={formtype}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData.page_title
              )}
              activestep={this.props.activestep}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  postCodeAutoComplteOnChange = (value, data, field) => {
    let { fields, selectedArr } = this.state;
    fields[field] = value;
    selectedArr = value;
    this.setState({
      fields,
      [`customddl-${field}`]: selectedArr,
      [`has${[field]}Error`]: false,
    });
  };

  comboChange = (value, data, field) => {
    let { fields, selectedArr } = this.state;
    fields[field] = value.value;
    selectedArr = value;
    this.setState({ fields, [`customddl-${field}`]: selectedArr });
  };

  PostZipAutoCompleteTag = (item, index, controltype) => {
    const { isValidate } = this.state;
    const { siteData, formtype } = this.props;
    const selectedArr = this.state[`customddl-${item.name}`];
    var theme = siteData.primary_color;
    var selectedArry =
      selectedArr === undefined || selectedArr === "undefined"
        ? ""
        : selectedArr;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    return (
      <>
        <div
          className="space-between"
          key={index}
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <AutoCompletePostSuburb
            type={formtype}
            options={item.values}
            name1={item.name}
            label={item.label}
            handleChange={(value) =>
              this.postCodeAutoComplteOnChange(value, item, item.name)
            }
            description={item.description}
            validateOptions={{
              check: true,
              required: item.required,
            }}
            allowMultiple={item.multiple}
            validate={isValidate}
            validationCallback={(res) => {
              this.setState({
                [`has${item.name}Error`]: res,
                isValidate: false,
              });
            }}
            value={selectedArry}
            requireValidOption={item.requireValidOption}
            theme_id={theme}
            iconName={item.icon}
            placeholder={item.placeholder}
            controltype={controltype}
            country={siteData.country}
            themecolors={themecolors.colors(
              theme,
              siteData.theme,
              siteFormData.page_title
            )}
            activestep={this.props.activestep}
          />
        </div>
      </>
    );
  };

  DropDownAutocompleteTag = (item, index) => {
    const { isValidate } = this.state;
    const { siteData, formtype } = this.props;

    const selectedArr = this.state[`customddl-${item.name}`];
    var theme = siteData.primary_color;
    var selectedArry =
      selectedArr === undefined
        ? item.values &&
        item.values.filter((inneritem) => {
          return inneritem.selected === true;
        })[0]
        : selectedArr;

    return (
      <div
        className="space-between"
        key={index}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <DropDownAutocomplete
          type={formtype}
          options={item.values}
          name={item.name}
          label={item.label}
          handleChange={(value) => this.comboChange(value, item, item.name)}
          description={item.description}
          validateOptions={{
            check: true,
            required: true,
          }}
          allowMultiple={item.multiple}
          validate={isValidate}
          validationCallback={(res) => {
            this.setState({ [`has${item.name}Error`]: res, isValidate: false });
          }}
          value={selectedArry}
          requireValidOption={item.requireValidOption}
          theme_id={theme}
          iconName={item.icon}
        // theme_id={siteData.theme}
        />
      </div>
    );
  };

  textAreaTag = (data, index) => {
    const { fields, isValidate } = this.state;
    const { theme_id, page_id, siteData } = this.props;
    var theme = this.props.siteData.primary_color;
    let siteFormData = siteData.content_manage_sites.filter((item) => {
      return item.page_id === 6;
    })[0];
    switch (data.subtype) {
      case "textarea":
        return (
          <div
            key={index}
            className={`space-between fullBox ${theme_id === "4" && page_id === "1" ? data.className : ""
              }`}
          >
            <TextArea
              theme_id={theme_id}
              theme={theme}
              key={index}
              label={data.label}
              placeHolder={data.placeholder}
              name={data.name}
              validateOptions={{
                required: data.required,
              }}
              className={data.className}
              iconName={data.icon}
              description={data.description}
              validate={isValidate}
              validationCallback={(res) =>
                this.setState({
                  [`has${data.name}Error`]: res,
                  isValidate: false,
                })
              }
              fieldAttributes={{
                autoComplete: "off",
                maxLength: data.maxlength,
                rows: data.rows,
                onChange: this.handleChange.bind(this, data.name, data),
                value: fields[data.name] || "",
                // maxLength: data.maxlength,
              }}
              themecolors={themecolors.colors(
                theme,
                siteData.theme,
                siteFormData.page_title
              )}
              activestep={this.props.activestep}
            />
          </div>
        );

      default:
        return <></>;
    }
  };

  handleSuccessCallback = (showProgress, otp) => {
    const { leadData } = this.props;
    const { fieldsData } = this.state;
    this.setState({ showOtpPop: false });
    showProgress && this.setState({ isLoading: true });
    if (
      fieldsData.domain === "biz.businessfundstoday.net" ||
      fieldsData.domain === "www.biz.businessfundstoday.net" ||
      fieldsData.domain === "v2.businessfundstoday.net" ||
      fieldsData.domain === "www.v2.businessfundstoday.net" ||
      fieldsData.domain === "businessloan.financesolver.co" ||
      fieldsData.domain === "www.businessloan.financesolver.co" ||
      fieldsData.domain === "native.financesolver.co" ||
      fieldsData.domain === "www.native.financesolver.co" ||
      fieldsData.domain === "bizau.businessfundstoday.net" ||
      fieldsData.domain === "www.bizau.businessfundstoday.net"
    ) {
      fieldsData.lead_description = `${otp}`;
    }
    const queryStringValues = queryString.parse(history.location.search);
    leadData({ ...fieldsData, ...queryStringValues });
  };

  hideDivFunction = () => {

    const rightSideDescription = document.getElementById('right-side-description');
    if (rightSideDescription && window.getComputedStyle(rightSideDescription).display !== 'none') {

      const surveyFirstContent = document.getElementById('surveyFirstContent');
      if (surveyFirstContent) {
        surveyFirstContent.style.display = 'none';
      }
    }
  }

  render() {
    const { data, siteData } = this.props;
    const { isLoading, showOtpPop, fieldsData } = this.state;

    return (
      <>
        {showOtpPop && (
          <Otpcard
            phoneval={fieldsData["phone_number"]}
            country={siteData.country}
            callback={this.handleSuccessCallback}
          />
        )}
        {isLoading && <Card />}
        {['creditrepair.financesolver.co', 'www.creditrepair.financesolver.co', "au.myhealthcompare.com.au", "www.au.myhealthcompare.com.au", "mylifeinsurancecompare.co.nz", "www.mylifeinsurancecompare.co.nz", "keepthebankshonest.com.au", "www.keepthebankshonest.com.au"].includes(Utils.getHost().websiteUrl) && this.hideDivFunction()}
        <div className="dynamic-form">
          {data.map((item, index) => {
            switch (item.type) {
              case "header":
                return this.headerTag(item, index);
              case "text":
                return this.textTag(item, index);
              case "button":
                return this.buttonTag(item, index);
              case "select":
                //  return this.selectTag(item, index);
                return this.DynmicDropDownAutocomplete(item, index);
              case "autocomplete":
                //   return this.AutocompleteTag(item, index);
                //return this.DropDownAutocompleteTag(item, index);
                return this.PostZipAutoCompleteTag(item, index, item.className);
              case "radio-group":
                return this.radioboxTag(item, index);
              case "paragraph":
                return this.paragraphTag(item, index);
              case "checkbox-group":
                return this.checkboxTag(item, index);
              case "date":
                return this.dateTag(item, index);
              case "file":
                return this.fileTag(item, index);
              case "textarea":
                return this.textAreaTag(item, index);
              case "number":
                return this.numberFieldTag(item, index);
              default:
                return <></>;
            }
          })}
        </div>
      </>
    );
  }
}

const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({ isLoading, getSiteDataObject, getSiteStatus, getSiteError });
export default connect(mapStateToProps, { leadData })(FifthLayoutDynamicForm);

FifthLayoutDynamicForm.defaultProp = {
  theme_id: 0,
  QBData: {},
  isFormClicked: false,
  heading: "",
  surveyLayout: "",
  formtype: "",
};
