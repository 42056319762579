import React from "react";
import { object } from "prop-types";
import { Helmet } from "react-helmet";
// import Utils from "../../../../utils/index";
import SmileIcon from "../../../../assets/images/smile-icon.png";

const FirstSectionPage = (props) => {
  const { siteData, mainData } = props;
  // const handleClick = ({ page_url, is_external, target }) => {
  //   Utils.handleClickUrl(page_url, is_external, target);
  // };
 const scrollToTop=()=> {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }
  //const survey_page = Utils.getPageContent(mainData, 6);

  return (
    <>    
    <div className="howItWorks">
      <div className="sectionDivider">
        <div className="divider__left"></div>
        <div className="divider__right"></div>
      </div>
      {mainData &&
        (mainData.primary_color === "fourth-theme" ||
          mainData.primary_color === "fifth-theme") && (
          <div
            className="trustedBrands"
            dangerouslySetInnerHTML={{
              __html: siteData.section_fourth_paragraph_left,
            }}
          ></div>
        )}

      <Helmet>
        <title>{siteData.meta_title}</title>
        <meta name="description" content={siteData.meta_description} />
      </Helmet>
      <section className="howItWorksContainer">
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="heading-section">
                <div className="smileIcon"><img src={SmileIcon} alt="Smile Icon" /></div>
                <h2>{siteData.section_first_header} </h2>
                <p>{siteData.section_first_paragraph}</p>
              </div>
            </div>

            <div className="block-multiples col-sm-12">
              <div className="row">
                <div className="col-sm-3">
                  <div
                    className="single-block"
                    onClick={() => scrollToTop()}
                    style={{ cursor: "pointer" }}
                  >
                    {siteData.list_first_img ? (
                      <>
                      <div className="singleBlockImg">
                        <img
                          alt={siteData.lsi_alt_tex}
                          src={siteData.list_first_img}
                          title={siteData.lsi_alt_tex}
                          className="img-fluid jpgImg"
                        />
                        </div>
                        {/* <img
                          alt={siteData.lsi_alt_tex}
                          src={siteData.list_first_img}
                          title={siteData.lsi_alt_tex}
                          className="img-fluid gifImg"
                        /> */}
                      </>
                    ) : (
                      <></>
                    )}
                    <h4>{siteData.list_first_header}</h4>
                    <p>{siteData.list_first_paragraph}</p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div
                    className="single-block"
                    //onClick={() => handleClick(survey_page)}
                    onClick={() => scrollToTop()}
                    style={{ cursor: "pointer" }}
                  >
                    {siteData.list_second_img ? (
                      <>
                      <div className="singleBlockImg">
                        <img
                          src={siteData.list_second_img}
                          alt={siteData.lsi_alt_tex}
                          title={siteData.lsi_alt_tex}
                          className="img-fluid jpgImg"
                        />
                        </div>
                        {/* <img
                          src={siteData.list_second_img}
                          alt={siteData.lsi_alt_tex}
                          title={siteData.lsi_alt_tex}
                          className="img-fluid gifImg"
                        /> */}
                      </>
                    ) : (
                      <></>
                    )}
                    <h4>{siteData.list_second_header}</h4>
                    <p>{siteData.list_second_paragraph}</p>
                  </div>
                </div>
                <div className="col-sm-3">
                  <div
                    className="single-block"
                    //onClick={() => handleClick(survey_page)}
                    onClick={() => scrollToTop()}
                    style={{ cursor: "pointer" }}
                  >
                    {siteData.list_third_img ? (
                      <>
                      <div className="singleBlockImg">
                        <img
                          src={siteData.list_third_img}
                          alt={siteData.lti_alt_text}
                          title={siteData.lti_alt_text}
                          className="img-fluid jpgImg"
                        />
                        </div>
                        {/* <img
                          src={siteData.list_third_img}
                          alt={siteData.lti_alt_text}
                          title={siteData.lti_alt_text}
                          className="img-fluid gifImg"
                        /> */}
                      </>
                    ) : (
                      <></>
                    )}
                    <h4>{siteData.list_third_header}</h4>
                    <p>{siteData.list_third_paragraph}</p>
                  </div>
                </div>
                {siteData.list_fourth_img?<>
                <div className="col-sm-3">
                  <div
                    className="single-block"
                    //onClick={() => handleClick(survey_page)}
                    onClick={() => scrollToTop()}
                    style={{ cursor: "pointer" }}
                  >
                    {siteData.list_fourth_img ? (
                      <>
                      <div className="singleBlockImg">
                        <img
                          src={siteData.list_fourth_img}
                          alt={siteData.lfori_alt_tex}
                          title={siteData.lfori_alt_tex}
                          className="img-fluid jpgImg"
                        />
                        </div>
                        {/* <img
                          src={siteData.list_fourth_img}
                          alt={siteData.lfori_alt_tex}
                          title={siteData.lfori_alt_tex}
                          className="img-fluid gifImg"
                        /> */}
                      </>
                    ) : (
                      <></>
                    )}
                    <h4>{siteData.list_fourth_header}</h4>
                    <p>{siteData.list_fourth_paragraph}</p>
                  </div>
                </div>
                </>:<></>}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
    </>
  );
};

FirstSectionPage.propTypes = {
  siteData: object,
};

export default FirstSectionPage;
