import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";
import InputField from "../input-field/input-field";
import Error from "../../components/error";
import { checkValidationRule } from "../../utils/validate";
import { string, func, bool, object } from "prop-types";

const useStyles = makeStyles((theme) => ({
  formControl: {
    "&span": { fontSize: "large" },
    backgroundColor: "#fff",
    width: "100%",
    margin: "0",
    padding: "8px",
    borderRadius: "4px",
  },
  root: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "10px",
  },
  rootInherit: {
    marginLeft: "20px",
  },
  formLabelroot: {
    display: "flex",
    paddingLeft: "0px !important",
    alignItems: "center",
    color: "#000 !important",
    fontSize: "14px",
    fontWeight: "bold !important",
  },
  formLabelrootInline: {
    paddingLeft: "0px !important",
    color: "#585555 !important",
    fontSize: "30px",
    padding: " 10px",
    display: "flex",
    fontWeight: "500",
  },
  rootInput: {
    maxWidth: "250px",
    marginLeft: "16px",
  },
  radiohvr: {
    "&:hover": {
      backgroundColor: "#fff",
      color: "white",
    },
  },
  textBackGround: {
    backgroundColor: "#fff",
    color: "white",
  },
  untextBackGround: {
    backgroundColor: "#8cccdb",
    color: "white",
  },
  iconMain: {
    color: `#7a7a7a`,
    marginRight: "10px",
    fontSize: "27px",
  },
  spanMain: {
    marginLeft: "15px",
    marginBottom: "10px",
    borderBottom: "1px solid #7a7a7a",
  },
}));

export default function RadioBox({
  data,
  onChangeHandle,
  onOtherChangeHandle,
  validateOptions,
  validate,
  validationCallback,
  value,
  name,
  type,
  onChangeOther,
  theme_id,
  iconName,
  themecolors,
}) {
  const [isOtherTxtEnable, setOtherTxtEnable] = React.useState(
    data && data.isOtherSelected ? true : false
  );
  const [otherTxt, setField] = React.useState(
    data && data.isOtherText ? data.isOtherText : ""
  );
  const [errors, setError] = React.useState({});
  // const [unselectedRow, unselrow] = React.useState({});

  // const [checkedValue, checkedVal] = React.useState("");

  // const [color, setCol] = useState("");

  // const [test, setTest] = useState(() => {
  // data.values.map((item, index) => {
  //  return item.selected ? checkedVal(item.value) : checkedVal("");
  // });

  // if (theme_id === "default-theme") {
  //   setCol("#19ad91");
  // } else if (theme_id === "first-theme") {
  //   setCol("#405d90");
  // } else if (theme_id === "second-theme") {
  //   setCol("#aba8ff");
  // } else if (theme_id === "third-theme") {
  //   setCol("#4094a9");
  // } else if (theme_id === "sixth-theme") {
  //   setCol("#5C0F8B");
  // }
  // });
  const handleChange = (event) => {
    // unselrow(classes.untextBackGround);
    // checkedVal(event.target.value);
    const isOtherTextSelected = event.target.value === "other";
    setOtherTxtEnable(isOtherTextSelected);
    isOtherTextSelected && setField("");
    onChangeHandle(event);
    setError({});
  };

  const handleOtherChange = (e) => {
    setField(e.target.value);
    onChangeOther(e.target.value, true);
  };

  const validationCallbackChek = (res) => {
    setError(res);
  };

  const { [name]: error = "" } = errors;
  const classes = useStyles({ themecolors });
  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };
    if (checkValidationRule(isVisible)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_CLICK" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const validateFields = (isVisible, e) => {
    const { required } = validateOptions;

    if (required) {
      let errorMsg;

      if ((value === "" || value === "other") && otherTxt === "") {
        errorMsg = `${name} is required`;
      } else {
        errorMsg = "";
      }
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }
    handleErrors("", isVisible, e);
  };

  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
  });

  return (
    <div
      className={(classes.root, classes.radioHover)}
      style={{
        width: "100%",
        marginLeft: "0px",
        borderRadius: "4px",
        border: `solid 1px ${!error ? "#ced4da" : "#db0020"}`,
      }}
    >
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel
          className={
            type === "survey"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
        >
          {data.label}&nbsp;
          {validateOptions.required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {data.description && <Tooltip title={data.description} />}
        </FormLabel>
        <RadioGroup
          aria-label="gender"
          name={name}
          value={value}
          onChange={handleChange}
          className={data.inline ? classes.root : classes.rootInherit}
        >
          {data.values.map((item, index) => {
            return (
              <span className={classes.spanMain}>
                <span
                  className={`demo-icon ${item.icon} ${classes.iconMain}`} //${item.icon}`}
                ></span>
                <span> item.label</span>
                <FormControlLabel
                  key={index}
                  style={{ marginLeft: "20px" }}
                  value={item.value}
                  control={
                    <Radio
                      color="default"
                      style={{ color: "rgb(122, 122, 122)" }}
                    />
                  }
                  labelPlacement="end"
                />
              </span>
            );
          })}
          {data.other && (
            <span>
              <span
                className={`demo-icon  ${classes.iconMain}`} //${item.icon}`}
              ></span>

              <FormControlLabel
                key={"otherkey"}
                value="other"
                control={<Radio color="default" />}
                label="Other"
                labelPlacement="end"
              />
            </span>
          )}
        </RadioGroup>
        {isOtherTxtEnable && (
          <div className={classes.rootInput}>
            <InputField
              className={classes.rootInput}
              label={""}
              placeHolder="Enter text here"
              name={"otherTxt"}
              validateOptions={{
                check: true,
                required: validateOptions.required,
              }}
              validate={validate}
              validationCallback={(res) => validationCallbackChek(res)}
              fieldAttributes={{
                autoComplete: "off",
                type: "text",
                onChange: handleOtherChange.bind(this),
                value: otherTxt,
              }}
              showErrorText={false}
              themecolors={themecolors}
            />
          </div>
        )}
        {!!error && <Error errorMessage={error} />}
      </FormControl>
    </div>
  );
}
RadioBox.propTypes = {
  data: object.isRequired,
  onChangeHandle: func.isRequired,
  validateOptions: object,
  validate: bool,
  validationCallback: func.isRequired,
  value: string,
  name: string,
  type: string,
};

RadioBox.defaultProps = {
  validate: false,
  value: "",
  type: "standard",
  theme_id: 0,
};

export let dataset = [{ name: "", isselected: false }];
