import React from "react";
import { string } from "prop-types";
import Banner from "./banner";
import Navbar from "react-bootstrap/Navbar";
import Utils from "../../../utils";

const HeaderPage = ({ pageType, siteData, page }) => {
  const handleClick = ({ page_url, is_external, target }) => {
    if (page && page !== undefined && (page === 7 || page === "7")) {
      window.location.href = `${page_url}`;
    } else {
      Utils.handleClickUrl(page_url, is_external, target);
    }
  };

  const landing = Utils.getPageContent(siteData, 1);
  const contact_us = Utils.getPageContent(siteData, 3);
  const about_us = Utils.getPageContent(siteData, 2);
  const survey_page = Utils.getPageContent(siteData, 6);
  const hideLogoLink = Utils.hideLogoLink(landing);
  const hideSurveyPage = Utils.hideSurveyPageLink(landing);

  const renderLogo = (siteData) => {
    return siteData && siteData.logo ? (
      <img
        src={siteData.logo}
        className="img-responsive"
        alt={siteData.logo_alt_text}
        title={siteData.logo_alt_text}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
      <section className="header-or-banner clearfix">
        <Navbar
          className="navigation header clearfix"
          collapseOnSelect
          expand="lg"
          bg="ligth"
          variant="dark"
        >
          <div className="container">
            {hideLogoLink ? (
              renderLogo(siteData)
            ) : (
              <Navbar.Brand onClick={() => handleClick({ page_url: "/" })}>
                {renderLogo(siteData)}
              </Navbar.Brand>
            )}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <ul className="navbar-nav ml-auto">
                {about_us ? (
                  <li
                    className={`nav-item ${
                      pageType === "about_us" ? "active" : "inactive"
                    }`}
                  >
                    <span
                      className="nav-link"
                      onClick={() => handleClick(about_us)}
                    >
                      About Us
                    </span>
                  </li>
                ) : (
                  <></>
                )}

                {contact_us ? (
                  <li
                    className={`nav-item ${
                      pageType === "contact_us" ? "active" : "inactive"
                    }`}
                  >
                    <span
                      className="nav-link"
                      onClick={() => handleClick(contact_us)}
                    >
                      Contact Us
                    </span>
                  </li>
                ) : (
                  <></>
                )}

                <li className="nav-item lastbtn">
                  {hideSurveyPage ? (
                    <></>
                  ) : (
                    <span
                      className="nav-link header-button"
                      onClick={() => handleClick(survey_page)}
                      style={{ cursor: "pointer" }}
                    >
                      {landing && landing.section_first_header}
                    </span>
                  )}
                </li>
              </ul>
            </Navbar.Collapse>
          </div>
        </Navbar>
      </section>
      {(page === 1 || page === "1") && <Banner siteData={siteData} />}
    </>
  );
};
HeaderPage.propTypes = {
  pageType: string,
};
HeaderPage.defaultProps = {
  pageType: "home",
};
export default HeaderPage;
