/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Component } from "react";
import LayoutFirstDemo from "../layout-first-demo";
import BetaLayout from "../beta-layout";
// import LayoutSecond from "../layout-second";
import { getSiteData } from "../../actions/manage-site-action-type";
import ContactUsMain from "./contactus";
import { connect } from "react-redux";
import LayoutFourth from "../layout-fourth";
import LayoutSixth from "../layout-sixth";
import LayoutSeventh from "../layout-seventh";
import Utils from "../../utils";
class ContactUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: "first",
      page: 3,
      isPageRefreshed: false,
    };
  }

  componentDidMount() {
    const { getSiteData, getSiteDataObject } = this.props;

    if (getSiteDataObject.length === 0) {
      this.setState({ isPageRefreshed: true });
      getSiteData(this.state.page);
    } else {
      Utils.renderFont(getSiteDataObject);
    }
  }

  render() {
    const { getSiteDataObject } = this.props;
    const { isPageRefreshed, page } = this.state;
    return getSiteDataObject &&
      getSiteDataObject.content_manage_sites &&
      getSiteDataObject.content_manage_sites.length > 0 ? (
      getSiteDataObject.theme === "1" ? (
        <>
          <LayoutFirstDemo
            siteData={getSiteDataObject}
            pageType={"contact_us"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          >
            <ContactUsMain
              siteData={getSiteDataObject}
              layoutType={"1"}
              page={page}
            />
          </LayoutFirstDemo>
        </>
      ) : // : getSiteDataObject.theme === "2" ? (
      //   <LayoutSecond
      //     siteData={getSiteDataObject}
      //     pageType={"content"}
      //     page={page}
      //     isPageRefreshed={isPageRefreshed}
      //   >
      //     <ContactUsMain
      //       siteData={getSiteDataObject}
      //       layoutType={"2"}
      //       page={page}
      //     />
      //   </LayoutSecond>
      // )
      getSiteDataObject.theme === "5" ? (
        <LayoutSixth
          siteData={getSiteDataObject}
          pageType={"content"}
          page={page}
          isPageRefreshed={isPageRefreshed}
        >
          <ContactUsMain
            siteData={getSiteDataObject}
            layoutType={"5"}
            page={page}
          />
        </LayoutSixth>
      ) : getSiteDataObject.theme === "7" ? (
        <LayoutSeventh
          siteData={getSiteDataObject}
          pageType={"content"}
          page={page}
          isPageRefreshed={isPageRefreshed}
        >
          <ContactUsMain
            siteData={getSiteDataObject}
            layoutType={"7"}
            page={page}
          />
        </LayoutSeventh>
      ) : getSiteDataObject.theme === "8" ? (
        <>
          <BetaLayout
            siteData={getSiteDataObject}
            pageType={"contact_us"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          >
            <ContactUsMain
              siteData={getSiteDataObject}
              layoutType={"1"}
              page={page}
            />
          </BetaLayout>
        </>
      ) : (
        <LayoutFourth
          siteData={getSiteDataObject}
          pageType={"contact_us"}
          page={page}
          isPageRefreshed={isPageRefreshed}
        >
          <ContactUsMain
            siteData={getSiteDataObject}
            layoutType={"4"}
            page={page}
          />
        </LayoutFourth>
      )
    ) : (
      <></>
    );
  }
}
const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({ isLoading, getSiteDataObject, getSiteStatus, getSiteError });
export default connect(mapStateToProps, { getSiteData })(ContactUs);
