import React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";
// import LinearProgressBar from "../progress/linear-progress-bar";
// import check from "../../assets/images/check.png";
// import refresh from "../../assets/images/refresh.png";
// import Utils from "../../utils";
import "./card.scss";

export default function Card() {
  // const [collectAnswer, setCollectAnswer] = React.useState(10);
  // const [analyseResult, setAnalyseResult] = React.useState(0);
  // const [qualify, setQualify] = React.useState(0);

  // React.useEffect(() => {
  //   const timer = setInterval(() => {
  //     setCollectAnswer((prevProgress) =>
  //       prevProgress < 100 ? prevProgress + 10 : 100
  //     );

  //     if (collectAnswer >= 100) {
  //       setAnalyseResult((prevProgress) =>
  //         prevProgress < 100 ? prevProgress + 10 : 100
  //       );
  //     }

  //     if (analyseResult >= 100) {
  //       setQualify((prevProgress) =>
  //         prevProgress < 100 ? prevProgress + 10 : 100
  //       );
  //     }
  //   }, 100);
  //   return () => {
  //     clearInterval(timer);
  //   };
  // }, [collectAnswer, analyseResult]);

  return (
    //  Utils.notShowVerifyingProgressBar() ?
    //
    <>
      <div className="base-card progressLoading" style={{ color: "#fff" }}>
          <CircularProgress size={60} color="inherit" />
      </div>
    </>
  );
  //  
  // : (
  //   <div className="base-card">
  //     <div className="ProgressLoader">
  //       <h2>Reviewing your application</h2>
  //       <div className="progressGroup">
  //         <label>Collecting Answers</label>
  //         <div className="progressGroupBar">
  //           <LinearProgressBar variant="determinate" value={collectAnswer} />
  //           <div className="StatusIcons">
  //             <img src={collectAnswer < 100 ? refresh : check} alt=""></img>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="progressGroup">
  //         <label>Analysing results</label>
  //         <div className="progressGroupBar">
  //           <LinearProgressBar variant="determinate" value={analyseResult} />
  //           <div className="StatusIcons">
  //             <img src={analyseResult < 100 ? refresh : check} alt=""></img>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="progressGroup">
  //         <label>Qualifying</label>
  //         <div className="progressGroupBar">
  //           <LinearProgressBar variant="determinate" value={qualify} />
  //           <div className="StatusIcons">
  //             <img src={qualify < 100 ? refresh : check} alt=""></img>
  //           </div>
  //         </div>
  //       </div>
  //       <div className="progressBtnBar">
  //         <span>Processing...</span>
  //       </div>
  //     </div>
  //   </div>
  // );
}
