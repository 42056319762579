import React from "react";
import Header from "./header";
import Footer from "./footer";
import { string, object, number } from "prop-types";
import "./layout-sixth.scss";
import "./survey-form.scss";
import "./blog-sixth.scss";

const LayoutSixth = (props) => {
  const { children, pageType, siteData, page, isPageRefreshed } = props;

  return (
    <div className="sixth_layout_global">
      <div
        className={`layout_sixth-${
          siteData.primary_color &&
          siteData.primary_color !== undefined &&
          siteData.primary_color !== "" &&
          siteData.primary_color !== null
            ? siteData.primary_color
            : "default-theme"
        }`}
      >
        <Header siteData={siteData} pageType={pageType} page={page} />
        <main role="main">{children}</main>
        <Footer
          siteData={siteData}
          pageType={pageType}
          page={page}
          isPageRefreshed={isPageRefreshed}
        />
      </div>
    </div>
  );
};

export default LayoutSixth;

LayoutSixth.propTypes = {
  pageType: string,
  siteData: object,
  page: number,
};

LayoutSixth.defaultProps = {
  pageType: "home",
  page: 1,
};
