import React from "react";
import { Helmet } from "react-helmet";
import { object } from "prop-types";

const LayoutBody = (props) => {
  const { siteData, layoutType } = props;

  let pageContent = siteData.pages.filter(filterByID)[0];
  let siteFormData = siteData.dynamic_forms.filter(filterByID)[0];

  if (pageContent === undefined && pageContent === null) {
    window.location.href = "/";
  }
  function filterByID(item) {
    if (item.page_id === 9 && item.url.toLowerCase() === window.location.pathname.toLowerCase()) {
      return item;
    }
  }
  return (
    <>
      <Helmet>
        <title>{pageContent.title}</title>
        <meta
          name="description"
          content={pageContent.description}
        />
        <link rel="icon" href={siteData.favicon} sizes="16x16" />
        <style>
          {pageContent.external_css}
        </style>
        {pageContent.external_script}
      </Helmet>
      <section className="innerPageContent" dangerouslySetInnerHTML={{
        __html: pageContent.content,
      }}>
      </section>
    </>
  );
};

LayoutBody.propTypes = {
  siteData: object,
};

LayoutBody.defaultProp = {
  layoutType: "1",
};

export default LayoutBody;
