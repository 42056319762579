import React from "react";
import { object } from "prop-types";

const SectionThirdPage = (props) => {
  const { siteData, mainData } = props;

  return (
    <div className="section-third-main">
      {mainData &&
      mainData.primary_color !== "fourth-theme" &&
      mainData.primary_color !== "fifth-theme" ? (
        <section className="sectionone first bgwhite third-sec">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 order-md-2">
                <div className="heading-section">
                  {siteData.section_third_img ? (
                    <img
                      src={siteData.section_third_img}
                      alt={siteData.sti_alt_text}
                      title={siteData.sti_alt_text}
                      className="img-fluid"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className="col-sm-6 order-md-1">
                <div className="heading-section">
                  <h2>{siteData.section_third_header}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: siteData.section_third_paragraph,
                    }}
                  ></p>
                </div>
              </div>
            </div>
            <div className="row">
              {siteData.col_one === "show" ? siteData.col_two : <div></div>}
            </div>
          </div>
        </section>
      ) : (
        <section className="sectionone first bgwhite third-sec">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 order-md-1">
                <div className="heading-section">
                  <h2>{siteData.section_third_header}</h2>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: siteData.section_third_paragraph,
                    }}
                  ></p>
                </div>
              </div>
              <div className="col-sm-6 order-md-2">
                <div className="heading-section">
                  {siteData.section_third_img ? (
                    <img
                      src={siteData.section_third_img}
                      alt={siteData.sti_alt_text}
                      title={siteData.sti_alt_text}
                      className="img-fluid"
                    />
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              {siteData.col_one === "show" ? siteData.col_two : <div></div>}
            </div>
          </div>
        </section>
      )}
    </div>
  );
};

SectionThirdPage.propTypes = {
  siteData: object,
};

export default SectionThirdPage;
