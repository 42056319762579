import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";
import InputField from "../input-field/input-field";
import Error from "../../components/error";
import { checkValidationRule } from "../../utils/validate";
import { string, func, bool, object } from "prop-types";
import clsx from "clsx";

const StyledCheckbox = (props) => {
  const { theme, icon, templatetheme, displayicon, themecolors, id } = props;
  // console.log(typeof templatetheme);
  const classes = useStylesCheckbox({ themecolors });
  return (
    <div
      id={`${id}_div`}
      style={{
        fontSize: `${
          templatetheme === "1" ||
          templatetheme === "2" ||
          templatetheme === 1 ||
          templatetheme === 2
            ? "30px"
            : "25px"
        }`,
        height: `${
          templatetheme === "1" ||
          templatetheme === "2" ||
          templatetheme === 1 ||
          templatetheme === 2
            ? "45px"
            : "38px"
        }`,
        width: `${
          templatetheme === "1" ||
          templatetheme === "2" ||
          templatetheme === 1 ||
          templatetheme === 2
            ? "45px"
            : "38px"
        }`,
        borderRight: "1px solid  rgba(0,0,0,0.2) ",
        display: displayicon === "show" ? "inline-block" : "none",
        marginRight: "10px",
      }}
    >
      <Radio
        id={id}
        className={`${classes.root} ${theme ? classes.rootDefault : ""}`}
        disableRipple
        color="default"
        style={{ display: "none" }}
        checkedIcon={
          <span
            className={clsx(
              classes.icon,
              classes.checkedIcon,
              theme ? [classes.checkedIconDefault, classes.iconDefault] : ""
            )}
          />
        }
        icon={<span className={`${classes.icon}`} />}
        inputProps={{ "aria-label": "decorative checkbox" }}
        {...props}
      />
      <i className={`demo-icon ${icon} ${classes.iconmain}`}></i>
    </div>
  );
};

const useStylesCheckbox = makeStyles({
  root: {
    background: "#BBDBE2",
    marginRight: "5px",
    "&:hover": {
      backgroundColor: "#fff",
    },
  },
  iconMain: {
    color: "white",
    marginRight: "10px",
    fontSize: "22px",
  },
  rootDefault: {
    background: (props) => `${props.themecolors.lightBackgroundColor}`,
  },

  icon: {
    borderRadius: "50%",
    width: 18,
    height: 18,
    boxShadow:
      "inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)",
    //backgroundColor: "#8abbc7",1b283f
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage: "radial-gradient(#F0F0F0,#F0F0F0 28%,transparent 32%)",
      content: '""',
    },
    "$root.Mui-focusVisible &": {
      outline: "2px auto rgba(19,124,189,.6)",
      outlineOffset: 2,
    },
    "input:hover ~ &": {
      backgroundColor: "#ebf1f5",
    },
  },
  iconDefault: {
    backgroundColor: (props) => `${props.themecolors.borderColor}`,
  },

  checkedIcon: {
    backgroundImage:
      "linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))",
    "&:before": {
      display: "block",
      width: 18,
      height: 18,
      backgroundImage: "radial-gradient(#fff,#fff 28%,transparent 32%)",
      content: '""',
    },
  },
  checkedIconDefault: {
    backgroundColor: (props) => `${props.themecolors.onHoverTextColor}`,
    "input:hover ~ &": {
      backgroundColor: (props) => `${props.themecolors.onHoverTextColor}`,
    },
  },
});

const useStyles = makeStyles((theme) => ({
  formControl: {
    "&span": { fontSize: "large" },
    backgroundColor: "#fff",
    width: "100%",
    margin: "0",
    padding: "0px",
    borderRadius: "4px",
  },
  root: {
    backgroundColor: "#fff",
    padding: "6px",
    marginBottom: "4px !important",
    position: "relative",
    borderRadius: "2px",
  },
  secondLayout: {
    paddingLeft: "10px",
  },
  rootInherit: {
    //marginLeft: "20px",
  },
  formLabelroot: {
    display: "flex",
    paddingLeft: "0px !important",
    alignItems: "center",
    color: "#000 !important",
    fontSize: "14px",
    fontWeight: "bold !important",
  },
  formLabelrootInline: {
    paddingLeft: "4px ",
    color: (props) => `${props.themecolors.labelcolor} !important`,
    fontSize: "30px",
    padding: " 10px",
    fontWeight: "500",
    display: "flex",
  },
  rootInput: {
    maxWidth: "250px",
    marginLeft: "16px",
  },
  radiohvr: {
    "&:hover": {
      transform: "scale(1.03)",
    },
  },
  textBackGround: {
    backgroundColor: "#4094a9",
    color: "white",
    borderBottom: ".3px solid black",
  },
  untextBackGround: {
    backgroundColor: "#8cccdb",
    color: "white",
    borderBottom: ".3px solid black",
  },
  checkBoxDesignSelectedSurveySixth: {
    backgroundColor: (props) =>
      `${props.themecolors.onSelectionbgcolor} !important`,
    color: (props) => `${props.themecolors.onHoverTextColor} !important`,
    transition: "0.3s",
    border: (props) => `${props.themecolors.onHoverBorder}`,
  },
  checkBoxDesignUnselectedSurveySixth: {
    backgroundColor: (props) =>
      `${props.themecolors.lightBackgroundColor} !important`,
    color: (props) => `${props.themecolors.hoverOutColor} !important`,
    border: (props) => `${props.themecolors.border}`,
    transition: "0.3s",
    "&:hover": {
      backgroundColor: (props) =>
        `${props.themecolors.onSelectionbgcolor} !important`,
      color: (props) => `${props.themecolors.onHoverTextColor} !important`,
      transition: "0.3s",
      border: (props) => `${props.themecolors.onHoverBorder}`,
      transform: "scale(1.03)",
    },
  },
}));

export default function RadioBox(props) {
  const {
    data,
    onChangeHandle,
    // onOtherChangeHandle,
    validateOptions,
    validate,
    validationCallback,
    value,
    name,
    type,
    onChangeOther,
    theme,
    templatetheme,
    displayicon,
    themecolors,
    activestep,
  } = props;

  const classes = useStyles({ theme, templatetheme, themecolors });
  const [isOtherTxtEnable, setOtherTxtEnable] = React.useState(
    data && data.isOtherSelected ? true : false
  );
  const [otherTxt, setField] = React.useState(
    data && data.isOtherText ? data.isOtherText : ""
  );
  const [errors, setError] = React.useState({});
  // const [unselectedRow, unselrow] = React.useState({});
  const [checkedValue, checkedVal] = React.useState(value);
  const handleChange = (event) => {
    // unselrow(classes.untextBackGround);

    checkedVal(event.target.value);
    const isOtherTextSelected = event.target.value === "other";
    setOtherTxtEnable(isOtherTextSelected);
    isOtherTextSelected && setField("");
    onChangeHandle(event);
    setError({});
  };

  const handleOnClick = (event) => {
    event.preventDefault();
    handleChange(event);
  };

  const handleOtherChange = (e) => {
    setField(e.target.value);
    onChangeOther(e.target.value, true);
  };

  const validationCallbackChek = (res) => {
    setError(res);
  };

  const { [name]: error = "" } = errors;

  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };
    if (checkValidationRule(isVisible)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_CLICK" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const validateFields = (isVisible, e) => {
    const { required } = validateOptions;

    if (required) {
      let errorMsg;

      if ((value === "" || value === "other") && otherTxt === "") {
        errorMsg = `${name} is required`;
      } else {
        errorMsg = "";
      }
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }
    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
    checkedVal(value);
  }, []);

  return (
    <div
      className={(classes.root, classes.radioHover)}
      style={{
        width: "100%",
        marginLeft: "0px",
      }}
    >
      <FormControl component="fieldset" className={classes.formControl}>
        <FormLabel className={classes.formLabelrootInline}>
          {data.label}&nbsp;
          {validateOptions.required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {data.description && <Tooltip title={data.description} />}
        </FormLabel>
        <RadioGroup
          id={`step_${activestep}_${name}`}
          aria-label="gender"
          name={name}
          value={value}
          //onChange={handleChange}
          className={data.inline ? classes.root : classes.rootInherit}
        >
          {data.values.map((item, index) => {
            return (
              <FormControlLabel
                key={index}
                value={item.value}
                control={
                  <StyledCheckbox
                    color="default"
                    theme={theme}
                    icon={displayicon === "show" ? item.icon : ""}
                    templatetheme={templatetheme}
                    displayicon={displayicon}
                    onClick={handleOnClick}
                    themecolors={themecolors}
                    id={`step_${activestep}_${name}_styledchk`}
                  />
                }
                label={
                  <div
                    className={
                      templatetheme === "2" && displayicon === "show"
                        ? `${classes.secondLayout}`
                        : ""
                    }
                    id={`step_${activestep}_${name}_div`}
                  >
                    {item.label}
                  </div>
                }
                labelPlacement="end"
                className={`${classes.root} ${
                  item.value.toLowerCase() === checkedValue.toLowerCase()
                    ? classes.checkBoxDesignSelectedSurveySixth
                    : classes.checkBoxDesignUnselectedSurveySixth
                }`}
                style={{
                  padding: displayicon === "hide" ? "16px" : "",
                }}
                id={`step_${activestep}_${name}_formcontrollabel`}
              />
            );
          })}
          {data.other && (
            <FormControlLabel
              key={"otherkey"}
              value="other"
              control={<Radio color="default" />}
              label="Other"
              labelPlacement="end"
              id={`step_${activestep}_${name}_formcontrollabel`}
            />
          )}
        </RadioGroup>
        {isOtherTxtEnable && (
          <div className={classes.rootInput}>
            <InputField
              className={classes.rootInput}
              label={""}
              placeHolder="Enter text here"
              name={"otherTxt"}
              validateOptions={{
                check: true,
                required: validateOptions.required,
              }}
              validate={validate}
              validationCallback={(res) => validationCallbackChek(res)}
              fieldAttributes={{
                autoComplete: "off",
                type: "text",
                onChange: handleOtherChange.bind(this),
                value: otherTxt,
              }}
              showErrorText={false}
              themecolors={themecolors}
            />
          </div>
        )}
        {!!error && <Error errorMessage={error} />}
      </FormControl>
    </div>
  );
}
RadioBox.propTypes = {
  data: object.isRequired,
  onChangeHandle: func.isRequired,
  validateOptions: object,
  validate: bool,
  validationCallback: func.isRequired,
  value: string,
  name: string,
  type: string,
};

RadioBox.defaultProps = {
  validate: false,
  value: "",
  type: "standard",
};
