import React from "react";
import { object } from "prop-types";
import notqualify from "../../assets/images/notqualify.png";

const DoNotQualifyMain = (props) => {
  //const { siteData } = props;
  const host = window.location.host;

  const backToHome = () => <div className="button-home">
    <a href="/">Back to Homepage</a>
  </div>

  const renderBody = () => {
    switch (host) {
      case "www.businessfundstoday.net":
      case "businessfundstoday.net":
      case "www.cbhs.myhealthcompare.com.au":
      case "cbhs.myhealthcompare.com.au":
      case "biz.businessfundstoday.net":
      case "www.biz.businessfundstoday.net":
        return <>
          <h1>
            {host === "www.cbhs.myhealthcompare.com.au" ||
              host === "cbhs.myhealthcompare.com.au"
              ? "Unfortunately it looks like we may not be able to assist you."
              : "Unfortunately we will be unable to assist you."}
          </h1>
          <p className="subText">
            {host === "www.cbhs.myhealthcompare.com.au" ||
              host === "cbhs.myhealthcompare.com.au"
              ? "This exclusive health fund is restricted and you may not meet the eligibility criteria."
              : "Seems one of the form field dosen't match with our service criteria, please consider filling the form again."}
          </p>
          {backToHome()}

        </>
      case "v2.businessfundstoday.net":
      case "www.v2.businessfundstoday.net":
      case "businessloan.financesolver.co":
      case "www.businessloan.financesolver.co":
      case "native.financesolver.co":
      case "www.native.financesolver.co":
        return <>
          <h1>
            Unfortunately, we can’t match you to a business loan.
          </h1>
          <p className="subText">
            You could qualify for the following personal loans:
          </p>
          <div className="partnerOffer">
            <ul>
              <li>
                <span>
                  You may qualify for a personal loan via society one.
                  Check your rate here:
                </span>
                <a
                  className="offer_button"
                  href="http://tracking.popsplot.com.au/aff_c?offer_id=5985&aff_id=8181&file_id=50177&url_id=26531&aff_sub=;"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>
              </li>
              <li>
                <span>
                  You could qualify for a personal loan with Nifty
                  Personal Loans. Apply Here:
                </span>
                <a
                  className="offer_button"
                  href="https://tracking.popsplot.com.au/aff_c?offer_id=6576&aff_id=8181&file_id=50013&url_id=26116"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>
              </li>
            </ul>
          </div>
          {backToHome()}

        </>
      case "2022.myenergycompare.com.au":
      case "www.2022.myenergycompare.com.au":
      case "gs.myenergycompare.com.au":
      case "www.gs.myenergycompare.com.au":
      case "myenergycompare.com.au":
      case "www.myenergycompare.com.au":
        return <>
          <h1>
            Unfortunately due to current changes in the Energy market,
            we can’t compare in your postcode.
          </h1>
          <p className="subText"></p>
          <div className="partnerOffer">
            <h3>
              We can help you compare your health insurance however!
            </h3>
            <ul>
              <li>
                <span>
                  Why Pay More Than You Need To On Health Insurance?
                  Compare and potential save hundreds by answering a
                  couple of quick questions.
                </span>
                <a
                  className="offer_button"
                  href="https://tracking.popsplot.com.au/aff_c?offer_id=6562&aff_id=8181"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>
              </li>
            </ul>
            <p className="desclaimerText">
              <i>
                This is not financial advice. Please consider your
                financial situation and seek financial advice into the
                best financial decision considering your own unique
                circumstances.
              </i>
            </p>
          </div>
          {backToHome()}

        </>
      case "www.edm.ozhouseprice.com.au":
      case "edm.ozhouseprice.com.au":
      case "aussiesgivingback.com.au":
      case "www.aussiesgivingback.com.au":
        return <>
          <img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
          <h1 className="mt-3">
            Unfortunately we’re unable to assist you with your
            enquiry
          </h1>
          <p className="subText"></p>
          {backToHome()}

        </>
      case "www.v3.ozhouseprice.com.au":
      case "v3.ozhouseprice.com.au":
        return <>
          <img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
          <h1 className="mt-3">Sorry, you do not qualify.</h1>
          <h4>
            {" "}
            You need to be a homeowner to request an appraisal
          </h4>
          <p className="subText"></p>
          {backToHome()}
        </>
      case "www.comparesuper.mysuperannuationcompare.com.au":
      case "comparesuper.mysuperannuationcompare.com.au":
      case "www.superannuation.financesolver.co":
      case "superannuation.financesolver.co":
      case "www.financesolver.co":
      case "financesolver.co":
      case "www.superexperts.financesolver.co":
      case "superexperts.financesolver.co":
        return <>
          <img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
          <h1 className="mt-3">
            {host === "www.financesolver.co" || host === "financesolver.co" ||
              host === "www.superexperts.financesolver.co" || host === "superexperts.financesolver.co"
              ? "Sorry, unfortunately our partners can't help you review your super at this time."
              : "Sorry, unfortunately our service can't help you compare your super at this time."}
          </h1>
          <p className="subText"></p>
        </>
      case "www.1stenergy.myenergycompare.com.au":
      case "1stenergy.myenergycompare.com.au":
        return <>
          <img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
          <h1 className="mt-3">
            Sorry, this offer is only available to Victorian and
            Tasmania residents.
          </h1>

          <p className="subText"></p>
          {backToHome()}

        </>
      case "www.momentum.mylifeinsurancecompare.co.nz":
      case "momentum.mylifeinsurancecompare.co.nz":
        return <>
          <img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
          <h1 className="mt-3">
            Unfortunately we will be unable to assist you.{" "}
          </h1>

          <p className="subText"></p>
          {backToHome()}
        </>
      case "mycarinsurancecompare.co":
      case "www.mycarinsurancecompare.co":
      case "nz.mycarinsurancecompare.co":
      case "www.nz.mycarinsurancecompare.co":
        return <>
          <img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
          <h1 className="mt-3">
            Unfortunately we will be unable to assist you.{" "}
          </h1>

          <p className="subText"></p>
          {/* <div className="button-home">
        <a href="/">Back to Homepage</a>
      </div> */}
        </>
      case "www.mypetinsurancecompare.co":
      case "mypetinsurancecompare.co":
      case "www.nz.mypetinsurancecompare.co":
      case "nz.mypetinsurancecompare.co":
        return <>
          <img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
          <h1
            className="mt-3"
            style={{ fontWeight: "600", color: "#004a8e" }}
          >
            Sorry, there are no plans for your pet at this time.{" "}
          </h1>

          <p className="subText">
            Unfortunately based on the information provided, we’re
            unable to assist. Please consider filling the form again.{" "}
          </p>

          <p
            className="subText"
            style={{ fontWeight: "600", marginBottom: "10px" }}
          >
            The rules for this will be:
          </p>
          <table
            className="table table-bordered tableQualify"
            style={{
              background: "#fff",
              width: "100%",
              maxWidth: "500px",
              margin: "auto",
            }}
          >
            <thead>
              <tr style={{ background: "#ffbc00", color: "#000" }}>
                <th></th>
                <th style={{ fontWeight: "600" }}>petnsurtype</th>
                <th></th>
                <th style={{ fontWeight: "600" }}>petnsurage</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>If</td>
                <td>Cat</td>
                <td>And</td>
                <td>≥ 13</td>
              </tr>

              <tr>
                <td>If</td>
                <td>Dog</td>
                <td>And</td>
                <td>≥ 13</td>
              </tr>

              <tr>
                <td>If</td>
                <td>Horse</td>
                <td>And</td>
                <td>≥ 17</td>
              </tr>

              <tr>
                <td>If</td>
                <td>Rabbit</td>
                <td>And</td>
                <td>≥ 3</td>
              </tr>
            </tbody>
          </table>
        </>
      case "www.au2.quickandeasyhearing.com":
      case "au2.quickandeasyhearing.com":
      case "www.au2.qehearing.com":
      case "au2.qehearing.com":
        return <>
          <img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
          <h1 className="mt-3">
            Unfortunately, we will be unable to assist you.
          </h1>

          <p className="subText"></p>
        </>
      case "aus.mysolarcompare.com.au":
      case "www.aus.mysolarcompare.com.au":
      case "localsolarquotes.mysolarcompare.com.au":
      case "www.localsolarquotes.mysolarcompare.com.au":
      case "v4.mysolarcompare.com.au":
      case "www.v4.mysolarcompare.com.au":
      case "lsq.mysolarcompare.com.au":
      case "www.lsq.mysolarcompare.com.au":
        return <>
          <img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
          <h1 className="mt-3">
            Sorry, the government solar rebate offer is only for homeowners, not renters.
          </h1>

          <p className="subText"></p>
          {backToHome()}
        </>
      case "www.mybroadbandcompare.co.nz":
      case "mybroadbandcompare.co.nz":
        return (
          <>
            <img
              className="mt-5"
              width={"60px"}
              src={notqualify}
              alt="Do Not Qualify"
            />
            <h1 className="mt-3">
              Thank you, we’re unfortunately unable to match you with a current
              offer at this time
            </h1>
            <p className="subText"></p>
            {backToHome()}
          </>
        );
      case "www.energyefficiency.financesolver.co":
      case "energyefficiency.financesolver.co":
        return (
          <>
            <img
              className="mt-5"
              width={"60px"}
              src={notqualify}
              alt="Do Not Qualify"
            />
            <h1 className="mt-3">
              Unfortunately you don't qualify for a free split system upgrade
            </h1>
            <p className="subText"></p>
          </>
        );
      case "www.supportwithdebt.com.au":
      case "supportwithdebt.com.au":
        return (
          <><img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
            <h1>Unfortunately we will be unable to assist you.</h1>
            <p className="subText">
              Consider speaking to a free Financial Counsellor for other
              financial assistance options.
            </p>
          </>
        );
      case "myhomeloancompare.com.au":
      case "www.myhomeloancompare.com.au":
      case "keepthebankshonest.com.au":
      case "www.keepthebankshonest.com.au":
      case "loancaddie.myhomeloancompare.com.au":
      case "www.loancaddie.myhomeloancompare.com.au":
      case "autolife.mycarinsurancecompare.co":
      case "www.autolife.mycarinsurancecompare.co":
      case "propertyinvestr.co":
      case "www.propertyinvestr.co":
      case "mybusinesscarfinance.com.au":
      case "www.mybusinesscarfinance.com.au":
      case "funeral.financesolver.co":
      case "localhost:3000":
        return <>
          <img
            className="mt-5"
            width={"60px"}
            src={notqualify}
            alt="Do Not Qualify"
          />
          <h1 className="mt-3">
            Unfortunately, we will be unable to assist you.
          </h1>

          <p className="subText"></p>
        </>
      case "creditrepair.financesolver.co":
      case "www.creditrepair.financesolver.co":
      case "debt.financesolver.co":
      case "www.debt.financesolver.co":
      case "2022.supportwithdebt.com.au":
      case "www.2022.supportwithdebt.com.au":
      case "mycarloancompare.com.au":
      case "www.mycarloancompare.com.au":
        return <>
          <h1>Unfortunately we will be unable to assist you.</h1>
          <p className="subText">
            Consider speaking to a free Financial Counsellor for other
            financial assistance options.
          </p>
        </>;

      default:
        return <>
          <h1>Unfortunately we will be unable to assist you.</h1>
          <p className="subText">
            Consider speaking to a free Financial Counsellor for other
            financial assistance options.
          </p>
          <div className="partnerOffer">
            <h3>Here are some partner offers:</h3>
            <ul>
              {/* <li>
                <span>
                  Ferratum offers Personal Loans with no credit checks
                  and cash fast. You can borrow up to $1,900.
                </span>
                <a
                  className="offer_button"
                  href="http://tracking.popsplot.com.au/aff_c?offer_id=6364&aff_id=8181&file_id=49360&url_id=24476"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>
              </li>
              <li>
                <span>
                  Jacaranda Finance offers personal loans with cash in
                  your bank in 60 seconds. Borrow between $300-3,000.
                </span>
                <a
                  className="offer_button"
                  href="http://tracking.popsplot.com.au/aff_c?offer_id=6563&aff_id=8181&file_id=49892&url_id=25765"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>
              </li> */}
              <li>
                <span>
                  Health Deal helps people compare and save on Health
                  Insurance. They save people on average over $320 per
                  annum and if you switch you go in the draw to win 6
                  months free health insurance!
                </span>
                <a
                  className="offer_button"
                  href="http://tracking.popsplot.com.au/aff_c?offer_id=6645&aff_id=8181&file_id=49891&url_id=25763"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>
              </li>
              <li>
                <span>
                  Why Pay More Than You Need To On Energy Bills? Compare
                  and potential save hundreds by answering a couple of
                  quick questions.
                </span>
                <a
                  className="offer_button"
                  href="http://tracking.popsplot.com.au/aff_c?offer_id=6607&aff_id=8181&file_id=49881&url_id=25722
              "
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Click here
                </a>
              </li>
            </ul>
            <p className="desclaimerText">
              <i>
                This is not financial advice. Please consider your
                financial situation and seek financial advice into the
                best financial decision considering your own unique
                circumstances.
              </i>
            </p>
          </div>
          <div className="button-home">
            <a href="/">Back to Homepage</a>
          </div>
        </>;
    };
  }


  return (
    <>
      <section className="notQualifySection">
        <div className="container notQualify-container">
          <div className="row">
            <div className="col-sm-12">
              <div className="descript-notQualify">
                {renderBody()}
              </div>
            </div >
          </div >
        </div >
      </section >

    </>
  );
};

DoNotQualifyMain.propTypes = {
  siteData: object,
};

DoNotQualifyMain.defaultProp = {
  layoutType: "1",
};
export default DoNotQualifyMain;
