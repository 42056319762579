import React from "react";
import { object } from "prop-types";
import Emoji2 from "../../../../assets/images/emoji2.svg";

const SectionThirdPage = (props) => {
  const { siteData } = props;

  return (
    <div className="beta-thirdSection">
      <div className="sectionDivider">
        <div className="divider__left"></div>
        <div className="divider__right"></div>
      </div>
      <section className="beta-thirdContainer">
        <div className="container">
          <div className="beta-thirdheader">
            <img src={Emoji2} alt="" />
            <h2>{siteData.section_third_header}</h2>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-6 order-md-2">
              <div className="beta-thirdImgSection">
                {siteData.section_third_img ? (
                  <img
                    src={siteData.section_third_img}
                    alt={siteData.sti_alt_text}
                    title={siteData.sti_alt_text}
                    className="img-fluid"
                  />
                ) : (
                  <></>
                )}
              </div>
            </div>

            <div className="col-sm-6 order-md-1">
              <div className="beta-thirdContentSection">
                <p
                  dangerouslySetInnerHTML={{
                    __html: siteData.section_third_paragraph,
                  }}
                ></p>
              </div>
            </div>
          </div>
          <div className="row">
            {siteData.col_one === "show" ? siteData.col_two : <div></div>}
          </div>
        </div>
      </section>
    </div>
  );
};

SectionThirdPage.propTypes = {
  siteData: object,
};

export default SectionThirdPage;
