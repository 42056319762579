import React from "react";
import { object } from "prop-types";

const SecondSectionPage = (props) => {
  const { siteData } = props;
  const scrollToTop=()=> {
    window.scrollTo({
      top: 0,
      behavior: "smooth"
    });
  }

  return (
    <div className="beta-Section-second">
      <div className="sectionDivider">
        <div className="divider__left"></div>
        <div className="divider__right"></div>
      </div>
      <section className="beta-sectionSecondContainer">
        <div className="container">
          <div
            className="betaSectionContainer"
            style={{
              backgroundColor: siteData && siteData.primary_color,
              backgroundImage: `url(${
                siteData ? siteData.section_first_img : ""
              })`,
            }}
          >
            <div className="heading-section">
              <h2>{siteData.section_second_header}</h2>
              <p>{siteData.section_second_paragraph}</p>
              <button className="homebtn" 
              onClick={() => scrollToTop()}
               style={{ cursor: "pointer" }}>
                {siteData.heading}
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

SecondSectionPage.propTypes = {
  siteData: object,
};

export default SecondSectionPage;
