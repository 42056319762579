import React from "react";
import { object, number } from "prop-types";
import Utils from "../../../utils";
import { Helmet } from "react-helmet";

const HeaderPage = ({ siteData, page, pageType }) => {
  const handleClick = ({ page_url, is_external, target }) => {
    if (page && page != undefined && (page === 7 || page === "7")) {
      window.location.href = `${page_url}`;
    } else {
      Utils.handleClickUrl(page_url, is_external, target);
    }
  };

  let siteDataValue = siteData.content_manage_sites.filter(filterByID)[0];

  if (siteDataValue === undefined || siteDataValue === null) {
    window.location.href = "/page-not-found5";
  }

  function filterByID(item) {
    if (item.page_id === (page === 6 ? 1 : page)) {
      return item;
    }
  }

  const landing = Utils.getPageContent(siteData, 1);
  const contact_us = Utils.getPageContent(siteData, 3);
  const about_us = Utils.getPageContent(siteData, 2);
  const blog = Utils.getPageContent(siteData, 8);
  const survey = Utils.getPageContent(siteData, 8);
  const hideLogoLink = Utils.hideLogoLink(landing);
  const hideSurveyPage = Utils.hideSurveyPageLink(landing);

  const renderLogo = (siteData) => {
    return siteData && siteData.logo ? (
      <img
        src={siteData.logo}
        alt={siteData.logo_alt_text}
        title={siteData.logo_alt_text}
        className="img-fluid"
        style={{ maxHeight: "50px" }}
      />
    ) : (
      <></>
    );
  };

  return (
    <>
        <Helmet><link rel="stylesheet" href='https://use.typekit.net/xpf6yke.css' /></Helmet>
      <header>
        <nav className="navbar navigationHeader">
          <div className="container">
            {/* <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#mainMenu" aria-controls="mainMenu" aria-expanded="false" aria-label="Toggle navigation">
            <span className="navbar-toggler-icon"></span>
          </button>  */}

            {hideLogoLink ? (
              renderLogo(siteData)
            ) : (
              <a
                className="navbar-brand"
                onClick={() => handleClick({ page_url: "/" })}
              >
                {renderLogo(siteData)}
              </a>
            )}

            {/* <a
              className="navbar-brand"
              href="https://www.shopadocket.com.au/"
              target="_blank"
            >
              <img
                src={siteData.logo}
                alt={siteData.logo_alt_text}
                title={siteData.logo_alt_text}
                className="img-fluid"
                style={{ maxHeight: "50px" }}
              /> */}
              {/* <img src="../../../assets/images/logo-shopadocket.png" className="logoImg" />
             <img src="../../../assets/images/shopadocket-white.png" className="logoMobileImg"/>  */}
            {/* </a> */}
            {/* <div className="loginJoin">
              <ul className="navbar-nav ml-auto">
              
                <li className="nav-item joinBtn">
                  <a
                    className="nav-link"
                    href="https://www.shopadocket.com.au/account/sign-up?return-to=/competitions/ipad-giveaway"
                    target="_blank"
                  >
                    Join
                  </a>
                </li>
              </ul>
            </div> */}
          </div>
          {/* <div className="getShopa">
            <div className="container">
               { 
               <div className="collapse navbar-collapse mainMenu" id="mainMenu">
              <ul className="navbar-nav mr-auto navMenu">
                <li className="nav-item">
                  <a className="nav-link" href="#" id="dropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Categories <svg className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv" focusable="false" aria-hidden="true" viewBox="0 0 24 24" data-testid="KeyboardArrowDownIcon"><path d="M7.41 8.59 12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"></path></svg></a>
                  <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                    <ul className="subMenu">
                      <li><a href="#">Featured Offers</a></li>
                      <li><a href="#">Food & Restaurants</a></li>
                      <li><a href="#">Hair & Beauty</a></li>
                      <li><a href="#">Health & Fitness</a></li>
                    </ul>
                    <ul className="subMenu">
                      <li><a href="#">Alcohol & Liquor</a></li>
                      <li><a href="#">Shopping & Retail</a></li>
                      <li><a href="#">Travel & Holidays</a></li>
                      <li><a href="#">Entertainment & Adventure</a></li>
                    </ul>
                    <ul className="subMenu">
                      <li><a href="#">Trades & Services</a></li>
                      <li><a href="#">Tyres & Auto</a></li>
                      <li><a href="#">Gift & Other</a></li>
                    </ul>
                  </div>
                  
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">How it works</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">Help & FAQs</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">Advertise with us</a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="#">Blog</a>
                </li>          
              </ul>                      
            </div>  
               }
              <a
                className="getShopaApp ml-auto"
                href="https://www.shopadocket.com.au/apps"
                target="_blank"
              >
                <svg
                  className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium css-vubbuv"
                  focusable="false"
                  aria-hidden="true"
                  viewBox="0 0 24 24"
                  data-testid="PhoneAndroidIcon"
                >
                  <path d="M16 1H8C6.34 1 5 2.34 5 4v16c0 1.66 1.34 3 3 3h8c1.66 0 3-1.34 3-3V4c0-1.66-1.34-3-3-3zm-2 20h-4v-1h4v1zm3.25-3H6.75V4h10.5v14z"></path>
                </svg>{" "}
                Get Shopadocket App
              </a>
            </div>
          </div> */}
        </nav>
      </header>
    </>
  );
};

HeaderPage.propTypes = {
  siteData: object,
  page: number,
};

HeaderPage.defaultProp = {
  page: 1,
};
export default HeaderPage;
