import React, { useEffect, Fragment } from "react";
import { object } from "prop-types";
import noImage from "../../../../assets/images/no-image.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SurveyFormNew from "../../../survey-form/survey-form-new";
import { v4 as uuidv4 } from "uuid";
import queryString from "query-string";
import history from "../../../../routes/history";
import Utils from "../../../../utils/index";
import Api from "../../../../api";
import "./banner.scss";

const BannerPage = (props) => {
  const { siteData } = props;
  const matches = useMediaQuery("(max-width:600px)");
  const landing = Utils.getPageContent(siteData, 1);
  const dynamicFormData = Utils.getDynamciFormContent(siteData, 6);

  useEffect(() => {
    let queries = queryString.parse(
      history.location.search ? history.location.search.toLowerCase() : ""
    );
    let query = null;
    if (queries && (queries.utm_source || queries.gclid)) {
      query =
        typeof queries === "object" && queries !== null
          ? JSON.stringify(queries)
          : queries.toString();
    }

    const sessionId = uuidv4();
    localStorage.setItem("sessionId", sessionId);
    Api.trackMicrositeSteps(sessionId, 0, "", "", query);
  }, []);

  return (
    <div className={`betaBanner `}>
      <div className="bannerBox" role="listbox">
        {siteData.banners ? (
          siteData.banners.map((item, index) => (
            <Fragment key={index}>
              <div className="container">
                <div className="row align-items-center d-flex flex-row-reverse ">
                  <div className="col-sm-6">
                    {landing && index === 0 && (
                      <>
                        <div className="homeformOutside">
                          <div className="homeForm">
                            <div className="homeFormInside">
                              <SurveyFormNew
                                theme_id={"8"}
                                heading={
                                  dynamicFormData &&
                                  dynamicFormData.form_heading
                                    ? dynamicFormData.form_heading
                                    : ""
                                }
                                siteData={siteData}
                                className="modal-content"
                                data={
                                  dynamicFormData &&
                                  dynamicFormData.data &&
                                  dynamicFormData.data.data
                                    ? dynamicFormData.data.data
                                    : ""
                                }
                                isFromHome={true}
                              />
                            </div>
                          </div>
                          {/* <div className="arrowIconForm">
                                <i className="icon-long-arrow-right"></i>
                              </div> */}
                        </div>
                      </>
                    )}
                  </div>
                  <div className="col-sm-6">
                    <div className="bannerImg">
                      <img src={matches ? item.mob_img : item.img} alt="" />

                      <div
                        className="lead"
                        dangerouslySetInnerHTML={{ __html: item.sub_heading }}
                      ></div>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>
          ))
        ) : (
          <div
            className="carousel-item"
            style={{ backgroundImage: `url(${noImage})` }}
          >
            <div className="carousel-caption d-none d-md-block container">
              <div className="slider-content">
                {/* <h2 className="display-4"> </h2> */}
                <p className="lead"></p>
                <button className="homebtn">{landing.heading}</button>
              </div>
              <div className="silder-image-new"></div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

BannerPage.propTypes = {
  siteData: object,
};

export default BannerPage;
