import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { object, string, func } from "prop-types";
import "./file-field.scss";
import Error from "../../components/error";
import fieldValidation from "../../utils/validate";
import { checkValidationRule } from "../../utils/validate";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";

const FileField = ({
  type,
  name,
  fieldAttributes,
  className,
  label,
  multiple,
  validate,
  validateExecutive,
  placeHolder,
  description,
  validationRule,
  validateOptions,
  validationCallback,
  activestep
}) => {
  const [errors, setError] = useState({});
  const { value } = fieldAttributes;
  const { [name]: error = "" } = errors;
  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };

    if (checkValidationRule(isVisible, validationRule)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_TYPE" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };
  const useStyles = makeStyles((theme) => ({
    formControl: {},
    root: {
      display: "flex",
      flexDirection: "row",
    },
    formLabelroot: {
      display: "flex",
      paddingLeft: "0px !important",
      alignItems: "center",
      color: "#000 !important",
      fontSize: "14px",
      fontWeight: "bold",
    },
    tip: {
      fontSize: "16px !important",
    },
    formLabelrootInline: {
      paddingLeft: "0px !important",
      color: "#585555 !important",
      fontSize: "30px",
      padding: " 10px",
      display: "flex",
      fontWeight: "500",
    },
  }));
  const classes = useStyles();

  const validateFields = (isVisible, e) => {
    const { required } = validateOptions;

    if (required) {
      const errorMsg = fieldValidation.required(value, label);

      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }

    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    } else if (validateExecutive) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
  });

  const hideError = () => {
    setError({ err: "" });
  };

  return (
    <Grid className={`file-uploads`}>
      {!!label && (
        <FormLabel
          className={
            type === "survey"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
        >
          {label}&nbsp;
          {!!description && (
            <Tooltip className={classes.tip} title={description} />
          )}
        </FormLabel>
      )}

      <input
      id={`step_${activestep}_${name}`}
        // id="file"
        type="file"
        {...fieldAttributes}
        multiple={multiple}
        placeholder={placeHolder}
        className={`file-uploads__field ${
          !!error && "file-uploads__field--error"
        } ${type === "survey" ? classes.formInputSize : ""}`}
        onClick={hideError}
      />

      {!!error && <Error errorMessage={error} />}
    </Grid>
  );
};

FileField.propTypes = {
  fieldAttributes: object,
  classname: string,
  label: string.isRequired,
  name: string.isRequired,
  validationCallback: func.isRequired,
};

FileField.defaultProps = {
  fieldAttributes: {},
  classname: "",
  validateOptions: {
    check: false,
  },
};

export default FileField;
