/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Component } from "react";
import { getSiteData } from "../../actions/manage-site-action-type";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import BlogMain from "./blog";
import Utils from "../../utils";
import LoaderComponent from "../common/loader/loader";

class Blog extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: "1",
      page: 8,
      isPageRefreshed: false,
    };
  }

  componentDidMount() {
    const { getSiteData, getSiteDataObject } = this.props;

    if (getSiteDataObject.length === 0) {
      this.setState({ isPageRefreshed: true });
      getSiteData(this.state.page);
    } else {
      Utils.renderFont(getSiteDataObject);
    }
  }

  render() {
    const { isLoading, getSiteDataObject } = this.props;
    const { page, isPageRefreshed } = this.state;
    return getSiteDataObject &&
      getSiteDataObject.content_manage_sites &&
      getSiteDataObject.content_manage_sites.length > 0 ? (
      (getSiteDataObject.theme === "1" || ["www.lsq.mysolarcompare.com.au", "lsq.mysolarcompare.com.au"].includes(Utils.getHost().websiteUrl)) ? (
        <BlogMain
          siteData={getSiteDataObject}
          layoutType={"1"}
          page={page}
          isPageRefreshed={isPageRefreshed}
        />
      ) : // : getSiteDataObject.theme === "2" ? (
        //   <BlogMain
        //     siteData={getSiteDataObject}
        //     layoutType={"2"}
        //     page={page}
        //     isPageRefreshed={isPageRefreshed}
        //   />
        // )
        getSiteDataObject.theme === "5" ? (
          <BlogMain
            siteData={getSiteDataObject}
            layoutType={"5"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          />
        ) : getSiteDataObject.theme === "7" ? (
          <BlogMain
            siteData={getSiteDataObject}
            layoutType={"7"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          />
        ) : getSiteDataObject.theme === "8" ? (
          <BlogMain
            siteData={getSiteDataObject}
            layoutType={"8"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          />
        ) : (
          <></>
        )
    ) : isLoading ? (
      <LoaderComponent />
    ) : (
      <></>
    );
  }
}

const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({ isLoading, getSiteDataObject, getSiteStatus, getSiteError });
export default connect(mapStateToProps, { getSiteData })(Blog);
