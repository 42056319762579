class Storage {
  get(key) {
    const storage = localStorage.getItem(key);

    return JSON.parse(storage || '{}');
  }

  delete(key) {
    this.key = key;

    localStorage.removeItem(this.key);

    return true;
  }

  save(key, value) {
    this.key = key;
    this.value = value;

    localStorage.setItem(key, JSON.stringify(value));

    return true;
  }
}

export default Storage;