import React, { Fragment, useEffect } from "react";
import { object } from "prop-types";
import noImage from "../../../../assets/images/no-image.png";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import SurveyFormNew from "../../../survey-form/survey-form-new";
import Utils from "../../../../utils/index";
import Api from "../../../../api";
import queryString from "query-string";
import history from "../../../../routes/history";
import { v4 as uuidv4 } from "uuid";
import "./banner.scss";

const BannerPage = (props) => {
  const { siteData } = props;
  const matches = useMediaQuery("(max-width:600px)");

  const handleClick = ({ page_url, is_external, target }) => {
    Utils.handleClickUrl(page_url, is_external, target);
  };

  const landing = Utils.getPageContent(siteData, 1);
  const dynamicFormData = Utils.getDynamciFormContent(siteData, 6);
  const survey_page = Utils.getPageContent(siteData, 6);

  const layoutTheme =
    siteData.primary_color &&
    siteData.primary_color !== undefined &&
    siteData.primary_color !== "" &&
    siteData.primary_color !== null &&
    siteData.primary_color;

  const showFomOnHome =
    landing &&
    landing.section_sub_heading &&
    landing.section_sub_heading !== undefined &&
    landing.section_sub_heading !== "" &&
    landing.section_sub_heading !== null &&
    landing.section_sub_heading.toLowerCase() === "show" &&
    layoutTheme !== "fourth-theme" &&
    layoutTheme !== "fifth-theme";

  useEffect(() => {
    if (showFomOnHome) {
      let queries = queryString.parse(
        history.location.search ? history.location.search.toLowerCase() : ""
      );
      let query = null;
      if (queries && (queries.utm_source || queries.gclid)) {
        query =
          typeof queries === "object" && queries !== null
            ? JSON.stringify(queries)
            : queries.toString();
      }

      const sessionId = uuidv4();
      localStorage.setItem("sessionId", sessionId);
      Api.trackMicrositeSteps(sessionId, 0, "", "", query);
    }
  }, []);

  return (
    <div className={`banner-div-section ${showFomOnHome && "bannerNewForm"}`}>
      <div
        id="carouselExampleIndicators"
        className="carousel slide slider-home"
        data-ride="carousel"
      >
        <ol className="carousel-indicators" key="carousel01">
          {siteData.banners &&
            siteData.banners.map((index) =>
              index === 0 ? (
                <li
                  key={index}
                  data-target="#carouselExampleIndicators"
                  data-slide-to={index}
                  className="active"
                ></li>
              ) : (
                <li
                  key={index}
                  data-target="#carouselExampleIndicators"
                  data-slide-to={index}
                ></li>
              )
            )}
        </ol>

        <div className="carousel-inner" role="listbox">
          {siteData.banners ? (
            siteData.banners.map((item, index) => (
              <Fragment key={index}>
                <div
                  key={`inner${index}`}
                  style={{
                    backgroundImage: `url(${
                      matches ? item.mob_img : item.img
                    })`,
                    cursor: "pointer",
                  }}
                  className={`carousel-item ${index === 0 && "active"} `}
                  onClick={() => handleClick(survey_page)}
                >
                  <div className="carousel-caption d-none d-md-block container">
                    <div className="slider-content">
                      <h2
                        className="display-4"
                        style={{ color: `${item.color1}` }}
                      >
                        {item.heading}
                      </h2>
                      <p
                        className="lead"
                        dangerouslySetInnerHTML={{ __html: item.sub_heading }}
                      ></p>
                      <span
                        className="homebtn"
                        onClick={() => handleClick(survey_page)}
                        style={{ cursor: "pointer" }}
                      >
                        {landing.heading}
                      </span>
                    </div>

                    <div className="silder-image-new"></div>
                  </div>
                </div>
                {showFomOnHome && index === 0 && (
                  <>
                    <div className="homeformOutside">
                      <div className="homeForm">
                        <div className="homeFormInside">
                          <SurveyFormNew
                            theme_id={"1"}
                            heading={
                              dynamicFormData && dynamicFormData.form_heading
                                ? dynamicFormData.form_heading
                                : ""
                            }
                            siteData={siteData}
                            className="modal-content"
                            data={
                              dynamicFormData &&
                              dynamicFormData.data &&
                              dynamicFormData.data.data
                                ? dynamicFormData.data.data
                                : ""
                            }
                            isFromHome={true}
                          />
                        </div>
                      </div>
                      <div className="arrowIconForm">
                        <i className="icon-long-arrow-right"></i>
                      </div>
                    </div>
                  </>
                )}
              </Fragment>
            ))
          ) : (
            <div
              className="carousel-item"
              style={{ backgroundImage: `url(${noImage})` }}
            >
              <div className="carousel-caption d-none d-md-block container">
                <div className="slider-content">
                  {/* <h2 className="display-4"></h2> */}
                  <p className="lead"></p>
                  <button className="homebtn">{landing.heading}</button>
                </div>
                <div className="silder-image-new"></div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

BannerPage.propTypes = {
  siteData: object,
};

export default BannerPage;
