import React from "react";
import { string } from "prop-types";
import Banner from "./banner";
import Utils from "../../../utils/index";

const HeaderPage = ({ pageType, siteData, page }) => {
  const handleClick = ({ page_url, is_external, target }) => {
    if (page && page !== undefined && (page === 7 || page === "7")) {
      window.location.href = `${page_url}`;
    } else {
      Utils.handleClickUrl(page_url, is_external, target);
    }
  };

  const landing = Utils.getPageContent(siteData, 1);
  const contact_us = Utils.getPageContent(siteData, 3);
  const about_us = Utils.getPageContent(siteData, 2);
  const blog_page = Utils.getPageContent(siteData, 8);
  const survey_page = Utils.getPageContent(siteData, 6);
  const hideSurveyPage = Utils.hideSurveyPageLink(landing);
  const hideLogoLink = Utils.hideLogoLink(landing);

  const renderLogo = (siteData) => {
    return siteData && siteData.logo ? (
      <img
        src={siteData.logo}
        className="img-fluid-logo"
        alt={siteData.logo_alt_text}
        title={siteData.logo_alt_text}
      />
    ) : (
      <></>
    );
  };

  return (
    <div className="header-main-div">
      <header className="header-section">
        {/* <!-- Navigation --> */}
        <nav
          className="navbar navbar-expand-lg navbar-light bg-light fixed-top"
          style={{ backgroundColor: siteData.primary_color }}
        >
          <div className="container">
            {siteData && siteData.logo ? (
              <>
                {hideLogoLink ? (
                  <span className="navbar-brand">{renderLogo(siteData)}</span>
                ) : (
                  <span
                    className="navbar-brand"
                    onClick={() => handleClick({ page_url: "/" })}
                  >
                    {renderLogo(siteData)}
                  </span>
                )}
              </>
            ) : (
              <></>
            )}

            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                {about_us ? (
                  <li
                    className={`nav-item ${
                      pageType === "about_us" && "active"
                    }`}
                  >
                    <span
                      className="nav-link"
                      onClick={() => handleClick(about_us)}
                    >
                      About Us
                    </span>
                  </li>
                ) : (
                  <></>
                )}

                {contact_us ? (
                  <li
                    className={`nav-item ${
                      pageType === "contact_us" && "active"
                    }`}
                  >
                    <span
                      className="nav-link"
                      onClick={() => handleClick(contact_us)}
                    >
                      Contact Us
                    </span>
                  </li>
                ) : (
                  <></>
                )}

                {blog_page ? (
                  <li className={`nav-item`}>
                    <span
                      className="nav-link"
                      onClick={() => handleClick(blog_page)}
                    >
                      Blog
                    </span>
                  </li>
                ) : (
                  <></>
                )}

                <li className="nav-item lastbtn">
                  {page !== 6 && !hideSurveyPage && (
                    <span
                      className="nav-link"
                      onClick={() => handleClick(survey_page)}
                      style={{ cursor: "pointer" }}
                    >
                      {landing.heading}
                    </span>
                  )}
                </li>
              </ul>
            </div>
          </div>
        </nav>
        {pageType === "home" ? <Banner siteData={siteData} /> : <></>}
      </header>
    </div>
  );
};
HeaderPage.propTypes = {
  pageType: string,
};
HeaderPage.defaultProps = {
  pageType: "home",
};
export default HeaderPage;
