import React from "react";
import "./page-not-found.scss";
import ErrorImage from "../../assets/images/errorPage.jpg";

const PageNotFound = (props) => {
  return (
    <div
      className="errro-not-found"
      style={{ backgroundImage: `url(${ErrorImage})` }}
    >
      Page not found
    </div>
  );
};
export default PageNotFound;
