import React, { useEffect, useState } from "react";
import { Grid } from "@material-ui/core";
import { object, string, func } from "prop-types";
import "./input-field.scss";
import Error from "../../components/error";
import fieldValidation from "../../utils/validate";
import { checkValidationRule } from "../../utils/validate";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";

const InputNumberField = ({
  name,
  fieldAttributes,
  classname,
  label,
  validate,
  validateExecutive,
  placeHolder,
  validationRule,
  validateOptions,
  validationCallback,
  description,
  min,
  max,
  type,
  theme_id,
  theme,
  iconName,
  themecolors,
  activestep
}) => {
  const [errors, setError] = useState({});
  const { value } = fieldAttributes;
  const col = themecolors.onHoverInColor;

  const { [name]: error = "" } = errors;

  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };

    if (checkValidationRule(isVisible, validationRule)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_TYPE" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };
  const useStyles = makeStyles((theme) => ({
    formControl: {},
    root: {
      display: "flex",
      flexDirection: "row",
    },
    formLabelroot: {
      display: "flex",
      paddingLeft: "0px !important",
      alignItems: "center",
      color: "#000 !important",
      fontSize: "14px",
      fontWeight: "400",
    },
    formLabelrootInline: {
      paddingLeft: "0px ",
      color: (props) => `${props.themecolors.labelcolor}`,
      fontSize: "30px",
      padding: " 10px",
      fontWeight: "500",
      display: "flex",
    },
    main: {
      position: "relative",
      padding: "0",
      margin: "0",
      display: "inline-block",
      width: "100%",
    },
    iconMain: {
      position: "absolute",
      paddingLeft: "7px",
      fontSize: "27px",
    },
    inputMain: {
      color: (props) => `${props.themecolors.textColor}`,
      paddingLeft: "45px !important",
    },
    themeLayout: {
      "&:hover": {
        border: (props) => `${props.themecolors.onHoverBorder}`,
      },
      border: "2px solid #c5c5c5 !important",
    },
  }));

  const classes = useStyles({ themecolors });

  const validateFields = (isVisible, e) => {
    const { check, required, reg, regMsg } = validateOptions;
    
    if (!check) {
      return;
    }
    
    if (name==="age") {
      if (parseInt(value) < 1 || parseInt(value) > 100) {
        handleErrors(`value should be between ${1} and ${max}`, isVisible, e);
        return;
      }
    }
    if (required) {
      const errorMsg = fieldValidation.required(value, name);

      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }

    if (reg) {
      const errorMsg = fieldValidation.reg(reg, value, regMsg);

      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }
    if (name === "password") {
      const errorMsg = fieldValidation.checkPassword(value);
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }

    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    } else if (validateExecutive) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
  });
  const [colHovor, SetolHovor] = useState(themecolors.textColor);
  const changeColorHoverIn = () => {
    SetolHovor(col);
  };
  const changeColorHoverOut = () => {
    SetolHovor(themecolors.textColor);
  };
  return (
    <Grid className={`input-box ${!!error && "input-box"}`}>
      {!!label && theme_id !== 3 &&theme_id !=="2" && (
        <FormLabel
          className={
            type === "survey"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
          style={{ display: theme_id === "5" ? "block" : "block" }}
        >
          {label}
          &nbsp;
          {validateOptions.required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {!!description && <Tooltip title={description} />}
        </FormLabel>
      )}
      <div
        onMouseOver={changeColorHoverIn}
        onFocus={changeColorHoverIn}
        // onBlur={changeColorHoverOut}
        onMouseLeave={changeColorHoverOut}
        className={theme_id==="2"?"form-group":`${classes.main}`}
      >
        <i
          className={`demo-icon ${iconName} ${classes.iconMain}`}
          style={{
            color: `${colHovor}`,
            marginTop: `${
              type === "survey" ||
              theme === "eighth-theme" ||
              theme === "ninth-theme"
                ? "10px"
                : "4px"
            }`,
          }}
        ></i>
        <input
        id={`step_${activestep}_${name}`}
          type="number"
          min={min}
          max={max}
          className={theme_id==="2"?"form-control form-control-lg":`${classes.inputMain} input-box__field ${
            type === "survey" && "input-box__field--survey"
          } ${classname} ${
            !!error && type !== "survey" && "input-box__field--error"
          } ${classes.themeLayout}`}
          onBlur={(e) => validateFields("VALIDATE_ON_BLUR", e)}
          
          onKeyUp={(e) => validateFields("VALIDATE_ON_TYPE", e)}
          {...fieldAttributes}
          placeholder={placeHolder}
          required
        />
        {theme_id ==="2"&&<label>{label}<span>*</span></label>}
      </div>
      {!!error && theme_id !== 3 && <Error errorMessage={error} />}
    </Grid>
  );
};

InputNumberField.propTypes = {
  fieldAttributes: object,
  classname: string,
  label: string.isRequired,
  name: string.isRequired,
  validationCallback: func.isRequired,
};

InputNumberField.defaultProps = {
  fieldAttributes: {},
  classname: "",
  validateOptions: {
    check: false,
  },
  theme_id: 0,
};

export default InputNumberField;
