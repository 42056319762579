import React from "react";
import DynamicForm from "../dynamic-form";
import { Helmet } from "react-helmet";
import { object } from "prop-types";
import Utils from "../../utils/index";

const ContactUs = (props) => {
  const { siteData, layoutType } = props;

  let siteDataValue = Utils.getPageContent(siteData, 3);
  let siteFormData = Utils.getDynamciFormContent(siteData, 3);

  if (siteDataValue === undefined && siteDataValue === null) {
    window.location.href = "/";
  }

  return (
    <>
      {layoutType === "8" && (
        <>
          <div
            className="pageTitle"
            style={{ backgroundColor: siteData.primary_color }}
          >
            <Helmet>
              <title>{siteDataValue.meta_title}</title>
              <meta
                name="description"
                content={siteDataValue.meta_description}
              />
              <link rel="icon" href={siteData.favicon} sizes="16x16" />
            </Helmet>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1>{siteDataValue.page_title}</h1>
                  <p>{siteDataValue.page_sub_title}</p>
                </div>
              </div>
            </div>
          </div>

          <section className="innerPageContent">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-1">
                  {siteFormData &&
                  siteFormData.data &&
                  siteFormData.data.data ? (
                    <form id="contact-form" className="contactWrapper1">
                      <div className="form-heading">
                        <h2>{siteDataValue.section_heading}</h2>
                        <div className="messages">
                          {siteDataValue.section_sub_heading}
                        </div>
                      </div>
                      <div className="controls contactControl">
                        <div className="row">
                          <div className="col-md-12">
                            {siteFormData.form_heading && (
                              <h1 className="form-heading-css contactHeading">
                                {siteFormData.form_heading}
                              </h1>
                            )}
                            <div className="contactDynamicForm">
                              <DynamicForm
                                data={siteFormData.data.data}
                                siteData={siteData}
                                page_id={3}
                                theme_id={1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    ""
                  )}
                </div>

                {siteDataValue.content ? (
                  <div className="col-md-4">
                    <div className="form-heading">
                      {/* <h2>Get in Touch</h2> */}
                      <div className="MapLocation"></div>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.content,
                      }}
                    ></div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
        </>
      )}
      {layoutType === "1" && (
        <>
          <div
            className="pageTitle"
            style={{ backgroundColor: siteData.primary_color }}
          >
            <Helmet>
              <title>{siteDataValue.meta_title}</title>
              <meta
                name="description"
                content={siteDataValue.meta_description}
              />
              <link rel="icon" href={siteData.favicon} sizes="16x16" />
            </Helmet>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1>{siteDataValue.page_title}</h1>
                  <p>{siteDataValue.page_sub_title}</p>
                </div>
              </div>
            </div>
          </div>

          <section className="innerPageContent">
            <div className="container">
              <div className="row">
                <div className="col-md-6 offset-md-1">
                  {siteFormData &&
                  siteFormData.data &&
                  siteFormData.data.data ? (
                    <form id="contact-form" className="contactWrapper1">
                      <div className="form-heading">
                        <h2>{siteDataValue.section_heading}</h2>
                        <div className="messages">
                          {siteDataValue.section_sub_heading}
                        </div>
                      </div>
                      <div className="controls contactControl">
                        <div className="row">
                          <div className="col-md-12">
                            {siteFormData.form_heading && (
                              <h1 className="form-heading-css contactHeading">
                                {siteFormData.form_heading}
                              </h1>
                            )}
                            <div className="contactDynamicForm">
                              <DynamicForm
                                data={siteFormData.data.data}
                                siteData={siteData}
                                page_id={3}
                                theme_id={1}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  ) : (
                    ""
                  )}
                </div>

                {siteDataValue.content ? (
                  <div className="col-md-4">
                    <div className="form-heading">
                      {/* <h2>Get in Touch</h2> */}
                      <div className="MapLocation"></div>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.content,
                      }}
                    ></div>
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </section>
        </>
      )}

      {layoutType === "4" && (
        <>
          <section className="bgbluew"></section>
          <Helmet>
            <title>{siteDataValue.meta_title}</title>
            <meta name="description" content={siteDataValue.meta_description} />
            <link rel="icon" href={siteData.favicon} sizes="16x16" />
          </Helmet>
          <section className="innerpage">
            <div className="container">
              <div className="col-sm-12">
                <div className="content-innerpages">
                  <h2>{siteDataValue.page_title}</h2>
                  <div className="bototmmcontent">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.section_second_paragraph,
                      }}
                    ></div>
                  </div>

                  {siteFormData && siteFormData.data && siteFormData.data.data && (
                    <div className="controls">
                      <DynamicForm
                        data={siteFormData.data.data}
                        siteData={siteData}
                        page_id={2}
                        theme_id={4}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </section>
        </>
      )}

      {layoutType === "7" && (
        <>
          <Helmet>
            <title>{siteDataValue.meta_title}</title>
            <meta name="description" content={siteDataValue.meta_description} />
            <link rel="icon" href={siteData.favicon} sizes="16x16" />
          </Helmet>

          <div className="newThemeContactUs">
            <div className="container">
              <div className="row">
                <div className="col-sm-6 contactTitleText">
                  <div className="">
                    <h1>
                      <span>{siteDataValue.page_title}</span>
                      {siteDataValue.page_sub_title}
                    </h1>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.content,
                      }}
                    ></div>
                  </div>
                </div>
                <div className="col-sm-6 contactFormNewTheme">
                  {siteFormData && siteFormData.data && siteFormData.data.data && (
                    <div className="controls">
                      <h2>{siteDataValue.section_heading}</h2>
                      <DynamicForm
                        data={siteFormData.data.data}
                        siteData={siteData}
                        page_id={2}
                        theme_id={7}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {layoutType === "5" && (
        <>
          <div
            className="pageTitle"
            style={{ backgroundColor: siteData.primary_color }}
          >
            <Helmet>
              <title>{siteDataValue.meta_title}</title>
              <meta
                name="description"
                content={siteDataValue.meta_description}
              />
              <link rel="icon" href={siteData.favicon} sizes="16x16" />
            </Helmet>
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <h1>{siteDataValue.page_title}</h1>
                  <p>{siteDataValue.page_sub_title}</p>
                </div>
              </div>
            </div>
          </div>

          <section className="innerPageContent">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 offset-lg-2">
                  {siteFormData &&
                  siteFormData.data &&
                  siteFormData.data.data ? (
                    <form id="contact-form" className="contactWrapper1">
                      <div className="form-heading">
                        <h2>{siteDataValue.section_heading}</h2>
                        <div className="messages">
                          {siteDataValue.section_sub_heading}
                        </div>
                      </div>
                      <div className="controls contactControl">
                        {siteFormData.form_heading && (
                          <h1 className="form-heading-css contactHeading">
                            {siteFormData.form_heading}
                          </h1>
                        )}
                        <div className="contactDynamicForm">
                          <DynamicForm
                            data={siteFormData.data.data}
                            siteData={siteData}
                            page_id={3}
                            theme_id={5}
                          />
                        </div>
                      </div>
                    </form>
                  ) : (
                    ""
                  )}
                </div>

                {siteDataValue.content ? (
                  <div className="col-md-4">
                    <div className="form-heading">
                      {/* <h2>Get in Touch</h2> */}
                      <div className="MapLocation"></div>
                    </div>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: siteDataValue.content,
                      }}
                    ></div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
};

ContactUs.propTypes = {
  siteData: object,
};

ContactUs.defaultProp = {
  layoutType: "1",
};
export default ContactUs;
