import React, { useEffect, useState } from "react";
import TextField from "@material-ui/core/TextField";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { array, func, string, bool } from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Error from "../../components/error";
import fieldValidation from "../../utils/validate";
import { checkValidationRule } from "../../utils/validate";
import Tooltip from "../../views/tool-tip";
import FormLabel from "@material-ui/core/FormLabel";

const useStyles = makeStyles((theme) => ({
  inputRoot: { fontSize: "14px", height: "50px" },
  Autocompeltehvr: {
    "&:hover": {
      backgroundColor: "#4094a9",
      color: "white",
      borderBottom: ".3px solid black",
    },
  },
  inputRootSurvey: { fontSize: "18px", height: "50px" },
  popper: { fontSize: "14px" },

  root: {
    backgroundColor: "#fff",
    width: "100%",
    height: "50px",
    "& label": {
      fontSize: "15px !important",
      fontWeight: "600 !important",
      fontFamily: "inherit !important",
      top: "-4px",
      left: "-8px",
    },
  },
  rootSurvey: {
    backgroundColor: "#fff",
    width: "100%",
    height: "50px",
    "& label": {
      fontSize: "15px !important",
      fontWeight: "600 !important",
      fontFamily: "inherit !important",
      top: "-4px",
      left: "-8px",
    },
  },

  // formLabelroot: {
  //   fontSize: "14px",
  //   fontWeight: "bold"
  // },
  rootInput: {
    maxWidth: "250px",
    marginLeft: "16px",
  },
  formLabelrootInline: {
    paddingLeft: "0px ",
    color: "#585555",
    fontSize: "30px",
    padding: " 10px",
    fontWeight: "500",
    display: "flex",
  },
  formLabelroot: {
    display: "flex",
    paddingLeft: "0px",
    alignItems: "center",
    color: "#000 ",
    fontSize: "14px",
    fontWeight: "bold",
  },
  svg: {
    fill: "red",
  },
}));

export default function ComboBox({
  arrList,
  label,
  handleChange,
  displayName,
  description,
  handelClickEvent,
  // clearOnEscape,
  value,
  name,
  validate,
  validateOptions,
  validationCallback,
  placeholder,
  requireValidOption,
  type,
  theme_id,
}) {
  const [errors, setError] = useState({});
  const [AutoBackGround, AutoBackGrou] = useState(false);
  const { [name]: error = "" } = errors;
  const classes = useStyles(error);

  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };

    if (checkValidationRule(isVisible)) {
      setError({ ...err });
    } else if (
      (isVisible === "VALIDATE_ON_TYPE" || isVisible === "VALIDATE_ON_FOCUS") &&
      e.key !== "Enter"
    ) {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const validateFields = (isVisible, e) => {
    const { check, required } = validateOptions;

    if (!check) {
      return;
    }

    if (required) {
      const errorMsg = fieldValidation.required(value, name);

      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }

    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    //$("*").css("font-family", localStorage.getItem("fontFamily"));
  });

  const handelClick = (value) => {
    AutoBackGrou(true);
    handelClickEvent(value);
  };

  const handleTextChange = (event) => {
    handleChange(event.target.value);
  };

  const handelclose = (event) => {
    requireValidOption && event.target.type === "text" && handleChange("");

    requireValidOption &&
      event.target.type === "text" &&
      validationCallback(true);
  };

  return (
    <div style={{ padding: "0px" }}>
      {theme_id !== 3 && (
        <FormLabel
          className={
            type === "survey"
              ? classes.formLabelrootInline
              : classes.formLabelroot
          }
        >
          {label}&nbsp;
          {validateOptions.required && type !== "survey" && (
            <span className="mendatory">*</span>
          )}
          {description && <Tooltip title={description} />}
        </FormLabel>
      )}

      <Autocomplete
        classes={{
          root: type === "survey" ? classes.rootSurvey : classes.root,
          inputRoot:
            type === "survey" ? classes.inputRootSurvey : classes.inputRoot,
          paper: classes.popper,
        }}
        className={`combo_box_root ${error && "combo_box_root__error"} `}
        style={{
          width: "100%",
          backgroundColor: `${AutoBackGround === true ? "#4094a9" : "white"}`,
          border: `${error && "1px solid #db0020"}`,
          borderRadius: `${error && "4px"}`,
        }}
        id={name}
        freeSolo={!requireValidOption}
        onChange={(event, value) => handelClick(value)}
        onClose={(event) => handelclose(event)}
        options={arrList}
        getOptionLabel={(option) => option[displayName]}
        inputValue={value}
        renderInput={(params) => (
          <TextField
            className={`${value ? "combo_box_select" : "combo_box"} `}
            {...params}
            label={placeholder}
            variant="outlined"
            fullWidth
            name="autocom"
            onChange={handleTextChange}
            style={{ height: "47px" }}
            onBlur={(e) => validateFields("VALIDATE_ON_BLUR", e)}
            onKeyUp={(e) => validateFields("VALIDATE_ON_TYPE", e)}
            onFocus={(e) => validateFields("VALIDATE_ON_FOCUS", e)}
          />
        )}
      />

      {!!error && theme_id !== 3 && <Error errorMessage={error} />}
    </div>
  );
}

ComboBox.propTypes = {
  arrList: array.isRequired,
  label: string,
  handleChange: func.isRequired,
  value: string,
  placeholder: string,
  requireValidOption: bool,
};

ComboBox.defaultProps = {
  arrList: [{ value: "No record Found" }],
  displayName: "value",
  value: "",
  placeholder: "",
  requireValidOption: true,
  theme_id: 0,
};
