import React, { useState, useEffect } from "react";
import axios from "axios";
import qs from "querystring";
import Error from "../../components/error";
import "./card.scss";
import phoneImg from "../../assets/images/mobile-icon-otp.svg";
import arrowNew from "../../assets/images/arrow-new.svg";

const Otpcard = ({ phoneval, country, callback }) => {
  const [otpval, setotpval] = useState(0);
  const [invalid, setinvalid] = useState(false);
  const [resender, setresender] = useState(false);

  const createOtpAndSendSms = () => {
    var otp = Math.floor(1000 + Math.random() * 9000);
    setotpval(otp);
    smsSend(otp);
    if (resender === true) {
      setresender(false);
      otpTimer();
    }
  };

  const otpTimer = () => {
    var timeLeft = 59;
    var elem = document.getElementById("otpTimer");
    var timerId = setInterval(function () {
      if (timeLeft < 0) {
        clearTimeout(timerId);
        document.getElementById("timer").style.display = "none";
        setresender(true);
      } else {
        if (timeLeft < 10) {
          elem.innerHTML = `<div id="timer"> Resend in 00:0${timeLeft} </div>`;
        } else {
          elem.innerHTML = `<div id="timer"> Resend in 00:${timeLeft} </div>`;
        }
        timeLeft--;
      }
    }, 1000);
  };

  const verifyotp = () => {
    var userotpval =
      document.getElementById("otp1").value +
      document.getElementById("otp2").value +
      document.getElementById("otp3").value +
      document.getElementById("otp4").value;
    if (otpval !== 0 && otpval === parseInt(userotpval)) {
      callback(true,otpval);
    } else {
      setinvalid(true);
    }
  };

  const smsSend = (otp) => {
    var body = {};
    body.phone = phoneval;
    body.otp = otp;
    body.country = country;
    var baseurl = "";
    if (process.env.WDS_SOCKET_PATH === "release") {
      baseurl = "https://helathinsurancefind.com.au/api/";
    } else {
      baseurl = "http://localhost:8034/api/";
    }

    axios({
      method: "post",
      url: `${baseurl}verificationsms`,
      data: qs.stringify(body),
      headers: {
        Authorization: "2mjf09.dgmui94.09eu734",
        "content-type": "application/x-www-form-urlencoded;charset=utf-8",
      },
    })
      .then((Response) => {
        console.log("sent otp");
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    createOtpAndSendSms();
    !resender && otpTimer();
  }, []);

  function handlefocus(event, prev, curr, next) {
    var length = document.getElementById(curr).value.length;
    if (length === 1) {
      next !== ""
        ? document.getElementById(next).focus()
        : document.getElementById(curr).focus();
    }

    if (event.key === "Backspace") {
      prev !== ""
        ? document.getElementById(prev).focus()
        : document.getElementById(curr).focus();
    }
  }

  return (
    <div className="base-card">
      <div className="OtpPopup">
        <div className="otpHeader">
          <h2>Verification</h2>
          <img src={phoneImg} className="phoneIcon" alt="phone verification" />
        </div>
        <div className="otpBody">
          <p>
            We have sent you an one time passcode for your entered mobile number
            verification
          </p>
          <label>Please verify code here</label>
          <div className="progressGroupBar">
            <div
              className="StatusIcons"
              style={{ display: "flex", justifyContent: "center" }}
            >
              <input
                id="otp1"
                maxLength={1}
                className="form-control card-Input"
                onKeyUp={(e) => handlefocus(e, "", "otp1", "otp2")}
              />
              <input
                id="otp2"
                maxLength={1}
                className="form-control card-Input"
                onKeyUp={(e) => handlefocus(e, "otp1", "otp2", "otp3")}
              />
              <input
                id="otp3"
                maxLength={1}
                className="form-control card-Input"
                onKeyUp={(e) => handlefocus(e, "otp2", "otp3", "otp4")}
              />
              <input
                id="otp4"
                maxLength={1}
                className="form-control card-Input"
                onKeyUp={(e) => handlefocus(e, "otp3", "otp4", "otp4")}
              />
            </div>
          </div>
          {invalid && <Error errorMessage={"Invalid Otp !!"} />}
        </div>
        <div className="otpBtnBar">
          <button id="verfybtn" className="otpBtn" onClick={verifyotp}>
            <img src={arrowNew} alt="arrow icon" />
          </button>
          <div>
            <div className="otpTimer" id="otpTimer">
            {resender && (
                <button onClick={createOtpAndSendSms}>Resend</button>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Otpcard;
