import React from "react";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import Utils from "../../../utils";
import FifthLayoutSurvey from "../../survey-form/fifth-layout-survey";

const LayoutBodySeventh = (props) => {
  const { siteData } = props;
  const homeContent = Utils.getPageContent(siteData, 1);
  const siteFormData = Utils.getDynamciFormContent(siteData, 6);
  const bannerContent = Utils.getBannerContent(siteData);
  const matches = useMediaQuery("(max-width:600px)");

  if (homeContent === undefined || homeContent === null) {
    window.location.href = "/page-not-found6";
  }

  const hideDiv = () => {
    //SetDisplayDiv(true);
  };
  const hideReview = () => {
    //SetHideReviewDiv(true);
  };

  return (
    <div className="seventhThemeBody" id="seventhsec">
      <section className="seventhTheme_FirstSection">
        <div className="container">
          <div className="homepageForm">
            <div className="homeFormImg">
              <img src={matches ? bannerContent.mob_img : bannerContent.img} alt="" />
              {/* <img src={formImg} /> */}
            </div>
            <div className="homeSurveyForm">
              <div className="homeSurveyFormInside">
                <div className="mainFormSurvey">
                  <div className="titleSurveyHeading">
                    <h1>{bannerContent.heading}</h1>
                    <p
                      dangerouslySetInnerHTML={{
                        __html: bannerContent.sub_heading,
                      }}
                    ></p>
                  </div>
                  {/* Survey Form Section - Start*/}
                  <div className="newLayoutSurvey">
                    {siteFormData &&
                    siteFormData.data &&
                    siteFormData.data.data ? (
                      <FifthLayoutSurvey
                        theme_id={"7"}
                        onClick={hideDiv.bind(this)}
                        hideReview={hideReview.bind(this)}
                        heading={
                          siteFormData && siteFormData.form_heading
                            ? siteFormData.form_heading
                            : ""
                        }
                        siteData={siteData}
                        className="modal-content"
                        data={
                          siteFormData &&
                          siteFormData.data &&
                          siteFormData.data.data
                            ? siteFormData.data.data
                            : ""
                        }
                      />
                    ) : (
                      <div className="noSurvey">No survey found</div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="otherImportantInfo">
        <div className="container">
          <div
            className="otherInfo"
            dangerouslySetInnerHTML={{
              __html: homeContent.section_heading,
            }}
          ></div>
        </div>
      </section>

      <section className="seventhHowItWorks">
        <div className="container">
          <div className="sectionHeadSeventh">
            <h2>{homeContent.section_first_header}</h2>
            <p className="seventhSubHead">
              {homeContent.section_second_paragraph}
            </p>
          </div>
          <div className="row">
            <div className="col-sm-3 howWorksList">
              <img
                width="42px"
                src={homeContent.list_first_img}
                alt={homeContent.lfi_alt_text}
              />
              <h4>{homeContent.list_first_header}</h4>
              <p>{homeContent.list_first_paragraph}</p>
            </div>
            <div className="col-sm-3 howWorksList">
              <img
                width="42px"
                src={homeContent.list_second_img}
                alt={homeContent.lsi_alt_text}
              />
              <h4>{homeContent.list_second_header}</h4>
              <p>{homeContent.list_second_paragraph}</p>
            </div>
            <div className="col-sm-3 howWorksList">
              <img
                width="42px"
                src={homeContent.list_third_img}
                alt={homeContent.lti_alt_text}
              />
              <h4>{homeContent.list_third_header}</h4>
              <p>{homeContent.list_third_paragraph}</p>
            </div>
            <div className="col-sm-3 howWorksList">
              <img
                width="42px"
                src={homeContent.list_fourth_img}
                alt={homeContent.lfi_alt_text}
              />
              <h4>{homeContent.list_fourth_header}</h4>
              <p>{homeContent.list_fourth_paragraph}</p>
            </div>
          </div>
        </div>
      </section>
      <section className="ourCustomersSection">
        <div className="ourCustomersSectionInside">
          <div className="container">
            <div className="sectionHeadSeventh">
              <h2>{homeContent.section_second_header}</h2>
              <p className="seventhSubHead">
                {homeContent.section_second_paragraph}
              </p>
            </div>
            {/* Section Reviews */}
            <div
              className="testimonialsBlock"
              dangerouslySetInnerHTML={{ __html: homeContent.col_one }}
            />
          </div>
        </div>
      </section>

      <section className="benefitSection">
        <div className="benefirSectionInside">
          <div className="container">
            <div className="row">
              <div className="col-sm-6 benefitTextBlock">
                <div className="benefirText">
                  <div className="benefitTextCenter">
                    <h2>{homeContent.section_third_header}</h2>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: homeContent.section_third_paragraph,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className="col-sm-6 benefitSectionImg">
                <img src={homeContent.section_first_img} alt="" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="takeActionSection">
        <div className="container">
          <div className="takeAction">
            <h2>{homeContent.section_fourth_header}</h2>
            <div className="checkEligibilityBtn">
              <a href="#seventhsec">{homeContent.heading}</a>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default LayoutBodySeventh;
