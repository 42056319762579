import React from "react";
const FooterDisclaimer=(siteData)=>{
    
return(
    <>
    {siteData.siteData&&siteData.siteData.redirect_to_article_page?
            <>
            <div className="row">
              <div className="col-sm-12 text-center">
               <div className="footerDisclaimer">
               <p
                dangerouslySetInnerHTML={{
                  __html: siteData.siteData.redirect_to_article_page,
                }}
              ></p>
            </div>
            </div>
              </div>
              </>
              :<></>}
    </>
);
}
export default FooterDisclaimer;