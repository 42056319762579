import React from "react";
import Utils from "../../../utils";
import DynamicForm from "../../dynamic-form";


const LayoutBodySecond = (props) => {
  const { siteData } = props;

  let siteDataValue = siteData.content_manage_sites.filter(filterByID)[0];
  //   let siteFormData = siteData.dynamic_forms.filter(filterByID)[0];
  let siteFormData = Utils.getDynamciFormContent(siteData, 1);
  if (siteDataValue === undefined || siteDataValue === null) {
    window.location.href = "/page-not-found6";
  }
  function filterByID(item) {
    if (item.page_id === 6 || item.page_id === "6") {
      return item;
    }
  }

  return (
    <>
      {siteDataValue ? (
        <div className="bodyContentArea">
          <section className="firstSection">
            <main>
              <section className="competitionBannerSection">
                <div className="container">
                <img
                          // alt={siteData.lsi_alt_tex}
                          src={siteData.header_image}
                          // title={siteData.lsi_alt_tex}
                          className="img-fluid"
                        />
                </div>
              </section>
              <section className="formSection">
                <div className="container">
                  <div className="formBlock">
                    <div className="formBlockContainer">
                      <div className="formHead">
                        <h1>
                          Win $5,000 Cash, Gift Cards, Holidays,{" "}
                          <span>TV’s, Mobile phones and much much more!</span>
                        </h1>
                        <h5>
                          Enter your details below for your chance to WIN!
                        </h5>
                      </div>
                      {/* {console.log("siteFormData", siteFormData)} */}
                      {/* {console.log("siteData", siteData)} */}
                      <DynamicForm
                        data={siteFormData.data.data}
                        siteData={siteData}
                        page_id={1}
                        theme_id={2}
                      />
                      <div className="info_sm" dangerouslySetInnerHTML={{__html:siteData.redirect_to_article_page}}>
                        
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </main>
          </section>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default LayoutBodySecond;
