import React, { useEffect } from "react";
import { Grid } from "@material-ui/core";
import { object, string, bool } from "prop-types";
import Error from "../../components/error/index";
import { makeStyles } from "@material-ui/core/styles";
import FormLabel from "@material-ui/core/FormLabel";
import Tooltip from "../../views/tool-tip";
import { checkValidationRule } from "../../utils/validate";

import $ from "jquery";
import "./select-box.scss";

const useStyles = makeStyles((theme) => ({
  formControl: {},
  root: {
    display: "flex",
    flexDirection: "row",
  },
  formLabelroot: {
    display: "flex",
    paddingLeft: "0px !important",
    alignItems: "center",
    color: "#000 !important",
    fontSize: "14px",
    fontWeight: "bold",
  },
  formLabelrootInline: {
    paddingLeft: "0px !important",
    color: "#585555 !important",
    fontSize: "30px",
    padding: " 10px",
    display: "flex",
    fontWeight: "500",
  },
  formInputSize: {
    fontSize: "large !important",
  },
}));

const SelectBox = ({
  options,
  name,
  showFirstEmpty,
  fieldAttributes,
  label,
  description,
  validateOptions,
  type,
  allowMultiple,
  validate,
  validationCallback,
  value,
  theme_id,
}) => {
  const [errors, setError] = React.useState({});
  const { [name]: error = "" } = errors;

  const classes = useStyles();

  // const handleChange = (event) => {
  //   setError({});
  // };

  const handleErrors = (errorMsg, isVisible, e) => {
    const err = { [name]: errorMsg };
    if (checkValidationRule(isVisible)) {
      setError({ ...err });
    } else if (isVisible === "VALIDATE_ON_CLICK" && e.key !== "Enter") {
      // this added because we want to unset error on type
      setError({ err: "" });
    }

    validationCallback(!!errorMsg);
  };

  const validateFields = (isVisible, e) => {
    const { required } = validateOptions;

    if (required) {
      let errorMsg;

      if (value === "") {
        errorMsg = `${name} is required`;
      } else {
        errorMsg = "";
      }
      if (errorMsg) {
        handleErrors(errorMsg, isVisible, e);

        return;
      }
    }
    handleErrors("", isVisible, e);
  };

  // componentDidupdate
  useEffect(() => {
    if (validate) {
      validateFields("VALIDATE_ON_FORM_SUBMIT");
    }
    $("*").css("font-family", localStorage.getItem("fontFamily"));
  });

  return (
    <>
      <Grid className={`select-box ${!!error && "select-field--error"}`}>
        {!!label && theme_id !== 3 && (
          <FormLabel
            className={
              type === "survey"
                ? classes.formLabelrootInline
                : classes.formLabelroot
            }
          >
            {label}&nbsp;
            {validateOptions.required && type !== "survey" && (
              <span className="mendatory">*</span>
            )}
            {!!description && <Tooltip title={description} />}
          </FormLabel>
        )}
        <select
          multiple={allowMultiple}
          name={name}
          className={`select-box__field 
        ${!!error && "select-box__field--error"} 
        ${
          (theme_id === 3 || theme_id === "3") &&
          allowMultiple === false &&
          "select-box__field--QuestionBlock"
        } 
        ${
          (theme_id === 3 || theme_id === "3") &&
          allowMultiple &&
          "select-box__field--QuestionBlockMultiple"
        }
        ${type === "survey" ? classes.formInputSize : ""}`}
          {...fieldAttributes}
          defaultValue={
            allowMultiple
              ? !!options &&
                options.map((option, index) => option.selected && option.value)
              : options.filter((option, index) => {
                  return option.selected;
                }).length > 0
              ? options.filter((option, index) => {
                  return option.selected;
                })[0].value
              : ""
          }
          // onChange={handleChange}
        >
          {!!showFirstEmpty && <option value="">Select</option>}
          {/* <option value="">-- Please select --</option> */}
          {Array.isArray(options)
            ? // if options is array with id and name selected={option.selected}
              !!options &&
              options.map((option, index) => (
                <option value={option.value} key={index}>
                  {option.label}
                </option>
              ))
            : // if options is object
              !!options &&
              Object.keys(options).map((option, index) => (
                <option value={option} key={index}>
                  {options[option]}
                </option>
              ))}
        </select>

        {!!error && theme_id !== 3 && <Error errorMessage={error} />}
      </Grid>
    </>
  );
};

SelectBox.propTypes = {
  fieldAttributes: object,
  name: string.isRequired,
  allowMultiple: bool,
};

SelectBox.defaultProps = {
  fieldAttributes: {},
  allowMultiple: false,
  theme_id: 0,
};

export default SelectBox;
