/* eslint-disable jsx-a11y/no-redundant-roles */
import React, { Component } from "react";
import LayoutFirstDemo from "../layout-first-demo";
import BetaLayout from "../beta-layout";
// import LayoutSecond from "../layout-second";
import { getSiteData } from "../../actions/manage-site-action-type";
import { connect } from "react-redux";
import CircularProgress from "@material-ui/core/CircularProgress";
import Page from "./page";
import LayoutFourth from "../layout-fourth";
import LayoutSixth from "../layout-sixth";
import LayoutSeventh from "../layout-seventh";
import Utils from "../../utils";
import LoaderComponent from "../common/loader/loader";

class PagesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: "first",
      page: 9,
      isPageRefreshed: false,
    };
  }

  componentDidMount() {
    const { getSiteData, getSiteDataObject } = this.props;

    if (getSiteDataObject.length === 0) {
      this.setState({ isPageRefreshed: true });
      getSiteData(this.state.page);
    } else {
      Utils.renderFont(getSiteDataObject);
    }
  }

  render() {
    const { isPageRefreshed, page } = this.state;
    const { getSiteDataObject, isLoading } = this.props;
    return getSiteDataObject &&
      getSiteDataObject.content_manage_sites &&
      getSiteDataObject.content_manage_sites.length > 0 ? (
      getSiteDataObject.theme === "1" ? (
        <>
          <LayoutFirstDemo
            siteData={getSiteDataObject}
            pageType={"privacy_policy"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          >
            <Page
              siteData={getSiteDataObject}
              layoutType={"1"}
              page={page}
            />
          </LayoutFirstDemo>
        </>
      ) : // : getSiteDataObject.theme === "2" ? (
        //   <LayoutSecond
        //     siteData={getSiteDataObject}
        //     pageType={"privacy_policy"}
        //     page={page}
        //     isPageRefreshed={isPageRefreshed}
        //   >
        //     <PrivacyPolicyMain
        //       siteData={getSiteDataObject}
        //       layoutType={"2"}
        //       page={page}
        //     />
        //   </LayoutSecond>
        // )
        getSiteDataObject.theme === "5" ? (
          <LayoutSixth
            siteData={getSiteDataObject}
            pageType={"privacy_policy"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          >
            <Page
              siteData={getSiteDataObject}
              layoutType={"5"}
              page={page}
            />
          </LayoutSixth>
        ) : getSiteDataObject.theme === "7" ? (
          <LayoutSeventh
            siteData={getSiteDataObject}
            pageType={"privacy_policy"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          >
            {/* <Page
            siteData={getSiteDataObject}
            layoutType={"7"}
            page={page}
          /> */}
          </LayoutSeventh>
        ) : getSiteDataObject.theme === "8" ? (
          <>
            <BetaLayout
              siteData={getSiteDataObject}
              pageType={"privacy_policy"}
              page={page}
              isPageRefreshed={isPageRefreshed}
            >
              {/* <Page
              siteData={getSiteDataObject}
              layoutType={"8"}
              page={page}
            /> */}
            </BetaLayout>
          </>
        ) : (
          <LayoutFourth
            siteData={getSiteDataObject}
            pageType={"privacy_policy"}
            page={page}
            isPageRefreshed={isPageRefreshed}
          >
            {/* <Page
            siteData={getSiteDataObject}
            layoutType={"4"}
            page={page}
          /> */}
          </LayoutFourth>
        )
    ) : isLoading ? (
      <LoaderComponent />
    ) : (
      <></>
    );
  }
}

const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({
  isLoading,
  getSiteDataObject,
  getSiteStatus,
  getSiteError,
});

export default connect(mapStateToProps, { getSiteData })(PagesComponent);
