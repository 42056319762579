import { call, put, takeLatest } from "redux-saga/effects";
import history from "../routes/history";
import { toast } from "react-toastify";
import Api from "../api";
import queryString from "query-string";
import {
  GET_SITE_DATA,
  getSiteDataRequested,
  getSiteDataSuccess,
  getSiteDataFailure,
  leadDataRequested,
  leadDataFailure,
  LEAD_DATA,
  LEAD_DATA_CALLBACK,
} from "../actions/manage-site-action-type";
import Utils from "../utils";

const {
  httpHelper: { getRequest, postRequest },
} = new Utils().getAll();

const host = Utils.getHost();
export function* getManageSiteData({ payload }) {
  const payloadData = {
    headers: {
      Authorization: "c2xkZ21sa21nbG1s",
    },
    url: `${host.apiUrl}manage-client/list/${payload}/${host.websiteUrl}`,
  };

  yield put(getSiteDataRequested());

  try {
    const { data, error } = yield call(getRequest, payloadData);
    if (data && !error) {
      yield put(getSiteDataSuccess(data.response));

      let queries = queryString.parse(
        history.location.search ? history.location.search.toLowerCase() : ""
      );

      if (
        queries &&
        queries?.transaction_id === "102a0deea395c1b6f188a299a288cc"
      ) {
        yield call(forwardTo, "/page-not-found");
      }
      Api.trackImpression(data.response, queries);
    } else {
      yield put(getSiteDataFailure(error));
      yield call(forwardTo, "/page-not-found10");
    }
  } catch (error) { }
}

export function* postLeadData({ payload }) {
  // console.log("dsafhj",payload);
  const phonecheckdata = {
    url: `${host.apiUrl}duplicatephonecheck/${payload.phone_number}/${payload.site_id}`,
  };
  const { data, error } = yield call(getRequest, phonecheckdata);

  // if (["www.2023.mylifeinsurancecompare.co.nz", "2023.mylifeinsurancecompare.co.nz"].includes(payload.domain)) {
  //   var d = sessionStorage.getItem("domain");
  //   if (d) {
  //     payload = {
  //       ...payload,
  //       domain: d
  //     }
  //   }
  // }


  const payloadData = {
    data: payload,
    url: `${host.apiUrl}add-lead`,
  };
  if (data.DuplicatePhone === false && !error) {
    yield put(leadDataRequested());
    try {
      const { data, error } = yield call(postRequest, payloadData);
      if (data && !error) {
        // console.log("response",data.response);
        payloadData.data.createDate = data?.response?.created_at;
        Api.trackImpressionClick(data?.response?.id);
        Api.callApi(payloadData?.data, data?.response?.id);
        const sessionId = localStorage.getItem("sessionId");
        if (sessionId) {
          Api.trackMicrositeSteps(
            sessionId,
            data?.response?.id,
            "submit",
            "conversion",
            ""
          );
        }
      } else {
        yield put(leadDataFailure(error));
        toast.error(
          "Something went wrong, please contact your administrator.",
          {
            position: toast.POSITION.BOTTOM_RIGHT,
          }
        );
      }
    } catch (error) {
      Api.trackError(error, "0");
    }
  } else {
    toast.error(`Survey already submitted with ${payload.phone_number}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    Api.redirectToThankyouPage(payloadData?.data);
  }
}

export function* postLeadDatacallback({ payload }, callback) {
  const phonecheckdata = {
    url: `${host.apiUrl}duplicatephonecheck/${payload.phone_number}/${payload.site_id}`,
  };
  const { data, error } = yield call(getRequest, phonecheckdata);
  const payloadData = {
    data: payload,
    url: `${host.apiUrl}add-lead`,
  };

  if (data.DuplicatePhone === false && !error) {
    try {
      // ShopaDocket leads should not save in microsite database leads tables, they will directly go into databowl (client api)
      if (payload.theme_id === 2) {
        Api.callApi(payloadData?.data, 0);
      } else {
        yield put(leadDataRequested());

        const { data, error } = yield call(postRequest, payloadData);
        if (data && !error) {
          Api.trackImpressionClick(data?.response?.id);
          Api.callApi(payloadData?.data, data?.response?.id);
          const sessionId = localStorage.getItem("sessionId");
          if (sessionId) {
            Api.trackMicrositeSteps(
              sessionId,
              data?.response?.id,
              "submit",
              "conversion",
              ""
            );
          }
        } else {
          yield put(leadDataFailure(error));

          toast.error(
            "Something went wrong, please contact your administrator.",
            {
              position: toast.POSITION.BOTTOM_RIGHT,
            }
          );
        }
      }
    } catch (error) {
      Api.trackError(error, "0");
    }
  } else {
    toast.error(`Survey already submitted with ${payload.phone_number}`, {
      position: toast.POSITION.TOP_CENTER,
    });
    Api.redirectToThankyouPage(payloadData?.data);
  }
}

function forwardTo(location) {
  history.push(location);
}

function* ManageSiteSaga() {
  yield takeLatest(LEAD_DATA, postLeadData);
  yield takeLatest(LEAD_DATA_CALLBACK, postLeadDatacallback);
  yield takeLatest(GET_SITE_DATA, getManageSiteData);
}
export default ManageSiteSaga;
