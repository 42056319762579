import React, { Component } from "react";
import BetaLayout from "../beta-layout";
import { getSiteData } from "../../actions/manage-site-action-type";
import { connect } from "react-redux";
import LayoutFirstDemo from "../layout-first-demo";
import LayoutFourth from "../layout-fourth";
import LayoutSixth from "../layout-sixth";
import LayoutSeventh from "../layout-seventh";
import AdvertiseWithUsMain from "./advertise-with-us";
import Utils from "../../utils";
import LoaderComponent from "../common/loader/loader";

class AdvertiseWithUs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      theme: "3",
      page: 1,
      isPageRefreshed: false,
    };
  }
  componentDidMount() {
    const { getSiteData, getSiteDataObject } = this.props;
    if (getSiteDataObject.length === 0) {
      this.setState({ isPageRefreshed: true });
      getSiteData(this.state.page);
    } else {
      Utils.renderFont(getSiteDataObject);
    }
  }
  render() {
    const { isLoading, getSiteDataObject } = this.props;
    const { isPageRefreshed } = this.state;

    return getSiteDataObject &&
      getSiteDataObject.content_manage_sites &&
      getSiteDataObject.content_manage_sites.length > 0 ? (
      getSiteDataObject.theme === "1" ? (
        <>
          <LayoutFirstDemo
            siteData={getSiteDataObject}
            pageType={"thankyou"}
            page={7}
            isPageRefreshed={isPageRefreshed}
          >
            <AdvertiseWithUsMain
              siteData={getSiteDataObject}
              layoutType={getSiteDataObject.theme}
            />
          </LayoutFirstDemo>
        </>
      ) : getSiteDataObject.theme === "5" ? (
        <LayoutSixth
          siteData={getSiteDataObject}
          pageType={"thankyou"}
          isPageRefreshed={isPageRefreshed}
          page={7}
        >
          <AdvertiseWithUsMain
            siteData={getSiteDataObject}
            layoutType={getSiteDataObject.theme}
          />
        </LayoutSixth>
      ) : getSiteDataObject.theme === "7" ? (
        <LayoutSeventh
          siteData={getSiteDataObject}
          pageType={"thankyou"}
          isPageRefreshed={isPageRefreshed}
          page={7}
        >
          <AdvertiseWithUsMain
            siteData={getSiteDataObject}
            layoutType={getSiteDataObject.theme}
          />
        </LayoutSeventh>
      ) : getSiteDataObject.theme === "8" ? (
        <>
          <BetaLayout
            siteData={getSiteDataObject}
            pageType={"thankyou"}
            page={7}
            isPageRefreshed={isPageRefreshed}
          >
            <AdvertiseWithUsMain
              siteData={getSiteDataObject}
              layoutType={getSiteDataObject.theme}
            />
          </BetaLayout>
        </>
      ) : (
        <LayoutFourth
          siteData={getSiteDataObject}
          pageType={"thankyou"}
          isPageRefreshed={isPageRefreshed}
          page={7}
        >
          <AdvertiseWithUsMain
            siteData={getSiteDataObject}
            layoutType={getSiteDataObject.theme}
          />
        </LayoutFourth>
      )
    ) : isLoading ? (
      <LoaderComponent />
    ) : (
      <></>
    );
  }
}

const mapStateToProps = ({
  ManageSite: { isLoading, getSiteDataObject, getSiteStatus, getSiteError },
}) => ({ isLoading, getSiteDataObject, getSiteStatus, getSiteError });
export default connect(mapStateToProps, { getSiteData })(AdvertiseWithUs);
