import React from "react";
import DynamicForm from "../../dynamic-form";
import Utils from "../../../utils";
import { Fragment } from "react";

const LayoutBodySixth = (props) => {
  const { siteData } = props;
  const homeContent = Utils.getPageContent(siteData, 1);
  const survey_page = Utils.getPageContent(siteData, 6);

  if (homeContent === undefined || homeContent === null) {
    window.location.href = "/page-not-found6";
  }

  let homeFormData = siteData.dynamic_forms.filter((item) => {
    return item.page_id === 1;
  })[0];

  const handleCompareButton = () => {
    Utils.handleClickUrl(
      survey_page?.page_url,
      survey_page?.is_external,
      survey_page?.target
    );
  };

  return (
    <div className="sixthThemeSections">
      <div className="getStartedSection">
        <section className="bannerSection">
          <div className="banner-area">
            <div className="bannerHead">
              <div data-video="5Gv1mqxbePU" className="bannerVideo">
                <div className="bannerVideoBackground">
                  {siteData.banners.map((index) => (
                    <img key={index} src={index.img} alt="" />
                  ))}
                </div>
              </div>
              <div className="bannerVideoContent">
                {siteData.banners.map((index) => (
                  <Fragment key={index}>
                    <h2>{index.heading}</h2>
                    <div className="sub_title">
                      <p
                        id="right-side-description"
                        dangerouslySetInnerHTML={{
                          __html: index.sub_heading,
                        }}
                      ></p>
                    </div>
                  </Fragment>
                ))}
              </div>
            </div>
            <div className="bannerForm">
              <div className="slider_age_step">
                {homeFormData !== null && homeFormData !== undefined ? (
                  <div className="contactDynamicForm">
                    <DynamicForm
                      data={homeFormData?.data?.data}
                      siteData={siteData}
                      page_id={1}
                      theme_id={5}
                      isFromHome={true}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="submitBtn" onClick={handleCompareButton}>
                  {homeContent.heading}
                </div>
                <div className="sliderAgeText">Takes under 2 minutes</div>
              </div>
            </div>
          </div>
        </section>
        <section className="firstSection">
          <div className="container">
            <div className="row">
              <div className="col-md-12 leftContent">
                <div className="firstHeadingDiv">
                  <h2> {homeContent.section_first_header}</h2>
                  <div className="sub_title">
                    {homeContent.section_first_paragraph}
                  </div>
                </div>
                <div className="boxes">
                  <div className="TimeBoxes">
                    <div className="timeBoxInside">
                      <a href="# ">
                        <img
                          title="my-img"
                          src={homeContent.list_first_img}
                          alt="my-img"
                        />
                        <p>{homeContent.list_first_header}</p>
                        <span>{homeContent.list_first_paragraph}</span>
                      </a>
                    </div>

                    <div className="timeBoxInside">
                      <a href="# ">
                        <img
                          title="my-img"
                          src={homeContent.list_second_img}
                          alt="my-img"
                        />
                        <p>{homeContent.list_second_header}</p>
                        <span>{homeContent.list_second_paragraph}</span>
                      </a>
                    </div>

                    <div className="timeBoxInside">
                      <a href="# ">
                        <img
                          title="my-img"
                          src={homeContent.list_third_img}
                          alt="my-img"
                        />
                        <p>{homeContent.list_third_header}</p>
                        <span>{homeContent.list_third_paragraph}</span>
                      </a>
                    </div>
                  </div>{" "}
                </div>
              </div>
            </div>
          </div>
        </section>

        <section className="secondSection">
          <div className="container">
            <h2>{homeContent.section_second_header}</h2>
            <div
              className="brandsList"
              dangerouslySetInnerHTML={{
                __html: homeContent.section_heading,
              }}
            ></div>
            <br />
            <div className="bannerForm">
              <div className="submitBtn" onClick={handleCompareButton}>
                {homeContent.heading}
              </div>
            </div>
          </div>
        </section>

        <section className="thirdSection">
          <div className="container">
            <h2>
              {homeContent.section_third_header}
              <span>{homeContent.section_fourth_paragraph}</span>
            </h2>

            <div className="bannerForm">
              <div className="SliderStepImage">
                <img src={homeContent.section_third_img} alt="" />
              </div>
              <div className="slider_age_step">
                {homeFormData !== null && homeFormData !== undefined ? (
                  <div className="contactDynamicForm">
                    <DynamicForm
                      data={homeFormData?.data?.data}
                      siteData={siteData}
                      page_id={1}
                      theme_id={5}
                    />
                  </div>
                ) : (
                  ""
                )}
                <div className="submitBtn" onClick={handleCompareButton}>
                  {homeContent.heading}
                </div>
                <div className="sliderAgeText">Takes under 2 minutes</div>
              </div>
            </div>
          </div>
        </section>
        <section className="faqSection">
          <div className="container">
            <div
              className="brandsList"
              id="description"
              dangerouslySetInnerHTML={{
                __html: homeContent.section_third_paragraph,
              }}
            ></div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default LayoutBodySixth;
