import {
    POST_ADVERTISE_WITH_US_SUCCESS,
    POST_ADVERTISE_WITH_US_REQUESTED,
    POST_ADVERTISE_WITH_US_FAILED
} from "../actions/advertise-with-us-actions";

const initialState = {
    isLoading: false,
};

const AdvertiseWithUsReducer = (state = initialState, { payload, type }) => {
    switch (type) {
        case POST_ADVERTISE_WITH_US_REQUESTED:
            return {
                ...state,
                isLoading: true,
            };
        case POST_ADVERTISE_WITH_US_SUCCESS:
        case POST_ADVERTISE_WITH_US_FAILED:
            return {
                ...state,
                isLoading: false,
            };
        default:
            return { ...state };
    }
};
export default AdvertiseWithUsReducer;
